import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import IconMoreInfo from "../../../../common/button/iconButton/MoreInfo";

const SchemaTitle = () => {
    const { t } = useTranslation();
    
    //RENDER
    const getInfoTitleSchema = () => {
        return (
          <>
            <Typography color="inherit">
              {t('info_title1')}
            </Typography>
            {t('info_schemaWhat')}
            <Typography color="inherit" marginTop={1}>
              {t('info_title2')}
            </Typography>
            {t('info_schemaHowTo')}
          </>
        );
      }

    return (
        <Typography>
            {t('headerSchema')}
            <IconMoreInfo title={getInfoTitleSchema()} />
      </Typography>
    );
}


export default SchemaTitle;