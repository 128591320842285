import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { AUTOMATION_SCRIPTING } from '../../../../../serverInteraction/services/endpointsConstants';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { AutomationScriptingProps } from '../../../../../types/automation/automationScripting/AutomationScriptingProps';

type Props = {
  autoScript: AutomationScriptingProps;
  onDelete?: () => void;
};

export function AutomationScriptingDeleteDialog({ autoScript, onDelete }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess('Deleted successfully!');
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove(AUTOMATION_SCRIPTING, onSuccess);
  const handleDelete = async () => {
    const autoScriptForDelete = {
      company: userPackage.company,
      document: userPackage.document,
      featureId: autoScript.featureId,
    };
    await remove(autoScriptForDelete);
  };

  return (
    <DeleteDialog title={`Delete automation script ${autoScript.featureId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${autoScript.featureId}?`}</Box>
    </DeleteDialog>
  );
}
