const subprop1 = [
  { name: `rootDocument`, parent: false },
  { name: `repositoryName`, parent: false },
  {
    name: `query("nxql")`,
    parent: true,
    subproperties: [
      { name: `get(0)`, parent: false },
      { name: `size()`, parent: false },
    ],
  },
];

const prop1 = [
  { name: 'name', parent: false },
  { name: 'id', parent: false },
  { name: 'type', parent: false },
  { name: 'path', parent: false },
  { name: 'title', parent: false },
  { name: 'description', parent: false },
  { name: 'lifeCycle', parent: false },
  { name: 'workspace', parent: true },
  { name: 'domain', parent: true },
  { name: 'parent', parent: true },
  { name: 'versionLabel', parent: false },
  { name: `["xpath"]`, parent: false },
  { name: `getProperty("xpath")`, parent: false },
  { name: `getParent("type")`, parent: true },
  { name: `hasFacet("facet")`, parent: false },
  { name: `hasSchema("schema")`, parent: false },
  { name: `isLocked()`, parent: false },
  { name: `isFolder()`, parent: false },
  { name: `isImmutable()`, parent: false },
  { name: `isProxy()`, parent: false },
  { name: `isVersion()`, parent: false },
  { name: `isDownloadable()`, parent: false },
  { name: `isVersionable()`, parent: false },
  { name: `resolvePath("path")`, parent: false },
  { name: `resolvePathAsRef("path")`, parent: true },
  { name: `size()`, parent: false },
  { name: `schemas`, parent: false },
  { name: `doc`, parent: false },
  { name: `documentType`, parent: false },
  {
    name: `session`,
    parent: true,
    subproperties: subprop1,
  },
];

const prop2 = [
  { name: 'months(2)', parent: true },
  { name: 'days(7)', parent: true },
  { name: 'years(-1)', parent: true },
  { name: 'seconds(3600)', parent: true },
  { name: 'weeks(-3)', parent: true },
  { name: 'year', parent: false },
  { name: 'month', parent: false },
  { name: 'day', parent: false },
  { name: 'hour', parent: false },
  { name: 'minute', parent: false },
  { name: 'second', parent: false },
  { name: 'week', parent: false },
  { name: 'format("pattern")', parent: false },
  { name: 'calendar', parent: false },
  { name: 'date', parent: false },
  { name: 'time', parent: false },
];

const prop3 = [
  { name: 'name', parent: false },
  { name: 'email', parent: false },
  { name: 'firstName', parent: false },
  { name: 'lastName', parent: false },
  { name: 'company', parent: false },
  { name: '["xpath"]', parent: false },
  { name: 'getProperty("xpath")', parent: false },
  { name: 'size', parent: false },
  { name: 'allGroups', parent: false },
  { name: 'principal', parent: false },
  { name: 'actingUser', parent: false },
];

const prop4 = [
  { name: 'getNextId("key")', parent: false },
  { name: 'getVocabularyLabel("voc", "key")', parent: false },
  { name: 'getEmail("username")', parent: false },
  { name: 'getEmails("usernames")', parent: false },
  { name: 'getEmailsFromGroup("group")', parent: false },
  { name: 'getEmailsFromGroup("group", "ignoreSubGroups")', parent: false },
  { name: 'getPrincipal("username")', parent: false },
  { name: 'getPrincipalEmails(var_principals)', parent: false },
  { name: 'date("date")', parent: false },
  { name: 'calendar("calendar")', parent: false },
  { name: 'htmlEscape("content")', parent: false },
  { name: 'concatenateIntoList(list, ["value1", "value2", ...]', parent: false },
  { name: 'concatenateValuesAsNewList(["value1", "value2", ...])', parent: false },
  { name: 'documentExists(Session, "docIdOrPath")', parent: false },
];

const prop5 = [
  { name: '["var"]', parent: false },
  { name: 'input', parent: false },
  { name: 'principal', parent: false },
  { name: 'coreSession', parent: true, subproperties: subprop1 },
  { name: '["workflowInitiator"]', parent: false },
  { name: '["workflowStartTime"]', parent: false },
  { name: '["nodeStartTime"]', parent: false },
  { name: '["nodeLastActor"]', parent: false },
  { name: '["nodeId"]', parent: false },
  { name: '["workflowInstanceId"]', parent: false },
  { name: '["taskDueTime"]', parent: false },
];

export const expressionEditorConstants = [
  {
    name: 'Document',
    description: 'The input document',
    parent: true,
    properties: prop1,
  },
  {
    name: 'This',
    description: 'The operation input. The input type is: document',
    properties: prop1,
  },
  {
    name: 'CurrentDate',
    description: 'The Current Date. You can construct other dates by using this object.',
    properties: prop2,
  },
  {
    name: 'CurrentUser',
    description: 'The Current User which invoked the operation',
    properties: prop3,
  },
  { name: 'Fn', description: 'Functions Library', properties: prop4 },
  {
    name: 'Env',
    description: 'A table of environment variables.',
    properties: [{ name: '["key"]', parent: false }],
  },
  {
    name: 'Context',
    description: `The execution context. <span color="green">Reserved for advanced users</span>.`,
    properties: prop5,
  },
  {
    name: 'WorkflowVariables',
    description: `The workflow variables. <span color="green">Reserved for advanced users, only in the context of a running workflow</span>.`,
    properties: [{ name: '["var"]', parent: false }],
  },
  {
    name: 'NodeVariables',
    description: `The node variables. <span color="green">Reserved for advanced users, only in the context of a running workflow</span>.`,
    properties: [
      { name: '["var"]', parent: false },
      { name: '["button"]', parent: false },
      { name: '["numberOfProcessedTasks"]', parent: false },
      { name: '["numberOfTasks"]', parent: false },
      {
        name: '["tasks"]',
        parent: true,
        subproperties: [{ name: 'getNumberEndedWithStatus("status")', parent: false }],
      },
    ],
  },
  {
    name: 'Session',
    description: `The Core Session. <span color="green">Reserved for advanced users</span>.`,
    properties: subprop1,
  },
];
