import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { LoginProps } from "../../../types/auth/auth";
import { ControlledInput } from "../../common/input/ControlledInput";
import { useUserContext } from "../../../context/userContext/UserProvider";
import { useLocation } from "react-router-dom";

function LoginForm() {
  const { t } = useTranslation();
  const location = useLocation();
  const from = location.state?.from || "/";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginProps>();
  const { login, isFetching, error } = useUserContext();

  const onSubmit = async (formData: LoginProps) => {
    await login(formData, from);
    reset();
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        p: 2,
      }}
    >
      <Divider sx={{ width: "100%" }}>
        <AccountCircleIcon fontSize="large" />
      </Divider>
      <Typography component="h1" variant="h4">
        {t("signIn")}
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        <FormControl>
          <FormLabel required>Email</FormLabel>
          <ControlledInput
            type="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus={true}
            register={{
              ...register("email", {
                required: {
                  value: true,
                  message: t("inputRequired").replace("%1", "email"),
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("inputCorrectedEmail"),
                },
              }),
            }}
            helperText={errors.email?.message}
            error={errors.email !== undefined}
            color={errors.email ? "error" : "primary"}
            sx={{ width: "100%" }}
          />
        </FormControl>
        <FormControl>
          <FormLabel required>{t("password")}</FormLabel>
          <ControlledInput
            type="password"
            placeholder="••••••"
            register={{
              ...register("password", {
                required: {
                  value: true,
                  message: t("inputRequired").replace("%1", t("password")),
                },
              }),
            }}
            helperText={errors.password?.message}
            error={errors.password !== undefined}
            color={errors.password ? "error" : "primary"}
            sx={{ width: "100%" }}
          />
        </FormControl>
        <Button type="submit" fullWidth variant="contained">
          {isFetching && <CircularProgress color="secondary" size={20} />}
          {t("signIn")}
        </Button>
        <Typography sx={{ color: "red" }}>{error}</Typography>
      </Box>
    </Card>
  );
}

export default LoginForm;
