import { ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from '@mui/icons-material/Circle';

interface IProps {
    featureId: string,
    label: string
}

const ValidationErrorsAccordionItem = ({ featureId, label }: IProps) => {
  const {t} = useTranslation();
    
  return (
    <ListItem sx = {{gap: '10px', alignItems: 'start', padding: '0 10px'}}>
      <CircleIcon sx = {{color: 'black', fontSize: '8px', marginTop: '10px'}}/>
      <ListItemText>
        {t(label).replace('%1', featureId).replace('%2', featureId)}
      </ListItemText>
    </ListItem>
  )  
}

export default ValidationErrorsAccordionItem;
