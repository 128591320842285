import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NavToDesigner() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "right",
        display: "flex",
        paddingTop: "1em",
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        sx={{ alignItems: "center" }}
      >
        {`${t("configureLayoutsButton")} Designer`}
      </Button>
    </Box>
  );
}
