import { Checkbox, FormControl, Stack } from "@mui/material"
import { Controller, useWatch } from "react-hook-form"
import { Inforequired, LabelRequired } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { constraintsParamNames } from "../../FieldAdvancedSettings/validationViews/constants";

interface IProps {
    schemaName: string, 
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    control: any

}

export default function RequiredView({schemaName, fieldName, fieldNamePrefix, fieldIndex, control}: IProps){
    const paramName = "mandatoryConstraint";
    const index = constraintsParamNames.findIndex((element: string) => element === paramName); 

    const watchParams = useWatch({
        control,
        name: [`${fieldNamePrefix}.constraints.${index}.${paramName}.value`]
    });

    return (
        <>
            <Stack direction="row" alignItems="center" sx={{marginLeft: "1em"}}>
                <Controller
                        name={`${fieldNamePrefix}.constraints.${index}.${paramName}.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                />
                <FormControl sx={{cursor: "default", width: "100%"}}>
                    <Inforequired />  
                </FormControl>
            </Stack>
            {watchParams[0] && <LabelRequired schema={schemaName} field={fieldName}/>}
        </>
        )
}