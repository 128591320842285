import { useSelector } from "react-redux";
import useGetAll from "../../entity/useGetAll";
import { PackageProps } from "../../../../types/packages/PackageProps";
import { STRUCTURE_TEMPLATES_ENDPOINT } from "../../../services/endpointsConstants";
import { GetStructureTemplateProps } from "../../../../types/content/structureTemplates/GetStructureTemplateProps";

const useStructureTemplates = () => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = `${STRUCTURE_TEMPLATES_ENDPOINT}?company=${userPackage.company}&document=${userPackage.document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<GetStructureTemplateProps>(entityName);

  return {
    status,
    structureTemplates: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useStructureTemplates;
