import { Typography } from '@mui/material'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { DeleteIconButton } from '../../../../../common/button/iconButton/DeleteIconButton';
import { UploadDialog } from '../../../../../common/dialog/UploadDialog';

interface IProps {
    value: string | null | undefined,
    onSelect: (newFileName: string | undefined) => void,
    onDelete: () => void
}

const IconSelector = ({value, onSelect, onDelete}: IProps) => {
  
   return (
    <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: '10px'}}
    >
          
        {value && 
            <Typography >
                <TextSnippetOutlinedIcon style={{ marginRight: 5 }}/>
                {value}
            </Typography>
        }
        {value && 
            <DeleteIconButton 
                onClick={onDelete} 
                size="small" 
            />
        }
        <UploadDialog 
            title="Select Resource" 
            variant="ICON" 
            handleChangeFile={onSelect} />
    </div>
  )
}

export default IconSelector