import { Checkbox, FormControl, Stack } from "@mui/material"
import { Controller, useWatch } from "react-hook-form"
import { InfoListRequired, LabelRequired } from "./ValidationLabels";
import { constraintsParamNames } from "./constants";


interface IProps {
    schemaName: string,
    fieldName: string,
    fieldIndex: number,
    control: any,
    
}

export default function ListRequiredView({schemaName, fieldName, fieldIndex, control }: IProps){
    const paramName = "listElementsMandatoryConstraint";
    const index = constraintsParamNames.findIndex((element: string) => element === paramName); 
    
    const fieldsWatches = useWatch({
        control,
        name: [`fields.${fieldIndex}.isMultiValue`, `fields.${fieldIndex}.constraints.${index}.${paramName}.value`]
    });

    
    if (fieldsWatches[0]){
        return (
            <>
                <Stack direction="row" alignItems="center" sx={{marginTop: "1em", marginLeft: "1em"}}>
                    <Controller
                        name={`fields.${fieldIndex}.constraints.${index}.${paramName}.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                    />
                    <FormControl sx={{cursor: "default", width: "100%"}}>
                        <InfoListRequired />  
                    </FormControl>
                </Stack>
                {fieldsWatches[1] && <LabelRequired schema={schemaName} field={fieldName}/>}
            </>
        )
    }

    return (<></>)
}