type Field = {
  name: string;
  required: boolean;
  tagType: string;
  value: any[] | string | null | boolean;
};

type Type = {
  name: string;
  description: string;
  accepts: string;
  produces: string;
  fields: Field[];
};

export type TypesChain = {
  name: string;
  types?: Type[];
};

export const typesChains: TypesChain[] = [
  {
    name: 'Conversion',
    types: [
      {
        name: 'Blob.ConcatenatePDF',
        description:
          'Given a File document holding a pdf on the file:content property and 2 pdfs on the files:files property, the following operation will provide a pdf that is the result of the merge of all the pdfs, with the content of the one in file:content property first.',
        accepts: 'bloblist, blob',
        produces: 'blob, blob',
        fields: [
          {
            name: 'filename',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'blob_to_append',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.Convert',
        description:
          'Convert the input file to a file of the given mime-type and return the new file.',
        accepts: 'bloblist, blob, document',
        produces: 'bloblist, blob, blob',
        fields: [
          {
            name: 'mimeType',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.RunConverter',
        description: `Simply call a converter based on the 'converter' parameter. You can pass the converter properties with the 'properties' parameter.`,
        accepts: 'blob',
        produces: 'blob',
        fields: [
          {
            name: 'converter',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.ToPDF',
        description: 'Convert the input file to a PDF and return the new file.',
        accepts: 'bloblist, blob, document',
        produces: 'bloblist, blob, blob',
        fields: [],
      },
      {
        name: 'PDF.AddPageNumbers',
        description:
          'Add the page numbers to the PDF, using the misc parameters. If the PDF is encrypted, a password is required.',
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'fontName',
            required: false,
            tagType: 'input',
            value: 'Helvetica',
          },
          {
            name: 'fontSize',
            required: false,
            tagType: 'input',
            value: '16',
          },
          {
            name: 'hex255Color',
            required: false,
            tagType: 'input',
            value: '0xffffff',
          },
          {
            name: 'password',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'position',
            required: false,
            tagType: 'select',
            value: [
              'Bottom right',
              'bottom left',
              'Bottom center',
              'Top right',
              'Top center',
              'Top left',
            ],
          },
          {
            name: 'startAtNumber',
            required: false,
            tagType: 'input',
            value: '1',
          },
          {
            name: 'startAtPage',
            required: false,
            tagType: 'input',
            value: '1',
          },
        ],
      },
      {
        name: 'PDF.ConvertToPictures',
        description: 'Convert each page of a PDF into a picture. Returns Blob list of pictures.',
        accepts: 'document',
        produces: 'bloblist',
        fields: [
          {
            name: 'fileName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'password',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.Encrypt',
        description:
          'Encrypts the PDF with the given permissions, returning a copy. Permissions are print, modify, copy, modifyAnnot, fillForms, extractForAccessibility, assemble and printDegraded. Any missing permission is set to false (values are true or false, assemble=true for example). originalOwnerPwd is used if the PDF was originally encrypted. If no keyLength is provided, use 128. If the operation is ran on Document(s), xpath lets you specificy where to get the blob from (default: file:content).',
        accepts: 'bloblist, blob, document, documents',
        produces: 'bloblist, blob, blob, bloblist',
        fields: [
          {
            name: 'originalOwnerPwd',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ownerPwd',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'userPwd',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'keyLength',
            required: false,
            tagType: 'select',
            value: ['40', '128'],
          },
          {
            name: 'permissions',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.EncryptReadOnly',
        description:
          'Encrypts the PDF, returning a copy. User can read, print and copy but cannot modify. originalOwnerPwd is used if the PDF was originally encrypted. If ownerPwd is empty, use originalOwnerPwd to encrypt. If no keyLength is provided, use 128. If the operation is ran on Document(s), xpath lets you specificy where to get the blob from (default: file:content).',
        accepts: 'bloblist, blob, document, documents',
        produces: 'bloblist, blob, blob, bloblist',
        fields: [
          {
            name: 'keyLength',
            required: false,
            tagType: 'select',
            value: ['40', '128'],
          },
          {
            name: 'originalOwnerPwd',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ownerPwd',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'userPwd',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.ExtractLinks',
        description:
          'Returns a JSON string of an array of objects with page, subType, text and link fields. If getAll is true, returns all the links (Remote Go To, Launch and URI in the current version).',
        accepts: 'blob',
        produces: 'string',
        fields: [
          {
            name: 'getAll',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'type',
            required: false,
            tagType: 'select',
            value: ['Launch', 'Remove Go To', 'URI'],
          },
        ],
      },
      {
        name: 'PDF.ExtractPages',
        description:
          'Extract pages from <code>startPage</code> to <code>endPage</code> (inclusive) from the input object. If a Blob is used as input, the <code>xpath</xpath> parameter is not used. <code>title</code>, <code>subject</code> and <code>author</code> are optional. If the PDF is encrypted, a password is required.',
        accepts: 'blob, document',
        produces: 'blob, blob',
        fields: [
          {
            name: 'endPage',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'startPage',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'fileName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'password',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfAuthor',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfSubject',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfTitle',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.MergeWithBlobs',
        description:
          'The input blob(s) always is(are) the first PDFs. The operation appends the blob referenced in the <code>toAppendVarName</code> Context variable. It then appends all the blobs stored in the <code>toAppendListVarName</code> Context variable. Returns the final PDF.',
        accepts: 'bloblist, blob',
        produces: 'blob, blob',
        fields: [
          {
            name: 'fileName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfAuthor',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfSubject',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfTitle',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'toAppendListVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'toAppendVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'PDF.MergeWithDocs',
        description:
          'The input document(s) always is(are) the first PDFs, and their PDF is read in the <code>xpath</code> field (but it is ok for the input doc to have no blob). The operation appends the blob referenced in the <code>toAppendVarName</code> Context variable. It then appends all the blobs stored in the <code>toAppendListVarName</code> Context variable. It then append the blobs stored in the docs whose IDs are passed in <code>toAppendDocIDsVarName</code> (the same <code>xpath</code> is used). Returns the final PDF.',
        accepts: 'document, documents',
        produces: 'blob, blob',
        fields: [
          {
            name: 'fileName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfAuthor',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfSubject',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfTitle',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'toAppendDocIDsVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'toAppendListVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'toAppendVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.RemoveEncryption',
        description:
          'Removes the encryption, returns a copy of the blob. If the operation is ran on Document(s), xpath lets you specificy where to get the blob from (default: file:content).',
        accepts: 'bloblist, blob, document, documents',
        produces: 'bloblist, blob, blob, bloblist',
        fields: [
          {
            name: 'ownerPwd',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.WatermarkWithImage',
        description: `<p>Return a <em>new</em> blob combining the input PDF and the<code> image </code>blob.</p><p>Properties must be one or more of the following (the default if the property is not set):</p><ul><li><code>scale </code>(1.0) : 1.0 is the original size of the picture</li><li><code>alphaColor</code> (0.5) : 0 is full transparency, 1 is solid</li><li><code>xPosition </code>(0) : in pixels from left or between 0 (left) and 1 (right) if relativeCoordinates is set to true</li><li><code>yPosition</code> (0) : in pixels from bottom or between 0 (bottom) and 1 (top) if relativeCoordinates is set to true</li><li><code>invertX</code> (false) : xPosition starts from the right going left</li><li><code>invertY</code> (false) : yPosition starts from the top going down</li><li><code>relativeCoordinates</code> (false)</li></ul>`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'image',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'PDF.WatermarkWithPDF',
        description:
          'Returns a new blob combining the input PDF and an overlaid PDF on every page.',
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'overlayPdf',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'PDF.WatermarkWithText',
        description: `<p>Return a <em>new</em> blob combining the input PDF and the <code>text</code> text.</p><p>Properties must be one or more of the following (the default if the property is not set):</p><ul><li><code>fontFamily</code> (Helvetica) </li><li><code>fontSize</code> (72)</li><li><code>rotation</code> (0): in&nbsp;counterclockwise degrees</li><li><code>hex255Color</code> (#000000)</li><li><code>alphaColor</code> (0.5) : 0 is full transparency, 1 is solid</li><li><code>xPosition</code> (0) : in pixels from left or between 0 (left) and 1 (right) if relativeCoordinates is set to true</li><li><code>yPosition</code> (0) : in pixels from bottom or between 0 (bottom) and 1 (top) if relativeCoordinates is set to true</li><li><code>invertX</code> (false) : xPosition starts from the right going left</li><li><code>invertY</code> (false) : yPosition starts from the top going down</li><li><code>relativeCoordinates</code> (false)</li></ul>`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'text',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'overlaypropertiesPdf',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Picture.GetView',
        description: 'Get an image from a Picture document.',
        accepts: 'document',
        produces: 'blob',
        fields: [
          {
            name: 'viewName',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Picture.Resize',
        description: 'Use conversion service to resize a picture contained in a Document or a Blob',
        accepts: 'blob, document',
        produces: 'blob, blob',
        fields: [
          {
            name: 'maxHeight',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'maxWidth',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Render.Document',
        description: `Get a document or a list of document in input and outputs one or more blobs that contain a rendered view for each input document given a rendering template. The template attribute may contain either the template content either a template URI. Template URis are strings in the form 'template:template_name' and will be located using the runtime resource service. Return the rendered file(s) as blob(s)`,
        accepts: 'document, documents',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'template',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'filename',
            required: false,
            tagType: 'input',
            value: 'output.ftl',
          },
          {
            name: 'mimetype',
            required: false,
            tagType: 'input',
            value: 'text/xml',
          },
          {
            name: 'type',
            required: false,
            tagType: 'input',
            value: ['ftl', 'mvel'],
          },
        ],
      },
      {
        name: 'Render.DocumentFeed',
        description: `Get a list of documents as input and outputs a single blob containing the rendering of the document list. The template attribute may contain either the template content either a template URI. Template URis are strings in the form 'template:template_name' and will be located using the runtime resource service. Return the rendered blob`,
        accepts: 'documents',
        produces: 'blob',
        fields: [
          {
            name: 'template',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'filename',
            required: false,
            tagType: 'input',
            value: 'output.ftl',
          },
          {
            name: 'mimetype',
            required: false,
            tagType: 'input',
            value: 'text/xml',
          },
          {
            name: 'type',
            required: false,
            tagType: 'input',
            value: ['ftl', 'mvel'],
          },
        ],
      },
      {
        name: 'Video.ExtractClosedCaptions',
        description: `Extracts the closed captions from the whole video or from a part of it when startAt and end time is provided. The output format references how the output is generated, and xpath can be used to indicate the video blob when using documents.`,
        accepts: 'blob, bloblist, document, documents',
        produces: 'blob, bloblist, blob, bloblist',
        fields: [
          {
            name: 'endAt',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'outFormat',
            required: false,
            tagType: 'input',
            value: 'ttxt',
          },
          {
            name: 'startAt',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Video.Slice',
        description: `SliceVideo the input blob starting at startAt, for a certain duration. The duration and startAt arguments are optional, but not simultaneously. A specific converter can be used.`,
        accepts: 'blob, bloblist, document, documents',
        produces: 'blob, bloblist, blob, bloblist',
        fields: [
          {
            name: 'duration',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'encode',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'startAt',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Video.SliceInParts',
        description: `Slices the video in n parts of approximately the same duration each.`,
        accepts: 'blob, document',
        produces: 'bloblist, bloblist',
        fields: [
          {
            name: 'duration',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Document',
    types: [
      {
        name: 'Collection.Create',
        description: `Create a new collection. This is returning the document serialization of the created collection.`,
        accepts: 'void, document',
        produces: 'document, document',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'description',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Collection.GetDocumentsFromCollection',
        description: `Get the list of documents visible by the currentUser in a collection. This is returning a list of documents.`,
        accepts: 'document',
        produces: 'documents',
        fields: [],
      },
      {
        name: 'Collection.RemoveFromCollection',
        description: `Remove a list of documents from a collection. No value is returned.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'collection',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.AddACE',
        description: `Set Acces Control Entry on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'permission',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'user',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: 'local',
          },
          {
            name: 'grant',
            required: true,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'overwrite',
            required: true,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.AddFacet',
        description: `Adds the facet to the document. <p>WARNING: The save parameter is true by default, which means the document is saved in the database after adding the facet. It must be set to false when the operation is used in the context of an event that will fail if the document is saved (empty document created, about to create, before modification, ...).</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'facet',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.AddItemToListProperty',
        description: `This operation can add new entries to a multivalued complex property. The xpath parameter is the property that should be updated (e.g.: contract:customers). The value parameter is a String containing the JSON-formatted list of entries to add. E.g.: assuming a Contract document type holding customers, each having a firstName and lastName property: [{"lastName":"Norris", "firstName": "Chuck"}, {"lastName":"Lee", "firstName": "Bruce"}] . Activating the save parameter forces the changes to be written in database immediately (at the cost of performance loss), otherwise changes made to the document will be written in bulk when the chain succeeds. <p>Save parameter has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events.</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'complexJsonProperties',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.AddPermissions',
        description: `Add Permission on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'permission',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: 'local',
          },
          {
            name: 'begin',
            required: false,
            tagType: 'date',
            value: null,
          },
          {
            name: 'blockInheritance',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'email',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'end',
            required: false,
            tagType: 'date',
            value: null,
          },
          {
            name: 'notify',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'username',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'users',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.AddToCollection',
        description: `Add a list of documents in a collection. No value is returned.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'collection',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.AddToFavorites',
        description: `Add a list of documents in the favorites. No value is returned.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.BlockPermissionInheritance',
        description: `Block the permission inheritance on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: 'local',
          },
        ],
      },
      {
        name: 'Document.CheckIn',
        description: `Checks in the input document. Returns back the document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'version',
            required: true,
            tagType: 'input',
            value: 'version',
          },
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'versionVarName',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.CheckOut',
        description: `Checks out the input document. Returns back the document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.Copy',
        description: `Copy the input document into the given Folderish. The name parameter will be used as the copy name otherwise if not specified the original name will be preserved. The target Folderish can be specified as an absolute or relative path (relative to the input document) as an UID or by using an EL expression. Return the newly created document (the copy). The document is copied with its children recursively. The resetLifeCycle parameter allows to reset the life cycle of the copied document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'target',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'name',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'resetLifeCycle',
            required: true,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Document.CopySchema',
        description: `Copy all the properties from the schema of the source into the input document. Either sourceId or sourcePath parameter should be filled. When both are filled, sourceId will be used. If saveDocument is true, the document is saved. If save is true, the session is saved (setting save to true and saveDocument to false has no effect, the session will not be saved)`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'schema',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'saveDocument',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'sourceId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sourcePath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Create',
        description: `Create a new document in the input folder. You can initialize the document properties using the 'properties' parameter. The properties are specified as <i>key=value</i> pairs separated by a new line. The key used for a property is the property xpath. To specify multi-line values, you can use a \ character followed by a new line. <p>Example:<pre>dc:title=The Document Title<br>dc:description=foo bar</pre>. Returns the created document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'type',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'name',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'schema',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Document.CreateLiveProxy',
        description: `This operation will create a proxy that points the given document as input. This is like a symbolic link for File System. The proxy will be created into the destination specified as parameter. <p>The document returned is the proxy live.<p> Remark: <b>you will have a strange behavior if the input is a folderish.</b>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'Destination Path',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.CreateVersion',
        description: `Create a new version for the input document. Any modification made on the document by the chain will be automatically saved. Increment version if this was specified through the 'snapshot' parameter. This operation should not be used in the context of the empty document created, about to create, before document modification, document modified events. Returns the live document (not the version).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'increment',
            required: false,
            tagType: 'select',
            value: ['None', 'Minor', 'Major'],
          },
          {
            name: 'saveDocument',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Document.Delete',
        description: `Delete the input document. The previous context input will be restored for the next operation.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.EmptyTrash',
        description: `Emtpy a folder's trash by permanently deleting documents marked as trashed.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'parent',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Filter',
        description: `Filter the input list of documents given a condition. The condition can be expressed using 4 parameters: types, facets, lifecycle and condition. If more than one parameter is specified an AND will be used to group conditions. <br>The 'types' parameter can take a comma separated list of document type: File,Note.<br>The 'facet' parameter can take a single facet name.<br> The 'life cycle' parameter takes a name of a life cycle state the document should have.<br>The 'condition' parameter can take any EL expression.<p>Returns the list of documents that match the filter condition.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'class',
            required: false,
            tagType: 'select',
            value: [
              'Any',
              'Regular Document',
              'Document Link',
              'Published Document',
              'Document Proxy',
              'Document Version',
              'Immutable Document',
              'Mutable Document',
            ],
          },
          {
            name: 'condition',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'facet',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'lifecycle',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pathStartsWith',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'types',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.FollowLifecycleTransition',
        description: `Follow the given transition on the input document life cycle state`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'Transition',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.GetChild',
        description: `Get a child document given its name. Take as input the parent document and return the child documen`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.GetChildren',
        description: `Get the children of a document. The list of children will become the input for the next operation`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.GetLastVersion',
        description: `Returns the last version of the document if it exists.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.GetParent',
        description: `Get the parent document of the input document. The parent document will become the input for the next operation. You can use the 'type' parameter to specify which parent to select from the document ancestors`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'type',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Versions',
        description: `Gets the versions of the input document.`,
        accepts: 'document',
        produces: 'documents',
        fields: [],
      },
      {
        name: 'Document.Lock',
        description: `Lock the input document for the current user. Returns back the locked document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.Move',
        description: `Move the input document into the target folder.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'target',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'name',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.MoveCollectionMember',
        description: `Move member1 of a collection right after member2 of the same collection. If member2 is not sepcified, the member1 is moved to first position. Returns true if successfully moved.`,
        accepts: 'document',
        produces: 'boolean',
        fields: [
          {
            name: 'member1',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'member2',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Order',
        description: `Given a parent document, order the source child before the destination child`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'before',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.PublishToSection',
        description: `input document's chosen rendition into the target section. If rendition is not given and default rendition option is false, it falls back on default publishing. Existing proxy is overrided if the override attribute is set. Return the created proxy.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'target',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'defaultRendition',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'override',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'renditionName',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.PublishToSections',
        description: `Publish the input document(s) into several target sections. The target is evaluated to a document list (can be a path, UID or EL expression). Existing proxy is overridden if the override attribute is set. Returns a list with the created proxies.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'target',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'override',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.Reload',
        description: `Reload the input document from the repository. Any previous modification made by the chain on this document will be lost if these modifications were not saved. Return the reloaded document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.RemoveACL',
        description: `Remove a named Acces Control List from the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'acl',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.RemoveEntryOfMultivaluedProperty',
        description: `Remove the first entry of the giving value in the multivalued xpath, does nothing if does not exist: <ul<li>if 'is Remove All' is check, all entry instance in the list.</li><li>if not will remove just the first one found</li></ul><p>Save parameter automatically saves the document in the database. It has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events.</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'value',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'is Remove All',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.RemoveFacet',
        description: `Removes the facet from the document. <p>WARNING: The save parameter is true by default, which means the document is saved in the database after removing the facet. It must be set to false when the operation is used in the context of an event that will fail if the document is saved (empty document created, about to create, before modification, ...).</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'facet',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'chckbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.RemoveFromFavorites',
        description: `Remove a list of documents from the favorites. No value is returned`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.RemoveItemFromListProperty',
        description: `This operation removes an entry from a multivalued property, specified using a xpath (e.g.: contract:customers). A specific entry can be removed using its index number. If the index parameter is left empty, all entries in the property are removed. Activating the save parameter forces the changes to be written in database immediately (at the cost of performance loss), otherwise changes made to the document will be written in bulk when the chain succeeds. <p>Save parameter has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events.</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'index',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'input',
            value: true,
          },
        ],
      },
      {
        name: 'Document.RemovePermission',
        description: `Remove a permission given its id or all permissions for a given user on the input document(s). Parameter 'id' or 'user' must be set. Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'id',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'user',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.RemoveProperty',
        description: `Remove the given property of the input document(s) as specified by the 'xpath' parameter. If the property points to a list then clear the list. Removing a property means setting it to null. Return the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.RemoveProxies',
        description: `Will remove all proxies pointing on the input document. Useful for instance to unpublish a document. Notice: this operation will remove all proxies, including the ones pointing to the current document version (live proxies). Activating the save parameter forces the changes to be written in database immediately (at the cost of performance loss).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.ReplacePermission',
        description: `Replace a given permission on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'permission',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'username',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: 'local',
          },
          {
            name: 'begin',
            required: false,
            tagType: 'date',
            value: null,
          },
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'end',
            required: false,
            tagType: 'date',
            value: null,
          },
          {
            name: 'notify',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Document.ResetSchema',
        description: `Reset all properties for a given schema or xpath. If saveDocument is true, the document is saved. If save is true, the session is saved (setting save to true and saveDocument to false has no effect, the session will not be saved).<p>WARNING: Default values are true for both saveDocument and save, which means the document is saved by default. saveDocument must be set to false when the operation is used in the context of an event that will fail if the document is saved (empty document created, about to create, before modification, ...).</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'saveDocument',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'schema',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.RestoreVersion',
        description: `Restores a document to the input version document. If createVersion is true, a version of the live document will be created before restoring it to the input version. If checkout is true, a checkout will be processed after restoring the document, visible in the UI by the '+' symbol beside the version number. Returns the restored document.`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'checkout',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'createVersion',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Document.Save',
        description: `Save in the repository any modification that was done on the input document. This operation should not be used in the context of the empty document created, about to create, before document modification, document modified events. Returns the saved document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.SendNotofocationEmailForPermission',
        description: `Send the notification email for a permission on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.SetBlob',
        description: `Set the input file to the given property on the input document. If the xpath points to a blob list then the blob is appended to the list, otherwise the xpath should point to a blob property. If the save parameter is set the document modification will be automatically saved. Return the document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'file',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.SetProperty',
        description: `Set a single property value on the input document. The property is specified using its xpath. Save parameter automatically saves the document in the database. It has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events. Returns the modified document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'value',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.SetSubscribe',
        description: `Subscribe one or more documents. No value is returned.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'notifications',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Trash',
        description: `Moves documents to the trash.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.UnblockPermissionInheritance',
        description: `Unblock the permission inheritance on the input document(s). Returns the document(s).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'acl',
            required: false,
            tagType: 'input',
            value: 'local',
          },
        ],
      },
      {
        name: 'Document.Unlock',
        description: `Unlock the input document. The unlock will be executed in the name of the current user. An user can unlock a document only if has the UNLOCK permission granted on the document or if it the same user as the one that locked the document. Return the unlocked document`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.UnpublishAll',
        description: `Unpublish all publications of the input document. Only publications that current user can remove will be unpublished.`,
        accepts: 'document',
        produces: 'void',
        fields: [],
      },
      {
        name: 'Document.Unsubscribe',
        description: `Unsubscribe one or more documents. No value is returned.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'notifications',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.Untrash',
        description: `Undeletes documents (and ancestors if needed to make them visible)..`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Document.Update',
        description: `Set multiple properties on the input document. The properties are specified as <i>key=value</i> pairs separated by a new line. The key used for a property is the property xpath. To specify multi-line values you can use a \ character followed by a new line. <p>Example:<pre>dc:title=The Document Title<br>dc:description=foo bar</pre>For updating a date, you will need to expose the value as ISO 8601 format, for instance : <p>Example:<pre>dc:title=The Document Title<br>dc:issued=@{org.nuxeo.ecm.core.schema.utils.DateParser.formatW3CDateTime(CurrentDate.date)}</pre><p>Returns back the updated document.<p>To update a multi-valued field with multiple values:<pre>custom:multivalued=a,b,c,d</pre><p>Save parameter automatically saves the document in the database. It has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events.</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'properties',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'changeToken',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.MultivaluedProperty.addItem',
        description: `Add value into the field expressed by the xpath parameter. This field must be a multivalued metadata.<p> 'checkExists' parameter enables to add value only if doesn't already exists in the field: <ul><li> if checked, the value will not be added if it exists already in the list</li><li>if not checked the value will be always added</li</ul>.<p> Remark: <b>only works for a field that stores a list of scalars (string, boolean, date, int, long) and not list of complex types.</b></p><p>Save parameter automatically saves the document in the database. It has to be turned off when this operation is used in the context of the empty document created, about to create, before document modification, document modified events.</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'value',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'checkExists',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Favorite.FavoriteFetch',
        description: `Fetch the favorites document root collection.`,
        accepts: 'void',
        produces: 'document',
        fields: [],
      },
      {
        name: 'Favorite.GetDocuments',
        description: `Get the list of documents visible from the currentUser's favorites. This is returning a list of documents.`,
        accepts: 'document',
        produces: 'documents',
        fields: [],
      },
      {
        name: 'PDF.ExtractInfo',
        description: `Extract the info of the PDF stored in <code>xpath</code> and put it in the fields referenced by <code>properties</code>. <code>properties</code> is a <code>key=value</code> list (one key-value pair/line, where <code>key</code> is the xpath of the destination field and <code>value</code> is the exact label (case sensitive) as returned by the PageExtractor (see this operation documentation). If there is no blob or the blob is not a PDF, all the values referenced in <code>properties</code> are cleared (set to empty string, 0, ...).`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: 'file:content',
          },
        ],
      },
      {
        name: 'PDF.ExtractText',
        description: `Extracts raw text from a PDF. If the PDF is encrypted, a password is required. pdfxpath is the xpath of the blob (default to file:content). The extracted text is set in the targetxpath property of the input document, which is saved if save is true. If patterntofind is not provided, extracts all the text it can, else it extracts only the line where the pattern is found. If patterntofind is provided and removepatternfromresult is true, the line is returned without the pattern.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'password',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'patterntofind',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pdfxpath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'removepatternfromresult',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'targetxpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Proxy.GetSourceDocument',
        description: `Get the live document even if this is a Proxy or Version Document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'User.GetCollections',
        description: `Get the list of all the collections visible by the currentUser. This is returning a list of collections.`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'searchTerm',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },

  {
    name: 'Execution Context',
    types: [
      {
        name: 'Context.RestoreBlobInput',
        description: `Restore the file input from a context variable given its name. Return the file.`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreBlobInputFromScript',
        description: `Run a script and return the result blob object of the script the output of the operation`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'script',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreBlobsInput',
        description: `Restore the file list input from a context variable given its name. Return the files.`,
        accepts: 'void',
        produces: 'bloblist',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreBlobsInputFromScript',
        description: `Run a script and return the result Blobs object of the script the output of the operation`,
        accepts: 'void',
        produces: 'bloblist',
        fields: [
          {
            name: 'script',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreDocumentInput',
        description: `Restore the document input from a context variable given its name. Return the document.`,
        accepts: 'void',
        produces: 'document',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreDocumentInputFromScript',
        description: `Run a script and return the result Document object of the script the output of the operation`,
        accepts: 'void',
        produces: 'document',
        fields: [
          {
            name: 'script',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreDocumentsInput',
        description: `Restore the document list input from a context variable given its name. Return the document list.`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.RestoreDocumentsInputFromScript',
        description: `Run a script and return the result documents object of the script the output of the operation`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'script',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Context.SetInputAsVar',
        description: `Set a context variable that points to the current input object. You must give a name for the variable. This operation works on any input type and return back the input as the output.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.SetMetadataFromBlob',
        description: `Read Metadata From binary to Context for a given input blob and given metadata to inject into the Operation context (if not specified, all metadata will be injected)`,
        accepts: 'blob',
        produces: 'void',
        fields: [
          {
            name: 'ignorePrefix',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'metadata',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'processor',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.SetVar',
        description: `Set a context variable given a name and the value. To compute the value at runtime from the current context you should use an EL expression as the value. This operation works on any input type and return back the input as the output.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'value',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Execution Flow',
    types: [
      {
        name: 'Context.RunDocumentOperationInNewTx',
        description: `Run an operation chain in a separate tx. The 'parameters' injected are accessible in the subcontext ChainParameters. For instance, @{ChainParameters['parameterKey']}.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'rollbackGlobalOnError',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'timeout',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Repository.SaveSession',
        description: `Commit any changes made by the operation on the documents. This can be used to explicitly commit changes. This operation can be executed on any type of input. The input of this operation will be preserved as the input for the next operation in the chain.`,
        accepts: 'void',
        produces: 'void',
        fields: [],
      },
      {
        name: 'RunDocumentOperation',
        description: `Run an operation chain which is returning a document in the current context. The input for the chain ro run is the current input of the operation. Return the output of the chain as a document. The 'parameters' injected are accessible in the subcontext ChainParameters. For instance, @{ChainParameters['parameterKey']}`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'newTx',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'rollbackGlobalOnError',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'timeout',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'RunFileOperation',
        description: `Run an operation chain which is returning a file in the current context. The input for the chain to run is a file or a list of files. Return the output of the chain as a file or a list of files. The 'parameters' injected are accessible in the subcontext ChainParameters. For instance, @{ChainParameters['parameterKey']}.`,
        accepts: 'bloblist, blob',
        produces: 'bloblist, blob',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'newTx',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'rollbackGlobalOnError',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'timeout',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'RunOperation',
        description: `Run an operation chain in the current context. The 'parameters' injected are accessible in the subcontext ChainParameters. For instance, @{ChainParameters['parameterKey']}`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'RunOperationOnList',
        description: `Run an operation for each element from the list defined by the 'list' parameter. The 'list' parameter is pointing to a context variable that represents the list which will be iterated. The 'item' parameter represents the name of the context variable which will point to the current element in the list at each iteration. You can use the 'isolate' parameter to specify whether or not the evalution context is the same as the parent context or a copy of it. If the 'isolate' parameter is 'true' then a copy of the current context is used and so that modifications in this context will not affect the parent context. Any input is accepted. The input is returned back as output when operation terminates. The 'parameters' injected are accessible in the subcontext ChainParameters. For instance, @{ChainParameters['parameterKey']}.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'list',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'item',
            required: false,
            tagType: 'input',
            value: 'item',
          },
          {
            name: 'newTx',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'rollbackGlobalOnError',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'timeout',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'RunOperationOnListInNewTx',
        description: `Run an operation/chain in a new Transaction for each element from the list defined by the 'list' parameter. The 'list' parameter is pointing to a context variable that represents the list which will be iterated. The 'itemName' parameter represents the name of the context variable which will point to the current element in the list at each iteration. You can use the 'isolate' parameter to specify whether or not the evalution context is the same as the parent context or a copy of it. If the 'isolate' parameter is 'true' then a copy of the current context is used and so that modifications in this context will not affect the parent context. Any input is accepted. The input is returned back as output when operation terminates.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'list',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'itemName',
            required: false,
            tagType: 'input',
            value: 'item',
          },
        ],
      },
      {
        name: 'RunOperationOnProvider',
        description: `Run an operation for each page of the provider defined by the provider name, the operation input is the curent page`,
        accepts: 'documents',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'isolate',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Fetch',
    types: [
      {
        name: 'Blob.CreationFromURL',
        description: `Creates a file from a given URL. The file parameter specifies how to retrieve the file content. It should be an URL to the file you want to use as the source. You can also use an expression to get an URL from the context. Returns the created file.`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'file',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'encoding',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'filename',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'mime-type',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.FetchDocument',
        description: `Fetch the input of the context as a document or list of documents. The document will become the input for the next operation.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Context.FetchFile',
        description: `Fetch the input of the context as a file or list of files. The file(s) will become the input for the next operation.`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [],
      },
      {
        name: 'Document.FetchByProperty',
        description: `For each specified string property value, fetch all documents that match the property and the optional where clause. Matching documents are collected into a list and the returned to the next operation. The operation has no input.`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'property',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'values',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'query',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Repository.GetDocument',
        description: `Fetch a document from the repository given its reference (path or UID). The document will become the input of the next operation.`,
        accepts: 'void',
        produces: 'document',
        fields: [
          {
            name: 'value',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Repository.PageProvider',
        description: `Perform a named provider query on the repository. Result is paginated. The query result will become the input for the next operation`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'providerName',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'currentPageIndex',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'documentLinkBuilder',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'highlights',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'language',
            required: false,
            tagType: 'select',
            value: ['NXQL'],
          },
          {
            name: 'namedParameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'pageSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'queryParams',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'quickFilters',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortBy',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortOrder',
            required: false,
            tagType: 'select',
            value: ['ASC', 'DESC'],
          },
        ],
      },
      {
        name: 'Repository.Query',
        description: `Perform a query on the repository. The document list returned will become the input for the next operation.If no provider name is given, a query returning all the documents that the user has access to will be executed.`,
        accepts: 'void',
        produces: 'documents',
        fields: [
          {
            name: 'currentPageIndex',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'language',
            required: false,
            tagType: 'select',
            value: ['NXQL'],
          },
          {
            name: 'maxResults',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'namedParameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'pageSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'Query',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'queryParams',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortBy',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortOrder',
            required: false,
            tagType: 'select',
            value: ['ASC', 'DESC'],
          },
        ],
      },
      {
        name: 'Repository.ResultSSetQuery',
        description: `Perform a query on the repository. The result set returned will become the input for the next operation. If no query or provider name is given, a query returning all the documents that the user has access to will be executed.`,
        accepts: 'void',
        produces: 'recordset',
        fields: [
          {
            name: 'ResultSet Query',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'currentPageIndex',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'language',
            required: false,
            tagType: 'select',
            value: ['NXQL', 'CMIS'],
          },
          {
            name: 'maxResults',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'namedParameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'pageSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'queryParams',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortBy',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'sortOrder',
            required: false,
            tagType: 'select',
            value: ['ASC', 'DESC'],
          },
        ],
      },
    ],
  },

  {
    name: 'Files',
    types: [
      {
        name: 'Blob.AttachOnDocument',
        description: `Attach the input file to the document given as a parameter. If the xpath points to a blob list then the blob is appended to the list, otherwise the xpath should point to a blob property. If the save parameter is set the document modification will be automatically saved. Return the blob.`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'document',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.BulkDownload',
        description: `Prepare a Zip of a list of documents.`,
        accepts: 'documents',
        produces: 'blob',
        fields: [
          {
            name: 'filename',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.CreateZip',
        description: `Creates a zip file from the input file(s). If no file name is given, the first file name in the input will be used. Returns the zip file`,
        accepts: 'bloblist, blob',
        produces: 'blob, blob',
        fields: [
          {
            name: 'filename',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.ExportToFS',
        description: `Save the input blob(s) as a file(s) into the given target directory. The blob(s) filename is used as the file name. You can specify an optional <b>prefix</b> string to prepend to the file name. Return back the blob(s).`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'directory',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'prefix',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.PostToURL',
        description: `Post the input file to a target HTTP URL. Returns back the input file.`,
        accepts: 'blob, bloblist',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'url',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.RemoveFromDocument',
        description: `Remove the file attached to the input document as specified by the 'xpath' parameter. If the 'xpath' point to a blob list then the list will be cleared. If the file to remove is part of a list it will be removed from the list otherwise the 'xpath' should point to a blob property that will be removed. If the save parameter is set the document modification will be automatically saved. Return the document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'XPath',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.SetMetadataFromContext',
        description: `Write Metadata To Blob From Context given a processor name (or the default Nuxeo one) and given metadata, and return the updated Blob.`,
        accepts: 'blob',
        produces: 'blob',
        fields: [
          {
            name: 'metadata',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'ignorePrefix',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'processor',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Blob.SetMetadataFromDocument',
        description: `Write metadata to a Blob (xpath parameter, or BlobHolder if empty) from a document (input) given a custom metadata mapping defined in a Properties parameter, using a named processor (exifTool for instance).`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'metadata',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'blobXPath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ignorePrefix',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'processor',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'BlobHolder.AttachOnCurrentDocument',
        description: `Attach the input file(s) to the current document using the BlobHolder abstraction`,
        accepts: 'bloblist, blob',
        produces: 'document, document',
        fields: [
          {
            name: 'useMainBlob',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Document.GetBlob',
        description: `Gets a file attached to the input document. The file location is specified using an xpath to the blob property of the document. Returns the file.`,
        accepts: 'document, documents',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.GetBlobs',
        description: `Gets a list of all blobs that are attached on the input document. Returns a list of files.`,
        accepts: 'document, documents',
        produces: 'bloblist, bloblist',
        fields: [],
      },
      {
        name: 'Document.GetBlobsByProperty',
        description: `Gets a list of files that are attached on the input document. The files location should be specified using the blob list property xpath. Returns a list of files.`,
        accepts: 'document, documents',
        produces: 'bloblist, bloblist',
        fields: [
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.GetContainerRendition',
        description: `Gets the list of blob of the folder's children or the collection's members default renditions. Returns a blob list file containing all the default rendition blobs.`,
        accepts: 'document',
        produces: 'blob',
        fields: [
          {
            name: 'limit',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'maxDepth',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'reason',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.GetRendition',
        description: `Gets a document rendition given its name. Returns the rendition blob.`,
        accepts: 'document, documents',
        produces: 'blob, bloblist',
        fields: [
          {
            name: 'renditionName',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.MoveToColdStorage',
        description: `Move the main document content to the cold storage.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.RequestRetrievalFromColdStorage',
        description: `Request a retrieval from cold storage of the content associated with the document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'numberOfDaysOfAvailability',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Document.SetBlobName',
        description: `Modify the filename of a file stored in the input document. The file is found in the input document given its xpath specified through the 'xpath' parameter. Return back the input document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'XPath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Document.SetMetadataFromBlob',
        description: `Write Metadata To Document From Binary according to metadata mapping.`,
        accepts: 'document',
        produces: 'void',
        fields: [
          {
            name: 'metadataMappingId',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'processor',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Video.AddWatermark',
        description: `Watermark the video with the picture stored in file:content of watermark, at the position(x, y) from the left-top corner of the picture.`,
        accepts: 'blob, bloblist, document, documents',
        produces: 'blob, bloblist, blob, bloblist',
        fields: [
          {
            name: 'watermark',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'x',
            required: false,
            tagType: 'input',
            value: '0',
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'y',
            required: false,
            tagType: 'input',
            value: '0',
          },
        ],
      },
      {
        name: 'Video.Concat',
        description: `Merge 2-n videos in one.`,
        accepts: 'bloblist, documents',
        produces: 'blob, blob',
        fields: [
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Local Configuration',
    types: [
      {
        name: 'LocalConfiguration.PutSimpleConfigurationParameter',
        description: `Put a Simple Configuration parameter on the input document. Add the 'SimpleConfiguration' facet on the input document if needed. The user adding a parameter must have WRITE access on the input document`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'key',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'value',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'LocalConfiguration.PutSimpleConfigurationParameters',
        description: `Put Simple Configuration parameters on the input document. Add the 'SimpleConfiguration' facet on the input document if needed. The parameters are specified as <i>key=value</i> pairs separated by a new line. The user adding parameters must have WRITE access on the input document.`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'parameters',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'save',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'LocalConfiguration.SetSimpleConfigurationParameterAsVar',
        description: `Set a context variable that points to the value of the given parameter name in the SimpleConfiguration from the input Document. You must give a name for the variable.`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'parameters',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Notification',
    types: [
      {
        name: 'Document.Mail',
        description: `Send an email using the input document to the specified recipients. You can use the HTML parameter to specify whether you message is in HTML format or in plain text. Also you can attach any blob on the current document to the message by using the comma separated list of xpath expressions 'files'. If you xpath points to a blob list all blobs in the list will be attached. Return back the input document(s). If rollbackOnError is true, the whole chain will be rollbacked if an error occurs while trying to send the email (for instance if no SMTP server is configured), else a simple warning will be logged and the chain will continue.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'from',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'message',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'subject',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'HTML',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'Strict User Resolution',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'bcc',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'cc',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'files',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'replyto',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'rollbackOnError',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
          {
            name: 'to',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'viewId',
            required: false,
            tagType: 'input',
            value: 'view_documents',
          },
        ],
      },
      {
        name: 'Event.Fire',
        description: `Send a Nuxeo event.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Log',
        description: `Logging with log4j`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'level',
            required: true,
            tagType: 'select',
            value: ['info', 'debug', 'warn', 'error'],
          },
          {
            name: 'message',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'category',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Push & Pop',
    types: [
      {
        name: 'Context.PopBlob',
        description: `Restore the last saved input file in the context input stack. This operation must be used only if a PUSH operation was previously made. Return the last <i>pushed</i> file.`,
        accepts: 'void',
        produces: 'blob',
        fields: [],
      },
      {
        name: 'Context.PopBlobList',
        description: `Restore the last saved input file list in the context input stack`,
        accepts: 'void',
        produces: 'bloblist',
        fields: [],
      },
      {
        name: 'Context.PopDocument',
        description: `Restore the last saved input document in the context input stack. This operation must be used only if a PUSH operation was previously made. Return the last <i>pushed</i> document.`,
        accepts: 'void',
        produces: 'document',
        fields: [],
      },
      {
        name: 'Context.PopDocumentList',
        description: `Restore the last saved input document list in the context input stack`,
        accepts: 'void',
        produces: 'documents',
        fields: [],
      },
      {
        name: 'Context.PullBlob',
        description: `Restore the last saved input file in the context input stack. This operation must be used only if a PUSH operation was previously made. Return the first <i>pushed</i> file.`,
        accepts: 'void',
        produces: 'blob',
        fields: [],
      },
      {
        name: 'Context.PullBlobList',
        description: `Restore the first saved input file list in the context input stack`,
        accepts: 'void',
        produces: 'bloblist',
        fields: [],
      },
      {
        name: 'Context.PullDocument',
        description: `Restore the first saved input document in the context input stack. This operation must be used only if a PUSH operation was previously made. Return the first <i>pushed</i> document.`,
        accepts: 'void',
        produces: 'document',
        fields: [],
      },
      {
        name: 'Context.PullDocumentList',
        description: `Restore the first saved input document list in the context input stack`,
        accepts: 'void',
        produces: 'documents',
        fields: [],
      },
      {
        name: 'Context.PushBlob',
        description: `Push the input file on the context stack. The file can be restored later as the input using the corrresponding pop operation. Returns the input file.`,
        accepts: 'blob',
        produces: 'blob',
        fields: [],
      },
      {
        name: 'Context.PushBlobList',
        description: `Push the input file list on the context stack. The file list can be restored later as the input using the corrresponding pop operation. Returns the input file list.`,
        accepts: 'bloblist',
        produces: 'bloblist',
        fields: [],
      },
      {
        name: 'Context.PushDocument',
        description: `Push the input document on the context stack. The document can be restored later as the input using the corrresponding pop operation. Returns the input document.`,
        accepts: 'document',
        produces: 'document',
        fields: [],
      },
      {
        name: 'Context.PushDocumentList',
        description: `Push the input document list on the context stack. The document list can be restored later as the input using the corrresponding pop operation. Returns the input document list.`,
        accepts: 'documents',
        produces: 'documents',
        fields: [],
      },
    ],
  },
  {
    name: 'Scripting',
    types: [
      {
        name: 'RunInputScript',
        description: `Run a script from the input blob. A blob comtaining script result is returned.`,
        accepts: 'blob',
        produces: 'blob',
        fields: [
          {
            name: 'type',
            required: false,
            tagType: 'select',
            value: ['mvel', 'groovy'],
          },
        ],
      },
      {
        name: 'RunScript',
        description: `Run a script which content is specified as text in the 'script' parameter`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'script',
            required: true,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Services',
    types: [
      {
        name: 'Audit.LogEvent',
        description: `Log events into audit for each of the input document. The operation accept as input one ore more documents that are returned back as the output.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'event',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'category',
            required: false,
            tagType: 'input',
            value: 'Automation',
          },
          {
            name: 'comment',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Audit.Restore',
        description: `Restore log entries from an audit storage implementation to the audit backend.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'auditStorage',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'batchSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'keepAlive',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Bulk.RunAction',
        description: `Run a bulk action on a set of documents expressed by a NXQL.`,
        accepts: 'void',
        produces: 'bulkstatus',
        fields: [
          {
            name: 'action',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'batchSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'bucketSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'namedParameters',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'parameters',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'providerName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'query',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'queryParams',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'quickFilters',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'repositoryName',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Directory.CreateVocabularyEntry',
        description: `Add a new entry in the <i>vocabularyName</i> vocabulary only if <i>id</i> is not found (an existing entry isnot updated). If <i>label</i> is empty, it is set to the id. WARNING: Current user must have enough rights to write in a vocabulary.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'vocabularyName',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'label',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'obsolete',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ordering',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'parent',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Directory.LoadFromCSV',
        description: `Load directory entries from a CSV file. Depending on the data loading policy, duplicate entries are ignored, updated or trigger an error.`,
        accepts: 'blob',
        produces: 'void',
        fields: [
          {
            name: 'dataLoadingPolicy',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'directoryName',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Directory.AddRelation',
        description: `Create a relation between 2 documents. The subject of the relation will be the input of the operation and the object of the relation will be retrieved from the context using the 'object' field. The 'predicate' field specifies the relation predicate (When using a known predicate, use the full URL like 'http://purl.org/dc/terms/IsBasedOn', unknown predicates will be treated as plain strings and be the same on the subject and object). The 'outgoing' flag indicates the direction of the relation - the default is false which means the relation will go from the input object to the object specified as 'object' parameter. Return back the subject document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'object',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'predicate',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'outgoing',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Directory.DeleteRelation',
        description: `Delete a relation between 2 documents. The subject of the relation will be the input of the operation and the object of the relation will be retrieved from the context using the 'object' field. The 'predicate' field specifies the relation predicate (When using a known predicate, use the full URL like 'purl.org/dc/terms/IsBasedOn', unknown predicates will be treated as plain strings and be the same on the subject and object). The 'outgoing' flag indicates the direction of the relation - the default is false which means the relation will go from the input object to the object specified as 'object' parameter. Return back the subject document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'object',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'predicate',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'outgoing',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Directory.Export',
        description: `Export the given document.`,
        accepts: 'document',
        produces: 'blob',
        fields: [
          {
            name: 'exportAsTree',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'exportAsZip',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Directory.GetLinkedDocuments',
        description: `Get the relations for the input document. The 'outgoing' parameter ca be used to specify whether outgoing or incoming relations should be returned. Retuns a document list.`,
        accepts: 'document',
        produces: 'documents',
        fields: [
          {
            name: 'predicate',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'graphName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'outgoing',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Elasticsearch.Index',
        description: `Enable to index Nuxeo documents.`,
        accepts: 'void, string, document',
        produces: 'void, void, void',
        fields: [],
      },
      {
        name: 'Elasticsearch.WaitForIndexing',
        description: `Wait until Elasticsearch indexing is done.`,
        accepts: 'void',
        produces: 'boolean',
        fields: [
          {
            name: 'refresh',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'timeoutSecond',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'waitForAudit',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'FileManager.Import',
        description: `Create Document(s) from Blob(s) using the FileManagerService. The destination container must be passed in a Context variable named currentDocument.`,
        accepts: 'bloblist, blob',
        produces: 'documents, document',
        fields: [
          {
            name: 'noMimeTypeCheck',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'overwite',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'FileManager.ImportWithProperties',
        description: `Create Document(s) from Blob(s) using the FileManagerService and set multiple properties on them.The destination container must be passed in a Context variable named currentDocument. <p>The properties are specified as <i>key=value</i> pairs separated by a new line. The key used for a property is the property xpath. To specify multi-line values you can use a \ character followed by a new line. <p>Example:<pre>dc:title=The Document Title<br>dc:description=foo bar</pre>For updating a date, you will need to expose the value as ISO 8601 format, for instance : <p>Example:<pre>dc:title=The Document Title<br>dc:issued=@{org.nuxeo.ecm.core.schema.utils.DateParser.formatW3CDateTime(CurrentDate.date)}</pre><p>Returns back the updated document.<p>To update a multi-valued field with multiple values:<pre>custom:multivalued=a,b,c,d</pre>`,
        accepts: 'bloblist, blob',
        produces: 'documents, document',
        fields: [
          {
            name: 'properties',
            required: true,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'mimeTypeCheck',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'overwrite',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'PermissionPurge',
        description: `Schedule a work to archive ACEs based on permissions_purge page provider from the input document.`,
        accepts: 'void, document, list',
        produces: 'void, void, void',
        fields: [
          {
            name: 'usernames',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Picture.Create',
        description: `Create a Picture document in the input folder. You can initialize the document properties using the 'properties' parameter. The properties are specified as <i>key=value</i> pairs separated by a new line. The key <i>originalPicture</i> is used to reference the JSON representation of the Blob for the original picture. The <i>pictureTemplates</i> parameter can be used to define the size of the different views to be generated, each line must be a JSONObject { title="title", description="description", maxsize=maxsize}. Returns the created document.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'name',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pictureTemplates',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Picture.RecomputeViews',
        description: `Recompute the picture views of the documents resulting from the provided NXQL query.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'query',
            required: true,
            tagType: 'input',
            value: `SELECT * FROM Document WHERE ecm:mixinType = 'Picture' AND picture:views/*/title IS NULL`,
          },
        ],
      },
      {
        name: 'RecomputeThumbnails',
        description: `Recompute the thumbnail of the documents resulting from the provided NXQL query.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'query',
            required: true,
            tagType: 'input',
            value: `SELECT * FROM Document WHERE ecm:mixinType = 'Thumbnail' AND thumb:thumbnail/data IS NULL AND ecm:isVersion = 0 AND ecm:isProxy = 0 AND ecm:isTrashed = 0`,
          },
        ],
      },
      {
        name: 'Service.HotReloadStudioSnapshot',
        description: `Updates Studio project with latest snapshot.`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'validate',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Services.RemoveDocumentTags',
        description: `Remove all document tags.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [],
      },
      {
        name: 'Services.TagDocument',
        description: `Tag document with one or several 'tags'.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'tags',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Services.UntagDocument',
        description: `Untag document from one or several 'tags'.`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'tags',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Tag.Suggestion',
        description: `Get tag suggestion`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'document',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'searchTerm',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'value',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'xpath',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Task.Create',
        description: `Enable to create a task bound to the document. <p><b>Directive</b>, <b>comment</b> and <b>due date</b> will be displayed in the task list of the user. In <b>accept operation chain</b> and <b>reject operation chain</b> fields, you can put the operation chain ID of your choice among the one you contributed. Those operations will be executed when the user validates the task, depending on whether he accepts or rejects the task. You have to specify a variable name (the <b>key for ... </b> parameter) to resolve target users and groups to which the task will be assigned. You can use Get Users and Groups to update a context variable with some users and groups. If you check <b>create one task per actor</b>, each of the actors will have a task to achieve, versus "the first who achieve the task makes it disappear for the others".</p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'task name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'due date',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'directive',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'accept operation chain',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'reject operation chain',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'variable name for actors prefixed ids',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'additional list of actors prefixed ids',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'create one task per actor',
            required: false,
            tagType: 'checkbox',
            value: true,
          },
        ],
      },
      {
        name: 'Task.GetAssigned',
        description: `List tasks assigned to this user or one of its group.Task properties are serialized using JSON and returned in a Blob.`,
        accepts: 'void',
        produces: 'blob',
        fields: [],
      },
      {
        name: 'UserWorkspace.CreateDocumentFromBlob',
        description: `Create Document(s) in the user's workspace from Blob(s) using the FileManagerService.`,
        accepts: 'bloblist, blob',
        produces: 'documents, document',
        fields: [],
      },
    ],
  },
  {
    name: 'User & Groups',
    types: [
      {
        name: 'Auth.LoginAs',
        description: `Login As the given user. If no user is given a system login is performed. This is a void operations - the input will be returned back as the output.`,
        accepts: 'void, document',
        produces: 'void, document',
        fields: [
          {
            name: 'name',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Auth.Logout',
        description: `Perform a logout. This should be used only after using the Login As operation to restore original login. This is a void operations - the input will be returned back as the output.`,
        accepts: 'void, document',
        produces: 'void, document',
        fields: [],
      },
      {
        name: 'Context.GetEmailsWithPermissionOnDoc',
        description: `Fetch the principal emails that have a given permission on the input document and then set them in the context under the given key variable name. The operation returns the input document. You can later use the list of principals set by this operation on the context from another operation. The 'key' argument represents the variable name and the 'permission' argument the permission to check. If the 'ignore groups' argument is false then groups are recursively resolved, extracting user members of these groups. Be <b>warned</b> that this may be a very consuming operation.<ul>Note that <li></li><li>groups are not included</li><li>the list pushed into the context is a string list of emails.</li></ul>`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'permission',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'variable name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ignore groups',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Context.GetUsersGroupIdsWithPermissionOnDoc',
        description: `Fetch the users and groups that have a given permission on the input document and then set them in the context under the given key variable name. The operation returns the input document. You can later use the list of identifiers set by this operation on the context from another operation. The 'key' argument represents the variable name and the 'permission' argument the permission to check. If the 'ignore groups' argument is false then groups will be part of the result. If the 'resolve groups' argument is true then groups are recursively resolved, adding user members of these groups in place of them. Be <b>warned</b> that this may be a very consuming operation. If the 'prefix identifiers' argument is true, then user identifiers are prefixed by 'user:' and groups identifiers are prefixed by 'group:'.`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'permission',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'variable name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'ignore groups',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'prefix identifiers',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'resolve groups',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'Group.CreateOrUpdate',
        description: `Create or Update Group`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'groupname',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'description',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'grouplabel',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'members',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'mode',
            required: false,
            tagType: 'input',
            value: 'createOrUpdate',
          },
          {
            name: 'parentGroups',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'subGroups',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'tenantId',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'User.CreateOrUpdate',
        description: `Create or Update User.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'groupname',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'description',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'grouplabel',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'members',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'mode',
            required: false,
            tagType: 'input',
            value: 'createOrUpdate',
          },
          {
            name: 'parentGroups',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'properties',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'subGroups',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'tenantId',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'User.Get',
        description: `Retrieve Nuxeo principal and export it as a DocumentModel, if login parameter is not set the Operation will return informations about the current user, otherwise Directory Administration rights are required.`,
        accepts: 'void',
        produces: 'document',
        fields: [
          {
            name: 'login',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'User.GetUserWorkspace',
        description: `Retrieve user's personal workspace.`,
        accepts: 'void',
        produces: 'document',
        fields: [],
      },
      {
        name: 'User.Invite',
        description: `Stores a registration request and returns its ID.`,
        accepts: 'nuxeoprincipal',
        produces: 'string',
        fields: [
          {
            name: 'autoAccept',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'info',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'validationMethod',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'User.Query',
        description: `Query users on a combination of their username, firstName and lastName fields, or on any of them (pattern).`,
        accepts: 'void',
        produces: 'blob',
        fields: [
          {
            name: 'firstName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'lastName',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'pattern',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'tenantId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'username',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Workflow Context',
    types: [
      {
        name: 'Context.SetWorkflowVar',
        description: `Set a workflow variable. The workflow variable must exists on the workflow. If no workflowId is specified the variable is set on the current workflow.To compute the value at runtime from the current context you should use a MVEL expression as the value. This operation works on any input type and return back the input as the output.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'value',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'workflowInstanceId',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Context.StartWorkflow',
        description: `Starts the workflow with the given model id on the input documents. Returns back the input documents.The id of the created workflow instance is available under the "workflowInstanceId" context variable.@Since 5.7.2 you can set multiple variables on the workflow (before 5.8 only scalar types are supported). The variables are specified as <i>key=value</i> pairs separated by a new line.To specify multi-line values you can use a \ character followed by a new line. <p>Example:<pre>description=foo bar</pre>For updating a date, you will need to expose the value as ISO 8601 format, for instance : <p>Example:<pre>title=The Document Title<br>issued=@{org.nuxeo.ecm.core.schema.utils.DateParser.formatW3CDateTime(CurrentDate.date)}</pre><p> @since 5.9.3 and 5.8.0-HF10 you can also set variables of complex types, by submiting a JSON representation: <p><pre>assignees = ["John Doe", "John Test"]</pre></p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'start',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
          {
            name: 'variables',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
      {
        name: 'Task.ApplyDocumentMapping',
        description: `Applies the mapping passed in parameter on the task document. The sourceDoc in the mapping is the input document in the workflow. The operation throws a NuxeoException if the input document is not a Task.`,
        accepts: 'document',
        produces: 'document',
        fields: [
          {
            name: 'mappingName',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Workflow.GetOpenTasks',
        description: `Returns all open tasks for the input document(s). If the operation is invoked with parameters, all tasks instances for the given 'processId' originating from the given 'nodeId' are returned. The 'processId' is the id of the document representing the workflow instance. The parameter 'username' is used to fetch only tasks assigned to the given user. Tasks are queried using an unrestricted session.`,
        accepts: 'document, documents',
        produces: 'documents, documents',
        fields: [
          {
            name: 'nodeId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'processId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'username',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Workflow.ResumeNode',
        description: `Resumes a route instance on a given node. When a parameter is not specified, it will be fetched from the current context if the operation is executed in the context of a running workflow (it applies to the current workflow and to the current node).`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'nodeId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'workflowInstanceId',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'Workflow.SetNodeVariable',
        description: `Set a workflow node variable given a name and the value in the context of a running workflow. To compute the value at runtime from the current context you should use an EL expression as the value. This operation works on any input type and return back the input as the output.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'name',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'value',
            required: false,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'WorkflowInstance.Cancel',
        description: `Cancel the workflow with the given id, where the required id is the id of the document representing the workflow instance.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'id',
            required: true,
            tagType: 'input',
            value: null,
          },
        ],
      },
      {
        name: 'WorkflowModel.BulkRestartInstances',
        description: `Bulk operation to restart workflows.`,
        accepts: 'void',
        produces: 'void',
        fields: [
          {
            name: 'workflowId',
            required: true,
            tagType: 'input',
            value: null,
          },
          {
            name: 'batchSize',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'nodeId',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'reinitLifecycle',
            required: false,
            tagType: 'checkbox',
            value: false,
          },
        ],
      },
      {
        name: 'WorkflowTask.Complete',
        description: `Completes the input task. If this is the last task the workflow will continue. Returns back the task document. "Status" is the id of the button the user would have clicked to submit the task form (if the outgoing transitions of the workflow node that created the task have conditions depending on it).@since 5.9.3 and 5.8.0-HF11 you can set multiple node or workflow variables when completing the task (also similar to ending the task via form submision from the UI).The variables are specified as <i>key=value</i> pairs separated by a new line.To specify multi-line values you can use a \ character followed by a new line. <p>Example:<pre>description=foo bar</pre>For updating a date, you will need to expose the value as ISO 8601 format, for instance : <p>Example:<pre>workflowVarString=A sample value<br>workflowVarDate=@{org.nuxeo.ecm.core.schema.utils.DateParser.formatW3CDateTime(CurrentDate.date)}</pre><p>For all values, you have to submit a JSON representation. This is an example for a variable of type StringList:<p><pre>nodeVarList = ["John Doe", "John Test"]</pre></p>`,
        accepts: 'document, documents',
        produces: 'document, documents',
        fields: [
          {
            name: 'comment',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'nodeVariables',
            required: false,
            tagType: 'textarea',
            value: null,
          },
          {
            name: 'status',
            required: false,
            tagType: 'input',
            value: null,
          },
          {
            name: 'workflowVariables',
            required: false,
            tagType: 'textarea',
            value: null,
          },
        ],
      },
    ],
  },
];
