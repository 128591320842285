import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2, TreeItem2Props } from "@mui/x-tree-view";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { forwardRef, SyntheticEvent } from "react";
import { StructureTemplateProps } from "../../../../../../types/content/structureTemplates/StructureTemplateProps";
import { FieldArrayWithId } from "react-hook-form";
import { CreateUpdateStructureTemplateProps } from "../../../../../../types/content/structureTemplates/CreateUpdateStructureTemplateProps";

interface IProps {
  structureTemplate: StructureTemplateProps;
  children: FieldArrayWithId<
    CreateUpdateStructureTemplateProps,
    "childs",
    "id"
  >[];
  selectedNode: string;
  setSelectedTemplate: (template: any) => void;
}

export function StructureTemplateTree({
  structureTemplate,
  children,
  selectedNode,
  setSelectedTemplate,
}: IProps) {
  // EVENTS
  const handleSelectedItemsChange = (
    event: SyntheticEvent<Element, Event>,
    ids: string | null
  ) => {
    if (ids) {
      // Найдем данные по featureId: это либо корень, либо ребенок
      // Зафиксируем
      // Ошибки, если что-то null, игнорируем.
      if (ids === structureTemplate.featureId) {
        setSelectedTemplate({ featureId: ids, isRoot: true });
      } else {
        const templateIndex = children.findIndex(
          (elem) => ids === elem.featureId
        );
        setSelectedTemplate({
          featureId: ids,
          isRoot: false,
          childName:
            templateIndex !== -1 ? children[templateIndex].name : undefined,
          childIndex: templateIndex !== -1 ? templateIndex : undefined,
        });
      }
    }
  };

  //RENDERING

  interface CustomLabelProps {
    children: string;
    className: string;
    secondaryLabel: string;
  }

  function RootLabel({ children, className }: CustomLabelProps) {
    return (
      <div className={className} style={{ display: "flex", gap: "10px" }}>
        <AccountTreeOutlinedIcon />
        <Typography>{children}</Typography>
      </div>
    );
  }

  const RootTreeItem = forwardRef(function CustomTreeItem(
    props: TreeItem2Props,
    ref: React.Ref<HTMLLIElement>
  ) {
    return (
      <TreeItem2
        {...props}
        ref={ref}
        slots={{
          label: RootLabel,
        }}
      />
    );
  });

  return (
    <SimpleTreeView
      slots={{
        expandIcon: AddBoxOutlinedIcon,
        collapseIcon: IndeterminateCheckBoxOutlinedIcon,
      }}
      selectedItems={selectedNode}
      expansionTrigger={"iconContainer"}
      defaultExpandedItems={[structureTemplate.featureId]}
      onSelectedItemsChange={handleSelectedItemsChange}
    >
      <RootTreeItem
        itemId={structureTemplate.featureId}
        label={structureTemplate.targetDocType}
        slots={{ checkbox: TextSnippetOutlinedIcon }}
      >
        {children.map((node) => {
          return (
            <TreeItem2
              itemId={node.featureId}
              label={node.name}
              slots={{ iconContainer: TextSnippetOutlinedIcon }}
            />
          );
        })}
      </RootTreeItem>
    </SimpleTreeView>
  );
}
