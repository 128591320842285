import { Typography } from "@mui/material"

interface IProps {
    countErrors: number
}
const ErrorLabel = ({countErrors}: IProps) => {
    return (
        <Typography 
            sx = {{minWidth: '18px', height: '18px', bgcolor: 'red', color: 'white', borderRadius: '50%', textAlign: 'center'}}
        >
            {countErrors}
        </Typography>
    )
}

export default ErrorLabel;