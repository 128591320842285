import { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
} from 'react-flow-renderer';
import { Box, Button } from '@mui/material';
import { PackageProps } from '../../../../types/packages/PackageProps';
import ControlPanel from './ControlPanel';
import NodeEditor from './NodeEditor';
import EdgeEditor from './EdgeEditor';

interface Box {
  id: string;
  boxType: string;
  name: string;
  description: string;
  pointX: number;
  pointY: number;
}

interface ConnectionData {
  id: string;
  name: string;
  stateFrom: string;
  stateTo: string;
}

interface LifeCycleData {
  featureId: string;
  boxes: Box[];
  connections: ConnectionData[];
  project: string;
}

export interface LifeCycleEditorProps {
  //   lifeCycles: string | LifeCycleData;
  lifeCycles: any[];
  userPackage: PackageProps;
  update: (id: string, data: any) => Promise<void>;
  featureId: string | undefined;
}

const LifeCycleEditor = ({ lifeCycles, userPackage, update, featureId }: LifeCycleEditorProps) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [boxNameMap, setBoxNameMap] = useState<{ [key: string]: string }>({});
  const [initialData, setInitialData] = useState<LifeCycleData | null>(null);

  const lifeCycleData: LifeCycleData | null = useMemo(() => {
    if (Array.isArray(lifeCycles)) {
      return lifeCycles.length > 0 ? lifeCycles[0] : null;
    } else if (typeof lifeCycles === 'string') {
      return JSON.parse(lifeCycles);
    } else {
      return lifeCycles;
    }
  }, [lifeCycles]);

  useEffect(() => {
    if (lifeCycleData?.boxes && lifeCycleData?.connections) {
      setInitialData(lifeCycleData);
      const updatedNodes = lifeCycleData.boxes.map((box: Box) => ({
        id: box.name,
        data: {
          label: box.name,
          description: box.description,
          boxType: box.boxType,
        },
        position: { x: box.pointX, y: box.pointY },
        type: 'default',
        draggable: true,
      }));

      const boxIdToNameMap = Object.fromEntries(
        lifeCycleData.boxes.map((box) => [box.id, box.name]),
      );

      setBoxNameMap(boxIdToNameMap);

      const updatedEdges = lifeCycleData.connections.map((conn: ConnectionData) => ({
        id: conn.id || `${conn.stateFrom}-${conn.stateTo}`,
        source: boxIdToNameMap[conn.stateFrom] || conn.stateFrom,
        target: boxIdToNameMap[conn.stateTo] || conn.stateTo,
        label: conn.name,
        type: 'step',
      }));

      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }
  }, [lifeCycleData]);

  useEffect(() => {
    setEdges((eds) =>
      eds.map((edge) => ({
        ...edge,
        source: boxNameMap[edge.source] || edge.source,
        target: boxNameMap[edge.target] || edge.target,
      })),
    );
  }, [boxNameMap]);

  const onConnect = (params: Edge | Connection) => {
    const newEdge: Edge<any> = {
      ...params,
      id: `edge-${edges.length + 1}`,
      label: `Связь ${edges.length + 1}`,
      source: params.source as string,
      target: params.target as string,
      type: 'step',
    };
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const onNodesChange = (changes: NodeChange[]) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  };

  const onEdgesChange = (changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };

  const handleSave = async () => {
    const boxes = nodes.map((node) => ({
      name: node.data.label,
      description: node.data.description,
      boxType: node.data.boxType,
      pointX: node.position.x,
      pointY: node.position.y,
      width: 1,
      height: 1,
    }));

    const connections = edges.map((edge) => ({
      name: edge.label,
      stateNameFrom: edge.source,
      stateNameTo: edge.target,
      pointX: 100,
      pointY: 100,
      width: 1,
      height: 1,
    }));

    const data = {
      company: userPackage.company,
      document: userPackage.document,
      featureId: lifeCycleData?.featureId,
      boxes,
      connections,
    };

    try {
      await update(`${featureId}`, data)
        .then(() => {
          console.log('Сохранение успешно выполнено');
        })
        .catch((error) => {
          console.error('Ошибка при сохранении:', error);
        });
    } catch (error) {
      console.log('Ошибка при выполнении обновления:', error);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <ControlPanel
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        setEdges={setEdges}
        boxNameMap={boxNameMap}
        setBoxNameMap={setBoxNameMap}
        initialData={initialData}
        handleSave={handleSave}
      />
      <NodeEditor
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        nodes={nodes}
        setNodes={setNodes}
        setBoxNameMap={setBoxNameMap}
        setEdges={setEdges}
      />
      <EdgeEditor
        selectedEdge={selectedEdge}
        setSelectedEdge={setSelectedEdge}
        edges={edges}
        setEdges={setEdges}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeDoubleClick={(_, node) => setSelectedNode(node)}
        onEdgeDoubleClick={(_, edge) => setSelectedEdge(edge)}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <Background />
      </ReactFlow>
    </div>
  );
};

export default LifeCycleEditor;
