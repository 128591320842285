import { useSelector } from 'react-redux';
import { SchemaWithFieldsJsonProps } from '../../../../types/content/schemas/SchemaWithFieldsProps';
import { PackageProps } from '../../../../types/packages/PackageProps';
import useGet from '../../entity/useGet';

const useSchemaWithFields = (featureId: string) => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = 'schemas/complete-hierarchy';

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<SchemaWithFieldsJsonProps>(
    entityName,
    `project=${userPackage.company}-${userPackage.document}&featureId=${featureId}`,
  );

  return {
    status,
    schema: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useSchemaWithFields;
