import React, { useState } from 'react';
import { Box, TextField, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useFormContext, useWatch } from 'react-hook-form';
import IconSelector from '../../documentTypes/tabs/IconSelector/IconSelector';
import { useTranslation } from 'react-i18next';

const DefinitionForm: React.FC = () => {
  const { register, watch, setValue } = useFormContext();
  const formValues = watch(); // Отслеживаем текущие значения формы
  const { t } = useTranslation();

  // Состояние для хранения имени файлов и обработка выбора файлов
  const handleSelectIcon = (newFileName: string | undefined) => {
    setValue('iconUrl', newFileName || null, { shouldDirty: true });
  };

  const handleSelectLargeIcon = (newFileName: string | undefined) => {
    setValue('largeIconUrl', newFileName || null, { shouldDirty: true });
  };

  return (
    <form>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <Box display="grid" gridTemplateColumns={'auto auto 1fr'} mb={2}> */}
        <Tooltip title={`${t('definitionLabel')}`}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Box
          component="label"
          sx={{
            alignSelf: 'center',
          }}
        >
          Label
        </Box>

        <TextField
          fullWidth
          label="Label"
          variant="outlined"
          style={{ marginLeft: '30%', maxWidth: '300px' }}
          InputLabelProps={{ shrink: true }}
          {...register('label', { required: true })}
          defaultValue={formValues.definition?.label || ''}
        />
      </Box>

      {/* Интеграция IconSelector для выбора иконки */}
      <Box display="flex" alignItems="center" mb={2}>
        <Box
          component="label"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Icon
        </Box>
        <IconSelector
          style={{ marginLeft: '30.5%', display: 'flex', alignItems: 'center' }}
          value={formValues.iconUrl || null}
          onFileSelect={handleSelectIcon}
          onDelete={() => setValue('iconUrl', null, { shouldDirty: true })}
        />
        <Tooltip title={`${t('definitionIcon')}`}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Интеграция IconSelector для выбора крупной иконки */}

      <Box display="flex" alignItems="center" mb={2}>
        <Box
          component="label"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Large Icon
        </Box>
        <IconSelector
          style={{ marginLeft: '30.5%', display: 'flex', alignItems: 'center' }}
          value={formValues.largeIconUrl || null}
          onFileSelect={handleSelectLargeIcon}
          onDelete={() => setValue('largeIconUrl', null, { shouldDirty: true })}
        />
        <Tooltip title={`${t('definitionLargeIcon')}`}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box display="flex" alignItems="center" mb={2}>
        <Box
          component="label"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          Description
          <TextField
            style={{ marginLeft: '28.5%', maxWidth: '300px' }}
            fullWidth
            label="Description"
            multiline
            rows={2}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...register('description')}
            defaultValue={formValues.definition?.description || ''}
          />
          <Tooltip title={`${t('definitionDescription')}`}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </form>
  );
};

export default DefinitionForm;
