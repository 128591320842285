import { Box, FormControl, Stack } from "@mui/material";
import { LabelUserGroupContains } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ListUserGroupContains } from "../../../../../../../staticValues/Constants";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    subfieldIndex: number,
    register: any,
    control: any,
    setValue: any,
    errors: any,
}

const UserGroupAttrs = ({ schemaName, fieldName, fieldNamePrefix, fieldIndex, subfieldIndex, register, control, errors, setValue}: IProps) => {
    const { t } = useTranslation();
       
    return(
        <Box>
            <Stack display="flex" flexDirection="row" marginTop="1em">
                <Controller
                    name={`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`}
                    control={control}
                    render={({ field: {value, onChange} }) => (
                        <FormControl variant="outlined">
                            <ControlledSelect
                                value={value}
                                valueList={ListUserGroupContains}
                                
                                size="small"
                                label={t("userGroupFieldContains")}
                                sx={{
                                    width: 250
                                }}
                                {...register(`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`,{
                                    required: {
                                        value: true,
                                        message: t("inputRequired").replace("%1", t("userGroupFieldContains"))
                                    }
                                })}
                                isTranslate={true}
                                helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.referenceConfiguration.firstParameterValue.message : undefined}
                                error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.referenceConfiguration.firstParameterValue !== undefined : undefined}
                                onChange={onChange}
                            />
                        </FormControl>
                        )}

                 
                        />
                    </Stack>
                    <LabelUserGroupContains schema={schemaName} field={fieldName} />  
                
        </Box>
    );
}

export default UserGroupAttrs;