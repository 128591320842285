import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { SchemaWithFieldsPropsGet, SchemaWithFieldsPropsUpdate } from "../../../../../types/content/schemas/SchemaWithFieldsProps";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Page } from "../../../../common/page/Page";
import { PageHeader } from "../../../../common/page/PageHeader";
import { PageContent } from "../../../../common/page/PageContent";
import { useSearchParams } from "react-router-dom";
import useUpdate from "../../../../../serverInteraction/hooks/entity/useUpdate";
import { createContext } from "react";
import SchemaTitle from "./SchemaTitle";
import SchemaForm from "./SchemaForm";
import FieldAdvancedSettings from "./FieldAdvancedSettings/FieldAdvancedSettings";
import { formatFieldConstrants } from "./FieldAdvancedSettings/validationViews/utils";
import SubFieldAdvancedSettings from "./SubFieldAdvancedSettings/SubFieldAdvancedSettings";
import { useNavigationPrompt } from "../../../../../hooks/useNavigationPrompt/useNavigationPrompt";
import ConfirmDiscardDialog from "../../../../common/dialog/ConfirmDiscardDialog";

interface IProps {
  schema: SchemaWithFieldsPropsGet,
  refetch: any
}

const SchemaView = ({schema, refetch}: IProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const fieldSearchParam = searchParams.get('field') || null;
  const subfieldSearchParam = searchParams.get('subfield') || null;
  const { update } = useUpdate<SchemaWithFieldsPropsUpdate, SchemaWithFieldsPropsGet>('schemas');
  const { control, getValues, setValue, register, watch, handleSubmit, formState: {errors, isDirty}, reset } = useForm<SchemaWithFieldsPropsGet>({
    defaultValues: schema
  });

  //EVENTS 
  const onSubmit = async (data: SchemaWithFieldsPropsUpdate) => {
    let dataForUpdate:SchemaWithFieldsPropsUpdate = data;
    //отформатируем fields перед отправкой
    //constraints: обнулим все неподходящее, отправляем только ненулевые
    dataForUpdate.fields.forEach(field => {
      field.constraints = formatFieldConstrants(field.constraints, field.fieldType, field.isMultiValue)
      field.subFields?.forEach(subfield => {
        subfield.constraints = formatFieldConstrants(subfield.constraints, subfield.fieldType, subfield.isMultiValue)
      })
    })
     
    try {
      await update('id', dataForUpdate);
      setSearchParams();
      refetch();
    } catch (error: any ) {
      console.log(error);
    }
  };

  const onNavigationSave = () => {
    onSubmit(getValues());
  }

  useNavigationPrompt({isDirty, onSave: onNavigationSave});

  const handleDiscardClick = () => {
    reset();
  }
  
  return (
    <Page>
      <PageHeader title={`${t('schemaName')} ${schema.featureId}`}></PageHeader>
      <PageContent subtitle={<SchemaTitle />}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper elevation={2} square sx={{ p: 2 }}>
            { fieldSearchParam === null ? 
              <SchemaForm control={control} register={register} watch = {watch} errors={errors} getValues={getValues} setValue={setValue}/> : 
                subfieldSearchParam === null ? 
                  <FieldAdvancedSettings index={Number(fieldSearchParam)} control={control} register={register} errors={errors} getValues={getValues} setValue={setValue} watch={watch}/> : 
                  <SubFieldAdvancedSettings index={Number(fieldSearchParam)} subindex = {Number(subfieldSearchParam)} control={control} register={register} errors={errors} getValues={getValues} setValue={setValue} watch={watch}/>
            }
          </Paper>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'right',
              display: 'flex',
              paddingTop: '1em',
            }}
          >
            <ConfirmDiscardDialog 
              buttonTitle = {t("button_discard")}
              isNeedConfirm = {isDirty}
              onConfirm = {handleDiscardClick}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              sx={{ marginLeft: '1em' }}
            >
              {t('save')}
            </Button>
          </Box>
        </form>
      </PageContent>
    </Page>
  );
}

export default SchemaView;