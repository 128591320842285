import { useParams } from "react-router-dom";
import useDocType from "../../../../serverInteraction/hooks/content/document-types/useDocType";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Typography } from "@mui/material";
import { GetDocTypesProps } from "../../../../types/content/documentTypes/GetDocTypesProps";
import useFacets from "../../../../serverInteraction/hooks/content/facets/useFacets";
import useDocTypes from "../../../../serverInteraction/hooks/content/document-types/useDocTypes";
import useSchemas from "../../../../serverInteraction/hooks/content/schemas/useSchemas";
import { DocumentTypeView } from "./DocumentTypeView";
import useLifeCycles from "../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles";
import {
  CreateUpdateDocTypesProps,
  TFacetOption,
} from "../../../../types/content/documentTypes/CreateUpdateDocTypesProps";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../types/packages/PackageProps";
import { CommonEntityProps } from "../../../../types/common/CommonEntityProps";
import { convertFieldToFieldType } from "../../../../utils/CommonUtils";

type Params = {
  id: string;
};

export function DocumentTypePage() {
  //Данные документа (исходные)
  const params = useParams<Params>();
  const id = params.id;
  const { status, documentType, isLoading, isFetching, error, refetch } =
    useDocType(`${id}`);
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { facets, isLoading: facetsLoading } = useFacets();
  const { documentTypes, isLoading: documentTypesLoading } = useDocTypes();
  const { lifeCycles, isLoading: lifeCyclesLoading } = useLifeCycles(
    userPackage.company,
    userPackage.document
  );
  const { schemas, isLoading: schemasLoading } = useSchemas(
    userPackage.company,
    userPackage.document
  );

  if (
    isLoading ||
    isFetching ||
    facetsLoading ||
    documentTypesLoading ||
    lifeCyclesLoading ||
    schemasLoading ||
    documentType === undefined ||
    facets === undefined ||
    documentTypes === undefined ||
    lifeCycles === undefined ||
    schemas === undefined
  ) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const doctypeData: GetDocTypesProps = JSON.parse(`${documentType}`);
  const customSchemaForUpdate = JSON.parse(
    `${doctypeData.customSchemaJson}`
  ) || {
    featureId: doctypeData.featureId,
    prefix: doctypeData.featureId,
    companyName: userPackage.company,
    documentName: userPackage.document,
    fields: [],
  };

  const doctypeForUpdate: CreateUpdateDocTypesProps = {
    company: userPackage.company,
    document: userPackage.document,
    featureId: doctypeData.featureId,
    label: doctypeData.label,
    description: doctypeData.description,
    extendType: doctypeData.extendType,
    iconUrl: doctypeData.iconUrl,
    bigIconUrl: doctypeData.bigIconUrl,
    lifeCycle: doctypeData.lifeCycle,
    containerTypes: JSON.parse(`${doctypeData.containerTypesJson}`) || [],
    subTypes: JSON.parse(`${doctypeData.subTypesJson}`) || [],
    facets:
      JSON.parse(`${doctypeData.facetsJson}`).map((elem: any) => elem.name) ||
      [],
    deploymentMode: doctypeData.deploymentMode,
    schemas: JSON.parse(`${doctypeData.schemasJson}`) || [],
    customSchema: customSchemaForUpdate,
    fields: convertFieldToFieldType(customSchemaForUpdate.fields),
    inheritedSchemas: doctypeData.inheritedSchemas ? JSON.parse(`${doctypeData.inheritedSchemas}`).join(",") || "" : "",
    schemasBroughtByFacets: doctypeData.schemasBroughtByFacets
  }

  const facetsId: CommonEntityProps[] =
    JSON.parse(`${facets}`).map((facet: any) => ({
      featureId: facet.featureId,
      label: facet.label,
      description: facet.description,
    })) || [];
  const facetsOptions: TFacetOption[] =
    JSON.parse(`${doctypeData.facetsJson}`) || [];
  const facetsRequired: string[] = facetsOptions
    .filter((elem: TFacetOption) => elem.required)
    .map((elem: TFacetOption) => elem.name);
  const schemasId =
    JSON.parse(`${schemas}`).map((featureId: any) => featureId.featureId) || [];
  const documentTypesId = JSON.parse(`${documentTypes}`).map(
    (documentType: any) => documentType.featureId
  );
  const lifeCyclesList = JSON.parse(`${lifeCycles}`);

  return (
    <DocumentTypeView
      initialData={doctypeForUpdate}
      documentTypesList={documentTypesId}
      facetsList={facetsId}
      facetsRequired={facetsRequired}
      lifeCyclesList={lifeCyclesList}
      schemasList={schemasId}
      refetch={refetch}
    />
  );
}
