import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidationErrorProps } from '../../types/validation-errors/ValidationErrorProps';
import { PacageEntitiesGetProps } from '../../types/packages/PackageEntitiesProps';
import { getValidationErrors } from './utils';

const initialErrors: ValidationErrorProps[] = [];
const validationErrorsSlice = createSlice({
  name: 'validationErrors',
  initialState: {
    errors: initialErrors
  },
  reducers: {
    loadValidationErrors(state, action: PayloadAction<{packageEntities: PacageEntitiesGetProps}>){
      const {packageEntities} = action.payload;
      state.errors = getValidationErrors(packageEntities)
    },
    updateValidationErrorsForEntity(state, action: PayloadAction<{object: string, featureId: string, errors: ValidationErrorProps[]}>){
      const {object, featureId, errors} = action.payload;
      state.errors = state.errors.filter((elem: ValidationErrorProps) => (elem.object !== object || elem.featureId !== featureId)).concat(errors);
    }
    
  }
 
})

export const { loadValidationErrors, updateValidationErrorsForEntity } = validationErrorsSlice.actions;
export default validationErrorsSlice.reducer;

