export enum DefaultDocumentTypesForStructureTemplate {
  Root,
  Collections,
  Domain,
  Folder,
  Forum,
  MailFolder,
  OrderedFolder,
  PictureBook,
  Section,
  SectionRoot,
  TemplateRoot,
  Workspace,
  WorkspaceRoot
}

