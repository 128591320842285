import useAlert from "../context/alertContext/useAlert";
import { TFieldGet, TFieldUpdate } from "../types/content/schemas/Field";
import { SchemaProjectProps, SchemaProps } from "../types/content/schemas/SchemaProps";
import { SchemaWithFieldsJsonProps, SchemaWithFieldsPropsGet } from "../types/content/schemas/SchemaWithFieldsProps";
import { constraintsParamNames } from "../components/pages/content/schemas/SchemaPage/FieldAdvancedSettings/validationViews/constants";

export function IsUniqueFeatureId(featureId: String, featureArray: String[]): Boolean {
    const { showError } = useAlert();
    const isUnique = featureArray.indexOf(featureId) === -1;
    if(!isUnique){
        showError("FeatureId is not unique");
    };
    return isUnique;
}

export function getListFeaturesId(listFeaturesId: any[]): String[] {
    const featuresId: String[] = [];
    listFeaturesId.forEach((obj) =>{
        featuresId.push(obj.featureId);
    });
    return featuresId;
}

export function convertSchemaProjectToSchema(schema: SchemaProjectProps): SchemaProps{
    return {
        id: schema.id,
        featureId: schema.featureId,
        prefix: schema.prefix,
        companyName: schema.project.split("-")[0],
        documentName: schema.project.split("-")[1],
        imported: schema.imported
    }
}

export function convertSchemaFieldsJsonToSchemaFieldsType(schemaJSON: SchemaWithFieldsJsonProps): SchemaWithFieldsPropsGet {
    const schema: SchemaWithFieldsPropsGet = {
        id: schemaJSON.id,
        featureId: schemaJSON.featureId,
        prefix: schemaJSON.prefix,
        companyName: schemaJSON.project.split("-")[0],
        documentName: schemaJSON.project.split("-")[1],
        isImported: schemaJSON.isImported,
        fields: convertFieldJSONToFieldType(schemaJSON.fields)
    }
    return schema;
}

export function convertFieldJSONToFieldType(fieldsJSON: Array<TFieldGet>): Array<TFieldUpdate>{
    const fields: Array<TFieldUpdate> = fieldsJSON.map((item: TFieldGet) => {
        // массив constrants с сервера переменной длины (содержит только установленные параметры)
        // для работы нам удобно иметь зафиксированные индексы (согласно constraintsParamNames) 
        //переформируем
        const constrantsFromServer = JSON.parse(item.constraintsJson);
        //переведем массив параметров в массив ключей параметров
        //знаем, что в одном элементе массива - ровно 1 параметр => индекс ключа и есть индекс в массиве
        const constraintsKeys = constrantsFromServer.map((elem: any) => (Object.keys(elem)[0]));
        const constraints = constraintsParamNames.map((name) => {
            const index = constraintsKeys.findIndex((elem: string) => (elem === name));
            return index < 0 ? null : constrantsFromServer[index];
        })
        return {
            id: item.id,
            name: item.name,
            fieldType: item.fieldType,
            isMultiValue: item.isMultiValue,
            defaultValue: item.defaultValue,
            referenceConfiguration: JSON.parse(item.referenceConfigurationJson),
            constraints: constraints,
            subFields: item.subFields === null ? [] : convertFieldJSONToFieldType(item.subFields)
        }
    });
    return fields;
}

export function convertFieldToFieldType(fields: Array<TFieldUpdate>): Array<TFieldUpdate>{
    const convertFields: Array<TFieldUpdate> = fields.map((item: TFieldUpdate) => {
        // массив constrants с сервера переменной длины (содержит только установленные параметры)
        // для работы нам удобно иметь зафиксированные индексы (согласно constraintsParamNames) 
        //переформируем
        const constrantsFromServer = item.constraints;
        //переведем массив параметров в массив ключей параметров
        //знаем, что в одном элементе массива - ровно 1 параметр => индекс ключа и есть индекс в массиве
        const constraintsKeys = constrantsFromServer.map((elem: any) => (Object.keys(elem)[0]));
        const constraints = constraintsParamNames.map((name) => {
            const index = constraintsKeys.findIndex((elem: string) => (elem === name));
            return index < 0 ? null : constrantsFromServer[index];
        })
        return {
            name: item.name,
            fieldType: item.fieldType,
            isMultiValue: item.isMultiValue,
            defaultValue: item.defaultValue,
            referenceConfiguration: item.referenceConfiguration,
            constraints: constraints,
            subFields: item.subFields === null ? [] : convertFieldToFieldType(item.subFields)
        }
    });
    return convertFields;
}