export const DefaultFacetsForWorkflow: string[] = [
  'Any',
  'Audio',
  'Collection',
  'Commentable',
  'Folderish',
  'HasRelatedText',
  'HasStoryboard',
  'HasVideoPreview',
  'HiddenInCreation',
  'HiddenInFacetedSearch',
  'HiddenInNavigation',
  'Indexable',
  'MasterPublishSpace',
  'MultiviewPicture',
  'NXTag',
  'NotCollectionMember',
  'Orderable',
  'Picture',
  'PublishSpace',
  'Publishable',
  'RoutingTask',
  'SuperSpace',
  'Task',
  'Versionable',
  'Video',
];
