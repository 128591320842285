import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../../types/packages/PackageProps';

type Props = {
  featureId: string;
  onDelete?: () => void;
};

export function SchemasDeleteDialog({ featureId, onDelete }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const endpoint = `schemas?featureId=${featureId}&project=${userPackage.company}-${userPackage.document}`

  const { remove } = useRemove(endpoint);
  const handleDelete = async () => {
    try {
      await remove(endpoint);
      onDelete?.();
    } catch (error: any ) {
      console.log(error);
    }
  };

  return (
    <DeleteDialog title={`${t("button_delete")} ${featureId}`} remove={handleDelete}>
      <Box>{`${t('confirmDelete')} ${featureId}?`}</Box>
    </DeleteDialog>
  );
}
