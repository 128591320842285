import { TPackagesState } from "./types";

export const initialState: TPackagesState = {
  userPackage: {
    id: 0,
    company: "",
    document: "",
    description: "",
    project: ""
  }
};

