import { GetStructureTemplateProps } from "../types/content/structureTemplates/GetStructureTemplateProps";
import { StructureTemplateProps } from "../types/content/structureTemplates/StructureTemplateProps";

export function convertStructureTemplateString(data: string): StructureTemplateProps {
  const dataWithJson: GetStructureTemplateProps = JSON.parse(data);
  return convertStructureTemplateWithJson(dataWithJson);
}

export function convertStructureTemplateWithJson(dataWithJson: GetStructureTemplateProps): StructureTemplateProps {
  const project: string[] = dataWithJson.project.split("-");
  const structureTemplate = {
    company: project?.[0] || "",
    document: project?.[1] || "",
    id: dataWithJson.id,
    featureId: dataWithJson.featureId,
    type: dataWithJson.type,
    targetDocType: dataWithJson.targetDocType,
    name: dataWithJson.name,
    title: dataWithJson.title,
    description: dataWithJson.description,
    accessControlList: JSON.parse(
      dataWithJson.accessControlListJson?.toString()
    ),
    parentId: dataWithJson.parentId,
    childs: dataWithJson.childs?.map((child: GetStructureTemplateProps) => convertStructureTemplateWithJson(child)) || [],
    deploymentMode: dataWithJson.deploymentMode
  }
  return structureTemplate;
}
  