import { useSearchParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface IProps {
    schemaName: string,
    fieldName: string,
    subfieldName: string | null,
    fieldIndex: number
}
const NavigationSection = ({ schemaName, fieldName, subfieldName, fieldIndex}: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const handleSchemeButtonClick = (event: any) => {
        setSearchParams();
    }

    const handleFieldButtonClick = (event: any) => {
        setSearchParams({field: `${fieldIndex}`});
    }

    return (
        <Box sx={{display: "flex", mb: '20px'}}>
            <Button 
                sx={{
                    marginRight: '0.5em',
                    color: '#1976d2',
                    border: '1px solid rgba(25, 118, 210, 0.5)',
                    fontSize: '0.8rem',
                    textTransform: 'none'
                }}
                onClick = {handleSchemeButtonClick}
            >
                {schemaName}
            </Button>
            <ArrowRightAltIcon fontSize="large" />
            <Button 
                sx={{
                    marginRight: '0.5em',
                    color: '#1976d2',
                    border: '1px solid rgba(25, 118, 210, 0.5)',
                    fontSize: '0.8rem',
                    textTransform: 'none'
                }}
                onClick = {handleFieldButtonClick}
            >
                {fieldName}
            </Button>
            <ArrowRightAltIcon fontSize="large" />
            <div 
                style={{
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius:'5px',
                    color: 'white',
                    backgroundColor: 'rgb(25, 118, 210)',
                    marginLeft: '0.25em',
                    paddingInline: '1em'
                }}
            >
                {subfieldName}
            </div>
        </Box>
    )
}

export default NavigationSection;