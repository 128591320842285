//список параметров ограничений
export const  constraintsParamNames = ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint', 'numericRangeConstraint', 'dateRangeConstraint'];
//список параметров ограничений в зависимости от типа
export const  typesConstraints = [
    {
        type: 'STRING',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint']
    },
    {
        type: 'BOOLEAN',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint']
    },
    {
        type: 'INTEGER',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'numericRangeConstraint']
    },
    {
        type: 'FLOATING_POINT',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'numericRangeConstraint']
    },
    {
        type: 'DATE',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'dateRangeConstraint']
    },
    {
        type: 'BLOB',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint', 'numericRangeConstraint', 'dateRangeConstraint']
    },
    {
        type: 'COMPLEX',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint']
    },
    {
        type: 'DIRECTORY',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint']
    },
    {
        type: 'USER_GROUP',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint']
    },
    {
        type: 'DOCUMENT',
        constrants: ['mandatoryConstraint', 'listElementsMandatoryConstraint', 'textFormatConstraint', 'textLengthConstraint']
    }
]
