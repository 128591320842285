import { Box, Button, Checkbox, FormControlLabel, Select, Stack, Typography } from '@mui/material';
import { ControlledTextarea } from '../../../../common/textarea/ControlledTextarea';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { TransferList } from '../../../../UI/TransferList/TransferList';
import { useState } from 'react';
import { DefaultTypesForDocument } from '../../../../../enum/DefaultTypesForDocument';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { defaultLifeCycles } from '../../../../../store/content/life-cycles/constants';
import { useTranslation } from 'react-i18next';
import { CommonEntityProps } from '../../../../../types/common/CommonEntityProps';
import { DefaultFacetsForDocument } from '../../../../../enum/DefaultFacetsForDocument';
import { useWatch } from 'react-hook-form';
import IconSelector from './IconSelector/IconSelector';

interface IProps {
  documentTypesList: string[];
  facetsList: CommonEntityProps[];
  facetsRequired: string[];
  lifeCyclesList: any[];
  initialContainerTypes: string[];
  initialFacets: string[];
  control: any;
  register: any;
  errors: any;
  getValues: any;
  setValue: any;
}

export const DefinitionForm = ({
  documentTypesList,
  facetsList,
  facetsRequired,
  lifeCyclesList,
  initialContainerTypes,
  initialFacets,
  control,
  register,
  errors,
  getValues,
  setValue,
}: IProps) => {
  const { t } = useTranslation();
  const [iconFileName, setIconFileName] = useState<string | undefined>(undefined);
  const [bigIconFileName, setBigIconFileName] = useState<string | undefined>(undefined);

  const lifeCyclesFullList = lifeCyclesList
    .map((elem: any) => ({ featureId: elem.featureId as string, description: '' }))
    .concat(defaultLifeCycles);
  const containersFullList: string[] = documentTypesList.concat(
    Object.keys(DefaultTypesForDocument)
      .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
      .map((key: any) => key),
  );
  const loadedDocTypes = getValues('containerTypes') || [];
  const facetsFullList: CommonEntityProps[] = DefaultFacetsForDocument.concat(facetsList);
  const [selectedFacet, setSelectedFacet] = useState<CommonEntityProps | null>(null);
  const loadedFacets = getValues('facets');
  const [isSubtypesVisible, setIsSubtypesVisible] = useState<boolean>(
    getValues('facets').includes('Folderish'),
  ); //признак отображения ChildrenTypes TransferList
  const subtypesFullList: string[] = documentTypesList.sort().concat(
    Object.keys(DefaultTypesForDocument)
      .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
      .map((key: any) => key)
      .sort(),
  );
  const loadedSubtypes = getValues('subTypes');

  const fieldsWatches = useWatch({
    control,
    name: ['iconUrl', 'bigIconUrl'],
  });

  // EFFECTS

  // EVENTS
  const updateDocTypes = (newList: string[]) => {
    setValue('containerTypes', newList);
  };

  const updateSubtypes = (newList: string[]) => {
    setValue('subTypes', newList);
  };

  const updateFacets = (newList: string[]) => {
    setValue('facets', newList);
    //признак отображения ChildrenTypes TransferList
    setIsSubtypesVisible(newList.includes('Folderish'));
  };

  const handleChangeIconFile = async (newFileName: string | undefined) => {
    setIconFileName(newFileName);
    setValue('iconUrl', newFileName || null, { shouldDirty: true });
  };

  const handleChangeBigIconFile = async (newFileName: string | undefined) => {
    setBigIconFileName(newFileName);
    setValue('bigIconUrl', newFileName || null, { shouldDirty: true });
  };

  const handleSelectFacet = (value: string) => {
    setSelectedFacet(facetsFullList.find((elem) => elem.featureId === value) || null);
  };

  // RENDER
  const basicProperties: GridOneToThreeItemProps[] = [
    {
      title: 'Extends',
      required: true,
      content: (
        <Select {...register('extendType')} native id="grouped-native-select">
          <option aria-label="None" value="" />
          <optgroup label="Local types">
            {documentTypesList.sort().map((item: any) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="Default types">
            {Object.keys(DefaultTypesForDocument)
              .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
              .sort()
              .map((key: any) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
          </optgroup>
        </Select>
      ),
    },
    {
      title: 'Label',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('label', {
              required: {
                value: true,
                message: 'Label is required',
              },
            }),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: 300 }}
        />
      ),
    },
    {
      title: 'Description',
      content: (
        <ControlledTextarea
          register={{ ...register('description') }}
          style={{ width: 300, maxWidth: 300, border: '1px solid #f3f3f3', outline: 'none' }}
          maxRows={3}
          minRows={3}
        />
      ),
    },
    {
      title: 'Icon',
      content: (
        <IconSelector
          value={fieldsWatches[0]}
          onDelete={() => setValue('iconUrl', null, { shouldDirty: true })}
          onFileSelect={handleChangeIconFile}
        />
      ),
    },
    {
      title: 'Large Icon',
      content: (
        <IconSelector
          value={fieldsWatches[1]}
          onDelete={() => setValue('bigIconUrl', null, { shouldDirty: true })}
          onFileSelect={handleChangeBigIconFile}
        />
      ),
    },
    {
      title: 'Life Cycle',
      content: (
        <Box display="flex" alignItems="center" gap="10px">
          <Select
            {...register('lifeCycle')}
            native
            id="grouped-native-select"
            renderValue={(selected) => {
              // here you can add you logic for your selected value
              const res =
                lifeCyclesFullList?.find((x) => x.featureId === selected)?.featureId || '';
              //const res = lifeCyclesFullList?.find((x) => x.featureId === selected).label
              return res; // something similar to this
            }}
          >
            <option aria-label="None" value="" />
            {lifeCyclesFullList.map((item: any) => {
              const label = `${item.featureId} ${item.description ? `(${item.description})` : ''}`;
              return (
                <option key={item.featureId} value={item.featureId}>
                  {label}
                </option>
              );
            })}
          </Select>
          <Button variant="outlined" color="primary" disabled>
            {`${t('button_create')} ${t('lifeCycle').toUpperCase()}`}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <GridOneToThree items={basicProperties} />
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <SimpleAccordion title="Container Types" sx={{ mt: 3 }} defaultExpanded={true}>
          <TransferList
            entityList={containersFullList.map((elem) => ({ value: elem, label: elem }))}
            updateParent={updateDocTypes}
            loadEntities={loadedDocTypes.map((elem: string) => ({ value: elem, label: elem }))}
          />
        </SimpleAccordion>
      </div>
      {isSubtypesVisible && (
        <Box>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <SimpleAccordion title="Accepted Children Types" sx={{ mt: 3 }} defaultExpanded={true}>
              <TransferList
                entityList={subtypesFullList.map((elem: string) => ({ value: elem, label: elem }))}
                updateParent={updateSubtypes}
                loadEntities={loadedSubtypes.map((elem: string) => ({ value: elem, label: elem }))}
              />
            </SimpleAccordion>
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column', pl: '16px' }}>
            <FormControlLabel control={<Checkbox />} label="Show Create Child Action" />
            <FormControlLabel control={<Checkbox />} label="Show View Tab" />
          </Box>
        </Box>
      )}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <SimpleAccordion title="Document Facets" sx={{ mt: 3 }} defaultExpanded={true}>
          <TransferList
            entityList={facetsFullList.map((elem: CommonEntityProps) => ({
              value: elem.featureId,
              label: elem.label || elem.featureId,
            }))}
            updateParent={updateFacets}
            loadEntities={loadedFacets.map((elem: string) => {
              const facetsObj = facetsFullList.find(
                (obj: CommonEntityProps) => obj.featureId === elem,
              );
              if (facetsObj) {
                return {
                  value: facetsObj.featureId,
                  label: facetsObj.label,
                  disabled: facetsRequired.includes(facetsObj.featureId) ? true : false,
                };
              }
              return { value: elem, label: elem };
            })}
            onSelect={handleSelectFacet}
          />
        </SimpleAccordion>
      </div>
      {selectedFacet && (
        <Box>
          <Stack direction="row" gap="10px">
            <Typography fontWeight="bold">Id:</Typography>
            <Typography>{selectedFacet.featureId}</Typography>
          </Stack>
          <Stack direction="row" gap="10px">
            <Typography fontWeight="bold">Label:</Typography>
            <Typography>{selectedFacet.label}</Typography>
          </Stack>
          <Stack direction="row" gap="10px">
            <Typography fontWeight="bold">Description:</Typography>
            <Typography>{selectedFacet.description}</Typography>
          </Stack>
        </Box>
      )}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <SimpleAccordion title="Advanced Configiration" sx={{ mt: 3 }} defaultExpanded={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginRight: 10 }}>Deployment Mode</Typography>
            <Select {...register('deploymentMode')} native>
              <option key="MERGE" value="MERGE">
                Merge
              </option>
              <option key="OVERRIDE" value="OVERRIDE">
                Override
              </option>
            </Select>
          </div>
        </SimpleAccordion>
      </div>
    </>
  );
};
