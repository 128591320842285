import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { INavigationConfirm } from '../../context/navigationConfirmContext/types';
import { useNavigationConfirm } from '../../context/navigationConfirmContext/hooks';



export const useNavigationPrompt = ({
  isDirty = false,
  onConfirm,
  onCancel,
  onSave
  }: INavigationConfirm & { isDirty?: boolean }) => {
  
  //const blocker = useBlocker(isDirty);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      isDirty && currentLocation.pathname !== nextLocation.pathname
    )
  );

  const { show } = useNavigationConfirm();

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>((resolve) => {
      show({
        onConfirm: () => {
          resolve(true);
          onConfirm?.();
        },
        onCancel: () => {
          resolve(false);
          onCancel?.();
        },
        onSave: () => {
          resolve(true);
          onSave?.();
        }
      });
    });
  }, [
    isDirty,
    onCancel,
    onConfirm,
    onSave,
    show,
   
  ]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then((result) => {
        if (result) {
          blocker.proceed();
        } else {
          blocker.reset();
        }
      });
    }
  }, [blocker, confirm]);

  return {
    confirm,
  };
};