import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DocTypesProps } from '../../../../../types/content/documentTypes/DocTypesProps';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import RenameDialog from '../../../../common/dialog/RenameDialog';

type Props = {
  featureId: string;
  refetch: () => void;
};

export function UpdateDialogDocTypes({ refetch, featureId }: Props) {
  
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<Partial<DocTypesProps>>({
    defaultValues: {
      featureId: featureId,
    },
  });

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              required: {
                value: true,
                message: 'Feature ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              maxLength: {
                value: 40,
                message: 'Must be at more 40 symbols',
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];
  
  //#TODO ждем ендпойнт от бэка
  const onSubmit =(newPerms: Partial<DocTypesProps>) => {
    
  };

  /*const onSubmit = async (newPerms: DocTypesProps) => {
    await create(newPerms);
  };*/

  const validate = async () => {
    return await trigger();
  };

  return (
    <RenameDialog
      title="Update documet type ID"
      update={handleSubmit(onSubmit)}
      validate={validate}
      isUpdate={refetch}
    >
      <hr />
      <Box sx={{ height: 'auto', marginTop: 1 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </RenameDialog>
  );
}
