import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Editor } from '@monaco-editor/react';
import { Box, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { useTranslation } from 'react-i18next';

const CodeEditor = forwardRef((props, ref) => {
  const editorRef = useRef<any>(null);
  const { watch, setValue } = useFormContext();
  const formValues = watch();
  const { t } = useTranslation();

  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  };

  useImperativeHandle(ref, () => ({
    getData: () => ({
      script: editorRef.current?.getValue() || '',
    }),
  }));

  const handleEditorChange = (value: string | undefined) => {
    setValue('script', value || '');
  };

  const insertText = () => {
    const textFunc = document.querySelector('#func_text')?.textContent;
    if (textFunc) {
      editorRef.current.setValue(textFunc);
      setValue('script', textFunc);
    }
  };

  return (
    <>
      <SimpleAccordion title={`${t('exampleAndShortcuts')}`} defaultExpanded={false}>
        <pre>
          <Box
            id="func_text"
            sx={{
              width: '500px',
              padding: '10px',
              border: '1px solid #f1f1f1',
              bgcolor: 'lightgray',
              color: 'gray',
            }}
          >
            {`function run(input, params) {
              var root = Repository.GetDocument(
                  null, {
                      "value": "/"
                  }
              );
              var newDoc = Document.Create(
                  root, {
                      "type": "File",
                      "name": "newDoc",
                      "properties": {
                          "dc:title": "New Title",
                          "dc:source": "JavaScript",
                          "dc:subjects": ["from", "javascript"]
                      }
                  }
              );
              newDoc = Document.Update(
                  newDoc, {
                  "properties": {
                      "dc:nature": "nature",
                      "dc:creator": ctx.currentUser.name
                      }
                  }
              );
              return newDoc;
          }`}
          </Box>
          <Button onClick={insertText} variant="outlined">
            Insert
          </Button>
        </pre>
      </SimpleAccordion>
      <form>
        <Box style={{ height: '680px', border: '1px solid #ddd' }}>
          <Editor
            height="100%"
            defaultLanguage="javascript"
            defaultValue={formValues.script}
            theme="vs-dark"
            onMount={handleEditorDidMount}
            onChange={handleEditorChange}
            options={{
              fontSize: 14,
              minimap: { enabled: true },
              automaticLayout: true,
            }}
          />
        </Box>
      </form>
    </>
  );
});

export default CodeEditor;
