import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { SimpleAccordion } from '../../../../../../../common/accordion/SimpleAccordion';

type GeneralTabProps = {
  data: any;
  onChange: (key: string, value: any) => void; // Добавлен обработчик изменений
};

const GeneralTab: React.FC<GeneralTabProps> = ({ data, onChange }) => {
  const { label } = data.data;

  const [assignees, setAssignees] = useState<string[]>(data.data.assignees || []); // Инициализация из данных

  useEffect(() => {
    onChange('assignees', assignees);
  }, [assignees, onChange]);

  const handleAddAssignee = () => {
    setAssignees([...assignees, '']);
  };

  const handleDeleteAssignee = (index: number) => {
    setAssignees(assignees.filter((_, i) => i !== index));
  };

  const handleChangeAssignee = (index: number, value: string) => {
    const updatedAssignees = [...assignees];
    updatedAssignees[index] = value;
    setAssignees(updatedAssignees);
  };

  const handleFieldChange = (key: string, value: any) => {
    onChange(key, value); // Передаём изменения в родительский компонент
  };

  return (
    <Box>
      <TextField
        label="Title"
        fullWidth
        variant="outlined"
        margin="normal"
        defaultValue={data.data.label}
        onChange={(e) => handleFieldChange('label', e.target.value)}
      />
      <TextField
        label="Node Id"
        fullWidth
        variant="outlined"
        margin="normal"
        onChange={(e) => handleFieldChange('nodeId', e.target.value)}
      />
      <Box
        sx={{
          display:
            label === 'Node' ||
            label === 'Fork 3 Ways' ||
            label === 'Fork 2 Ways' ||
            label === 'Merge' ||
            label === 'Start' ||
            label === 'Stop' ||
            label === 'Sub Workflow'
              ? 'none'
              : 'block',
        }}
      >
        <TextField
          label="Directive"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          defaultValue={data.data.directive}
          rows={3}
          onChange={(e) => handleFieldChange('directive', e.target.value)}
        />
        <TextField
          label="Due date expression"
          fullWidth
          variant="outlined"
          margin="normal"
          defaultValue={data.data.dueDateExpression}
          onChange={(e) => handleFieldChange('dueDateExpression', e.target.value)}
        />
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          {assignees.map((assignee, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <TextField
                fullWidth
                value={assignee}
                onChange={(e) => handleChangeAssignee(index, e.target.value)}
                placeholder="Enter assignee"
              />
              <IconButton color="error" onClick={() => handleDeleteAssignee(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <IconButton color="primary" onClick={handleAddAssignee}>
            <AddIcon />
          </IconButton>
          <span>Add Assignee</span>
        </Box>

        <TextField
          label="Assignees expression"
          fullWidth
          variant="outlined"
          margin="normal"
          defaultValue={data.data.assigneesExpression}
          onChange={(e) => handleFieldChange('assigneesExpression', e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={data.data.allowTaskReassignment}
              onChange={(e) => handleFieldChange('allowTaskReassignment', e.target.checked)}
            />
          }
          label="Allow task reassignment"
          style={{ marginBottom: '10px' }}
        />
        <Select
          fullWidth
          variant="outlined"
          displayEmpty
          defaultValue={data.data.grantPermission}
          onChange={(e) => handleFieldChange('grantPermission', e.target.value)}
        >
          <MenuItem value="" disabled></MenuItem>
          <MenuItem value="Static list and permissions">Static list and permissions</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default GeneralTab;
