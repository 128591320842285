import { Paper, Stack } from "@mui/material";
import { Page } from "../../../common/page/Page";
import { PageContent } from "../../../common/page/PageContent";
import { PageHeader } from "../../../common/page/PageHeader";
import { FacetsCreateDialog } from "./create/FacetsCreateDialog";
import { FacetsPageList } from "./FacetsPageList";
import { useTranslation } from "react-i18next";

export function FacetsPage() {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title={t("facet")} />
      <PageContent subtitle={t("facetsSubtitle")}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <FacetsCreateDialog />
            <FacetsPageList />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
