import { GetDocTypesProps } from "../../types/content/documentTypes/GetDocTypesProps";
import { PacageEntitiesGetProps } from "../../types/packages/PackageEntitiesProps";
import { ValidationErrorProps } from "../../types/validation-errors/ValidationErrorProps";

export const getValidationErrors = (packageEntities: PacageEntitiesGetProps) => {
    const errors = getDoctypesValidationErrors(packageEntities.doctypes);
    return errors;
}

export const getDoctypeValidationErrors = (doctype: GetDocTypesProps) => {
    const errors: ValidationErrorProps[] = [];
    //cycle Extend 
    if (doctype.featureId === doctype.extendType) {
        errors.push({chapter: 'content', object: 'document-types', featureId: doctype.featureId, errorId: 'cycleExtend'});
    }
    return errors;
}

export const getDoctypesValidationErrors = (doctypes: GetDocTypesProps[]) => {
    const initialErrors: ValidationErrorProps[] = [];
    const errors = doctypes.reduce( ( acc, doctype ) => {
        return acc.concat(getDoctypeValidationErrors(doctype));
    } , initialErrors)
    return errors;
}