import { Typography } from '@mui/material';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { DeleteIconButton } from '../../../../../common/button/iconButton/DeleteIconButton';
import { UploadDialog } from '../../../../../common/dialog/UploadDialog';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value: string | null | undefined;
  onFileSelect: (newFileName: string | undefined) => void; // Изменено имя метода
  onDelete: () => void;
}

const IconSelector = ({ value, onFileSelect, onDelete, ...props }: IProps) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: '10px' }}
      {...props}
    >
      {value && (
        <Typography>
          <TextSnippetOutlinedIcon style={{ marginRight: 5 }} />
          {value}
        </Typography>
      )}
      {value && <DeleteIconButton onClick={onDelete} size="small" />}
      <UploadDialog title="Select Resource" handleChangeFile={onFileSelect} />
    </div>
  );
};

export default IconSelector;
