import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DeleteButton } from "../../../../../../../../common/button/DeleteButton";
import { NoEntityWarningDialog } from "../NoEntityWarning/NoEntityWarningDialog";

interface IProps {
  selectedAclIndex?: number;
  onDelete: () => void;
}
export function DeleteAclDialog({ selectedAclIndex, onDelete }: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    if (selectedAclIndex === undefined) {
      setIsOpen(true);
    } else {
      onDelete();
    }
  };

  // RENDER

  return (
    <div>
      <DeleteButton onClick={handleDelete} />
      {selectedAclIndex === undefined && (
        <NoEntityWarningDialog
          title={`${t("edit")} ${t("accessControlList")}`}
          label={`${t("noAclEntrySelected")}!`}
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}
