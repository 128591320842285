import { Box, Stack, TextField, Typography } from "@mui/material";
import { LabelDirectoryField } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DirectoryNameDialog from "../../FieldAdvancedSettings/directoryAttrs/DirectoryNameDialog";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    subfieldIndex: number,
    register: any,
    control: any,
    setValue: any,
    getValues: any,
    errors: any,
}

const DirectoryAttrs = ({ schemaName, fieldName, fieldNamePrefix, fieldIndex, subfieldIndex, register, control, errors, setValue, getValues}: IProps) => {
    const { t } = useTranslation();
        
    return(
        <Box>
            <Stack display='flex' flexDirection='row' gap='1em' marginTop='1em'>
                <Controller
                    name={`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => <TextField
                        label={t('directoryFieldName')}
                        value={value === null ? '' : value}
                        onChange={onChange}
                        {...register(`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`,{
                            minLength: {
                                value: 3,
                                message: t('minValidation').replace('%1', '3')
                            },
                            pattern: {
                                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                message: t('patternValidation')
                            },
                            required: {
                                value: true,
                                message: t("inputRequired").replace("%1", t("directoryFieldName"))
                            }
                        })}
                        size="small"
                        helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.referenceConfiguration.firstParameterValue.message : undefined}
                        error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.referenceConfiguration.firstParameterValue !== undefined : undefined}
                        />
                    }
                />
                <Typography  marginTop='10px'>
                    {t('or')}
                </Typography>
                
                <DirectoryNameDialog 
                    initialValue={getValues(`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`)} 
                    buttonTitle={t('dirSelectVocabulary')} 
                    onConfirm={(value: string) => setValue(`${fieldNamePrefix}.referenceConfiguration.firstParameterValue`, value, {
                        shouldDirty: true,
                      }) }                
                />
            </Stack>
            <LabelDirectoryField schema={schemaName} field={fieldName} />
        </Box>
    );
}

export default DirectoryAttrs;