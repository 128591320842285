import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import { PackageProps } from '../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { CONTENT_ENDPOINT } from '../../../serverInteraction/services/endpointsConstants';
import { Loader } from '../loader/Loader';
import axios from 'axios';
import ErrorMessage from '../../pages/content/processDefinition/CustomErrorMessage';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  title: string;
  handleChangeFile: (newFileName: string | undefined) => void;
};

export function UploadDialog({ title, handleChangeFile }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const allowedFileTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/gif',
    'application/xml',
  ];

  const handleClickOpen = () => {
    setOpen(true);
    resetDialog();
  };

  const resetDialog = () => {
    setError(null);
    setFile(null);
    setSelectedImage(null);
  };

  const handleCommit = async () => {
    if (file) {
      console.log('Uploading file...');
      const formData = new FormData();
      formData.append('file', file);

      try {
        setIsProcessing(true);
        const contentEndpoint = `${process.env.REACT_APP_API_URL}${CONTENT_ENDPOINT}?company=${userPackage.company}&document=${userPackage.document}`;
        const response = await axios.post(contentEndpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setUploadedFiles((prev) => [...prev, response.data]);
        handleChangeFile(response.data);
        resetDialog();
        setOpen(false);
      } catch (error) {
        console.error(error);
        if (axios.isAxiosError(error)) {
          setError(error.message);
        } else {
          setError('Loading error');
        }
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetDialog();
    setUploadedFiles([]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (allowedFileTypes.includes(selectedFile.type) || selectedFile.name.endsWith('.xsd')) {
        setFile(selectedFile);
        setError(null);
      } else {
        setError(
          'Недопустимый формат файла. Пожалуйста, выберите файл с расширением jpeg, jpg, png, svg, tiff, gif или xsd.',
        );
        setFile(null);
      }
    }
  };

  const handleDeleteFile = (fileName: string) => {
    setUploadedFiles((prev) => prev.filter((name) => name !== fileName));
    if (selectedImage === fileName) {
      setSelectedImage(null);
    }
  };

  const handleImageClick = (fileName: string) => {
    setSelectedImage(fileName);
  };

  if (isProcessing) return <Loader />;

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Select Resource
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm" // Установите максимальную ширину для диалога
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          {error && <ErrorMessage message={error} />}
          <Box mt={2}>
            <Input id="upload_field" type="file" onChange={handleChange} fullWidth />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Uploaded Files:</Typography>
          <List>
            {uploadedFiles.map((fileName) => (
              <ListItem key={fileName} onClick={() => handleImageClick(fileName)} dense>
                <ListItemText primary={fileName} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDeleteFile(fileName)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {selectedImage && (
            <Box mt={2} display="flex" alignItems="center">
              <Typography variant="h6">Selected Image:</Typography>
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: '40px', height: '40px', objectFit: 'cover', marginLeft: '10px' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCommit} color="primary" disabled={!file}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
