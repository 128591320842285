import { useDispatch, useSelector } from 'react-redux';
import { ValidationErrorProps } from '../../../types/validation-errors/ValidationErrorProps';
import { updateValidationErrorsForEntity } from '../../../store/validationErrors/validationErrorsSlice';

const useValidationErrors = (object: string, featureId?: string) => {
    const dispatch = useDispatch();
    const validationErrors = useSelector((state: any) => state.validationErrors.errors);
    
    const data = typeof featureId === 'string' ? 
      validationErrors.filter((elem: ValidationErrorProps) => (elem.object === object && elem.featureId === featureId)) : 
      validationErrors.filter((elem: ValidationErrorProps) => (elem.object === object));
  
    const update = async (updateProps: ValidationErrorProps[]) => {
        if (typeof featureId === 'string')
          dispatch(updateValidationErrorsForEntity({object: object, featureId: featureId, errors: updateProps}));
    };

    return { data, update }
};

export default useValidationErrors;

