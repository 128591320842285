import { Box, Checkbox, FormControl, FormControlLabel, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { LabelDateRange, DateRangeCheckbox } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { constraintsParamNames } from "../../FieldAdvancedSettings/validationViews/constants";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    subfieldIndex: number,
    control: any,
    register: any,
    errors: any,
    getValues: any,
    setValue: any
}


export default function DateRangeView({schemaName, fieldName, fieldNamePrefix, fieldIndex, subfieldIndex, control, register, errors, getValues, setValue}: IProps){
    const { t } = useTranslation();
   
    const paramName = "dateRangeConstraint";
    const index = constraintsParamNames.findIndex((element: string) => element === paramName); 
    const dateRangeConstraint: Array<any | number> = getValues([`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`, `${fieldNamePrefix}.constraints.${index}.${paramName}.maximum`]);
    const [isWatchDateRange, setWatchDateRange] =  useState(dateRangeConstraint[0] || dateRangeConstraint[1]);
    const fieldsWatches = useWatch({
        control,
        name: [`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`, `${fieldNamePrefix}.constraints.${index}.${paramName}.maximum`]
    });
    
    return(
        <Fragment>
          <Stack direction="row" alignItems="center" sx={{marginTop: "1em", marginLeft: "1em"}}>
                <Checkbox onChange={() => setWatchDateRange(!isWatchDateRange)} checked={isWatchDateRange} />
                <FormControl sx={{cursor: "default", width: "100%"}}>
                    <DateRangeCheckbox />   
                </FormControl>
            </Stack>
            {isWatchDateRange &&
            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <Box style={{
                    marginLeft: "3em",
                    marginTop: "1em"
                }}>
                    <Box style={{ display: "flex", gap: "20px", alignItems: "center", marginBottom: "1em"}}>
                        <Controller
                        name={`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`}
                        control={control}
                        render={({ field }) => (<TextField {...field}
                                variant="outlined" 
                                size="small"
                                type="number"
                                label={t("validMinimum")}
                                
                                {...register(`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`, {
                                    min: {
                                        value: 0,
                                        message: t("minValueValidation").replace("%1", "0")
                                    },
                                    max: {
                                        value: 999999999,
                                        message: t("maxValueValidation").replace("%1", "999999999")
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message: t("onlyIntegerNumber")
                                    }
                                })}
                                helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.dateRangeConstraint?.minimum?.message : undefined}
                                error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.dateRangeConstraint?.minimum !== undefined : undefined}
                                />
                            )}
                        />
                        <Controller
                            name={`${fieldNamePrefix}.constraints.${index}.${paramName}.minimumInclusive`}
                            control={control}
                            render={({ field: { onChange, value } }) => <FormControlLabel control={<Checkbox disabled={fieldsWatches[0] === ""} onChange={onChange} checked={value} />} label={t("includes")}/>}
                        />
                    </Box>
                    <Box style={{ display: "flex", gap: "20px", alignItems: "center"}}>
                        <Controller
                            name={`${fieldNamePrefix}.constraints.${index}.${paramName}.maximum`}
                            control={control}
                            render={({ field }) => (<TextField {...field}
                                {...register(`${fieldNamePrefix}.constraints.${index}.${paramName}.maximum`, {
                                    min: {
                                        value: 0,
                                        message: t("minValidation").replace("%1", "0")
                                    },
                                    max: {
                                        value: 999999999,
                                        message: t("maxValidation").replace("%1", "999999999")
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message: t("onlyIntegerNumber")
                                    }
                                })}
                                variant="outlined" 
                                size="small"
                                type="number"
                                label={t("validMaximum")}
                                helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.dateRangeConstraint?.maximum?.message : undefined}
                                error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.dateRangeConstraint?.maximum !== undefined : undefined}
                                />
                            )}
                        />
                        <Controller
                            name={`${fieldNamePrefix}.constraints.${index}.${paramName}.maximumInclusive`}
                            control={control}
                            render={({ field: { onChange, value } }) => <FormControlLabel control={<Checkbox disabled={fieldsWatches[1] === ""} onChange={onChange} checked={value} />} label={t("includes")}/>}
                        />
                    </Box>
                </Box>
                <LabelDateRange schema={schemaName} field={fieldName} />
            </div>
            }
        </Fragment>
    )
};
