import React from 'react';
import { FaPlay, FaStop } from 'react-icons/fa';
import {
  AiOutlineFork,
  AiOutlineMergeCells,
  AiOutlineCheck,
  AiOutlineSisternode,
} from 'react-icons/ai';
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import styles from './SidebarPanel.module.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type SidebarPanelProps = {
  onAddNode: (type: string) => void;
  onIncreaseForkOutputs?: (nodeId: string) => void;
};

const SidebarPanel: React.FC<SidebarPanelProps> = ({ onAddNode }) => {
  return (
    <section className={styles.sidebar}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Automated tasks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <div className={styles.item} onClick={() => onAddNode('node')}>
              <div className={styles.icon_wrapper}>
                <AiOutlineSisternode color="blue" />
              </div>
              Node
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>User tasks</Typography>
        </AccordionSummary>
        <Box sx={{ padding: '10px' }}>
          <div className={styles.item} onClick={() => onAddNode('acceptReject')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineCheck color="green" />
            </div>
            Accept/Reject
          </div>
          <div className={styles.item} onClick={() => onAddNode('approve')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineCheck color="green" />
            </div>
            Approve
          </div>
          <div className={styles.item} onClick={() => onAddNode('multipletasks')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineCheck color="green" />
            </div>
            Multiple Tasks
          </div>
          <div className={styles.item} onClick={() => onAddNode('simpletask')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineCheck color="green" />
            </div>
            Simple task
          </div>
        </Box>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Structural node</Typography>
        </AccordionSummary>
        <Box sx={{ padding: '10px' }}>
          <div className={styles.item} onClick={() => onAddNode('fork2ways')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineFork color="red" />
            </div>
            Fork 2 ways
          </div>
          <div className={styles.item} onClick={() => onAddNode('fork3ways')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineFork color="red" />
            </div>
            Fork 3 ways
          </div>
          <div className={styles.item} onClick={() => onAddNode('merge')}>
            <div className={styles.icon_wrapper}>
              <AiOutlineMergeCells color="red" />
            </div>
            Merge
          </div>
          <div className={styles.item} onClick={() => onAddNode('start')}>
            <div className={styles.icon_wrapper}>
              <FaPlay color="green" />
            </div>
            Start
          </div>
          <div className={styles.item} onClick={() => onAddNode('stop')}>
            <div className={styles.icon_wrapper}>
              <FaStop color="red" />
            </div>
            Stop
          </div>
          <div className={styles.item} onClick={() => onAddNode('subworkflow')}>
            <div className={styles.icon_wrapper}>
              <MdOutlineSubdirectoryArrowRight color="blue" />
            </div>
            Sub Workflow
          </div>
        </Box>
      </Accordion>
    </section>
  );
};

export default SidebarPanel;
