import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { theme } from "../../../../../../styling/Theme";
import { DeleteIconButton } from "../../../../../common/button/iconButton/DeleteIconButton";
import FieldTypeSelect from "../FieldAdvancedSettingsV1/FieldTypeSelect/FieldTypeSelect";
import DefaultValueInput from "../FieldAdvancedSettingsV1/DefaultValueInput/DefaultValueInput";
import FieldComment from "../FieldComment/FieldComment";


interface IProps {
  fieldName: string,
  fieldIndex: number,
  control: any,
  register: any,
  errors: any,
  getValues: any,
  setValue: any,
  watch: any,
  onNavigate: (index: number) => void,
}

const SchemaSubFieldsTableV1 = ({ fieldName, fieldIndex, control, register, errors, getValues, setValue, watch, onNavigate}: IProps) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
   
    const fieldSearchParam = searchParams.get("field") || null;

    const namePrefix = `fields.${fieldIndex}.subFields`; 
    if (fieldSearchParam!==null){
       const d = errors.fields;
       if (d) {
        const c = errors.fields[Number(fieldSearchParam)];
        if (c) {
          const s = c.subFields;
        }
      }
    }
    //const errorsObj = fieldSearchParam===null ? errors.fields : (errors.fields ? errors.fields[Number(fieldSearchParam)]?.subFields : undefined);
    const addButtonTitle = t("subfieldAdd");
    const { fields,  append, remove, replace } = useFieldArray({control, name: namePrefix});
    
    const handleItemAdd = () => {
      append({
          name: "",
          fieldType: "STRING",
          isMultiValue: false,
          defaultValue: "",
          constraints: [],
          referenceConfiguration: null,
          subFields: null
      });
    };
  
    return (
      <>
        <Box
          sx={{
            width: "100%",
            paddingBottom: "1em",
          }}
        >
          <Button
            disabled = {getValues("isImported")}
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            startIcon={<AddIcon />}
            onClick={handleItemAdd}
          >
            {addButtonTitle}
          </Button>
        </Box>
        <Box
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            border: "1px solid grey",
            borderRadius: "5px",
          }}
        >
          <Box>
            <Stack
              direction="row"
              sx={{
                fontWeight: 600,
                height: "40px",
              }}
            >
              <div
                style={{
                  width: "40px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: theme.palette.grey[200],
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "1em",
                  borderLeft: "1px solid grey",
                }}
              >
                {t("fieldsConfig")}
            </div>
            </Stack>
          </Box>
          <form>
            {fields.map((item: any, index: number) => {
              
              return (
              <Box key={index}>
                <Stack direction="row" height="auto" width="100%">
                  <div
                    style={{
                      width: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTop: "1px solid grey",
                    }}
                  >
                    <DeleteIconButton disabled = {getValues("isImported")} onClick={() => remove(index)} size="small" />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderLeft: "1px solid grey",
                      borderTop: "1px solid grey",
                      paddingLeft: "1em",
                    }}
                  >
                    <Stack
                      direction="row"
                      py={2}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        paddingBottom: "4px"
                      }}
                    >
                      <div>
                        <Controller
                          name={`${namePrefix}.${index}.name`}
                          control={control}
                          render={({ field }) => {
                            return (
                            <TextField
                              {...field}
                              disabled = {getValues("isImported")}
                              label={t("fieldName")}
                              variant="outlined"
                              size="small"
                              sx={{
                                paddingRight: "1em",
                              }}
                              {...register(`${namePrefix}.${index}.name`, {
                                required: {
                                  value: true,
                                  message: t("inputRequired").replace("%1", t("fieldName")),
                                },
                                minLength: {
                                  value: 2,
                                  message: t("minValidation").replace("%1", "2"),
                                },
                                maxLength: {
                                  value: 256,
                                  message: t("maxValidation").replace("%1", "256"),
                                },
                                pattern: {
                                  value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                  message: t("patternValidation"),
                                },
                              })}
                              helperText={errors.fields && errors.fields[fieldIndex] && errors.fields[fieldIndex].subFields && errors.fields[fieldIndex].subFields[index] ? errors.fields[fieldIndex]?.subFields[index]?.name?.message : undefined}
                              error={errors.fields && errors.fields[fieldIndex] && errors.fields[fieldIndex].subFields && errors.fields[fieldIndex].subFields[index] ? errors.fields[fieldIndex]?.subFields[index]?.name !== undefined : undefined}                              
                            />
                          )}}
                        />
                        <FieldTypeSelect  
                          variant="SUBFIELD"
                          name={`${namePrefix}.${index}.fieldType`}
                          fieldNamePrefix={`${namePrefix}.${index}`}
                          isImported={getValues("isImported")}   
                          control={control}
                          register={register}
                          setValue={setValue}
                          helperText={errors.fields && errors.fields[fieldIndex] && errors.fields[fieldIndex].subFields && errors.fields[fieldIndex].subFields[index] ? errors.fields[fieldIndex]?.subFields[index]?.fieldType?.message : undefined}
                          error={errors.fields && errors.fields[fieldIndex] && errors.fields[fieldIndex].subFields && errors.fields[fieldIndex].subFields[index] ? errors.fields[fieldIndex]?.subFields[index]?.fieldType !== undefined : undefined}
                          defaultInputName={`${namePrefix}.${index}.defaultValue`}
                          referenceConfigurationInputName={`${namePrefix}.${index}.referenceConfiguration`}                      
                        />
                        
                        <Controller
                          name={`${namePrefix}.${index}.isMultiValue`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              disabled = {getValues("isImported")}
                              control={<Checkbox onChange={onChange} checked={value} />}
                              label={t("fieldMulti")}
                              sx={{ paddingLeft: "1em" }}
                            />
                          )}
                        />
                        <DefaultValueInput 
                            name={`${namePrefix}.${index}.defaultValue`} 
                            control={control} 
                            register={register} 
                            getValues={getValues} 
                            fieldTypeInputName={`${namePrefix}.${index}.fieldType`}                
                        />
                      </div>
                      <Stack spacing={2} direction="row" style={{
                        marginRight: "1%"
                      }}>
                        <Tooltip title={t("fieldAdvSett")}>
                          <IconButton disabled = {getValues("isImported")} onClick={()=> onNavigate(index)}>
                            <SettingsIcon/>
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <FieldComment field={`${namePrefix}.${index}`} control={control} />
                  </div>
                </Stack>
              </Box>
            )})}
          </form>
        </Box>
        
      </>
    );
  }

  export default SchemaSubFieldsTableV1;