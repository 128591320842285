import { Link, Stack, Typography, Box } from "@mui/material";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../../../styling/Theme";
import useLifeCycles from "../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles";
import { LifeCycleProps } from "../../../../types/content/life-cycles/LifeCycleProps";
import { LifeCyclesDeleteDialog } from "./delete/LifeCyclesDeleteDialog";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../types/packages/PackageProps";
import SchemasRenameDialog from "../schemas/rename/SchemasRenameDialog";
import { LifeCyclesRenameDialog } from "./rename/LifeCyclesRenameDialog";

export function LifeCyclesPageList() {
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { status, lifeCycles, isLoading, isFetching, error, refetch } =
    useLifeCycles(userPackage.company, userPackage.document);

  if (isLoading || isFetching || lifeCycles === undefined) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {JSON.parse(`${lifeCycles}`).map((lifeCycle: LifeCycleProps) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={lifeCycle.featureId}
          >
            <Link
              underline="hover"
              component={RouterLink}
              to={`${lifeCycle.featureId}`}
            >
              {lifeCycle.featureId}
            </Link>
            <Stack
              justifyContent="space-between"
              alignItems="right"
              spacing={2}
            >
              <Box sx={{ display: "flex" }}>
                <LifeCyclesRenameDialog
                  featureId={lifeCycle.featureId}
                  refetch={() => refetch()}
                />
                <LifeCyclesDeleteDialog
                  lifeCycle={lifeCycle}
                  onDelete={() => refetch()}
                />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
