import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
} & IconButtonProps;;

export function DeleteIconButton({title, ...props }: Props) {
  const {t} = useTranslation();
  return (
    <Tooltip title={title || t("button_delete")}>
      <IconButton aria-label='delete' {...props}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
