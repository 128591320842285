import useGetAll from '../../entity/useGetAll';
import { AUTOMATION_CHAINS } from '../../../services/endpointsConstants';
import { AutomationChainsProps } from '../../../../types/automation/automationChains/AutomationChainsProps';

const useAutomationChains = (company: string, document: string, featureId?: string) => {
  let entityName = '';

  if (featureId) {
    entityName = `${AUTOMATION_CHAINS}?company=${company}&document=${document}&featureId=${featureId}`;
  } else {
    entityName = `${AUTOMATION_CHAINS}?company=${company}&document=${document}`;
  }

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<AutomationChainsProps>(entityName);

  return {
    status,
    automationChains: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useAutomationChains;
