import { Box, Button } from "@mui/material";
import { Edge, Node } from "react-flow-renderer";

interface ControlPanelProps {
  nodes: Node[];
  edges: Edge[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  boxNameMap: { [key: string]: string };
  setBoxNameMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  initialData: any;
  handleSave: () => void;
}

const ControlPanel = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  boxNameMap,
  setBoxNameMap,
  initialData,
  handleSave,
}: ControlPanelProps) => {
  const handleAddStateBox = (state: number) => {
    const newNodeId = `Box ${nodes.length + 1}`;
    const newNode: Node = {
      id: newNodeId,
      data: {
        label: `${newNodeId}`,
        description: "New Box",
        boxType: state ? "INITIAL_STATE" : "STATE",
      },
      position: { x: 400, y: 300 },
      type: "default",
      draggable: true,
    };

    setNodes((nds) => [...nds, newNode]);

    setBoxNameMap((prev) => ({
      ...prev,
      [newNodeId]: newNode.data.label,
    }));
  };

  const handleDeleteSelectedEdges = () => {
    setEdges((eds) => eds.filter((edge) => !edge.selected));
  };

  const handleDeleteSelectedNodes = () => {
    const selectedNodeIds = nodes
      .filter((node) => node.selected)
      .map((node) => node.id);
    setNodes((nds) => nds.filter((node) => !node.selected));

    setEdges((eds) =>
      eds.filter(
        (edge) =>
          !selectedNodeIds.includes(edge.source) &&
          !selectedNodeIds.includes(edge.target)
      )
    );

    setBoxNameMap((prev) =>
      Object.fromEntries(
        Object.entries(prev).filter(([key]) => !selectedNodeIds.includes(key))
      )
    );
  };

  const handleDeleteAll = () => {
    setNodes([]);
    setEdges([]);
  };

  const handleReset = () => {
    if (initialData) {
      setNodes(
        initialData.boxes.map((box: any) => ({
          id: box.name,
          data: {
            label: box.name,
            description: box.description,
            boxType: box.boxType,
          },
          position: { x: box.pointX, y: box.pointY },
          type: "default",
          draggable: true,
        }))
      );

      const boxIdToNameMap = Object.fromEntries(
        initialData.boxes.map((box: any) => [box.id, box.name])
      );

      setBoxNameMap(boxIdToNameMap);

      const updatedEdges = initialData.connections.map((conn: any) => ({
        id: conn.id || `${conn.stateFrom}-${conn.stateTo}`,
        source: boxIdToNameMap[conn.stateFrom] || conn.stateFrom,
        target: boxIdToNameMap[conn.stateTo] || conn.stateTo,
        label: conn.name,
        type: "step",
      }));

      setEdges(updatedEdges);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
      <Button variant="contained" onClick={() => handleAddStateBox(1)}>
        Add "INITIAL STATE" Box
      </Button>
      <Button variant="contained" onClick={() => handleAddStateBox(0)}>
        Add "STATE" Box
      </Button>
      <Button variant="contained" onClick={handleDeleteSelectedEdges}>
        Remove selected connection
      </Button>
      <Button variant="contained" onClick={handleDeleteSelectedNodes}>
        Remove selected box
      </Button>
      <Button variant="contained" onClick={handleDeleteAll}>
        Remove all
      </Button>
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
      <Button variant="outlined" onClick={handleReset}>
        Undo changes
      </Button>
    </Box>
  );
};

export default ControlPanel;
