import { Box } from "@mui/material"
import ErrorLabel from "../common/errorLabel/ErrorLabel"

interface IProps {
    label: string,
    countErrors: number
}
const SidebarItemTitle = ({label, countErrors}: IProps) => {
    return (
        <Box 
            style = {{display: 'flex', alignItems: 'center', gap: '10px'}}
        >
            {label} 
            {countErrors ? 
                <ErrorLabel
                    countErrors={countErrors}
                /> : null
            }
        </Box>
    )
}

export default SidebarItemTitle