import { Box, Typography } from "@mui/material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { AclForm } from "./AclForm";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../../../common/grid/GridOneToThree";
import { ControlledInput } from "../../../../../../common/input/ControlledInput";
import { useFormContext } from "react-hook-form";
import { CreateUpdateStructureTemplateProps } from "../../../../../../../types/content/structureTemplates/CreateUpdateStructureTemplateProps";
import { ControlledTextarea } from "../../../../../../common/textarea/ControlledTextarea";
import { useTranslation } from "react-i18next";

interface IProps {
  childName: string;
  childIndex: number;
}
export function StructureTemplateFormChild({ childName, childIndex }: IProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<{ childs: CreateUpdateStructureTemplateProps[] }>();

  const templateItems: GridOneToThreeItemProps[] = [
    {
      title: "Node Type",
      content: (
        <ControlledInput
          type="text"
          disabled
          register={{
            ...register(`childs.${childIndex}.type`),
          }}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Node Name",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register(`childs.${childIndex}.name`, {
              required: {
                value: true,
                message: t("inputRequired").replace("%1", t("name")),
              },
            }),
          }}
          helperText={errors?.childs?.[childIndex]?.name?.message || undefined}
          error={errors?.childs?.[childIndex]?.name !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Title",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register(`childs.${childIndex}.title`),
          }}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Description",
      required: true,
      content: (
        <ControlledTextarea
          register={{
            ...register(`childs.${childIndex}.description`, {}),
            maxRows: 4,
            minRows: 4,
          }}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box sx={{ display: "flex", gap: "10px", mt: "4px" }}>
        <TextSnippetOutlinedIcon />
        <Typography>{childName}</Typography>
      </Box>
      <Box sx={{ width: "400px", mb: 3 }}>
        <GridOneToThree items={templateItems} />
      </Box>
      <AclForm
        fieldsName={`childs[${childIndex}].accessControlList.elements`}
      />
    </Box>
  );
}
