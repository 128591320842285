import { StructureTemplateFormRoot } from "./StructureTemplateFormRoot";
import { StructureTemplateFormChild } from "./StructureTemplateFormChild";

interface IProps {
  isRoot: boolean;
  childName: string;
  childIndex: number;
}
export function StructureTemplateForm({
  isRoot,
  childName,
  childIndex,
}: IProps) {
  if (isRoot) return <StructureTemplateFormRoot />;

  if (childIndex !== -1)
    return (
      <StructureTemplateFormChild
        childName={childName}
        childIndex={childIndex}
      />
    );

  return null;
}
