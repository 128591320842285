import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { LifeCyclesCreateDialog } from './create/LifeCyclesCreateDialog';
import { LifeCyclesPageList } from './LifeCyclesPageList';
import { Trans } from 'react-i18next';

export const LifeCyclesPage = () => {
  return (
    <Page>
      <PageHeader title="Life Cycle" />
      <PageContent subtitle={<Trans i18nKey={'headerLifeCycles'} />}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <LifeCyclesCreateDialog />
          <LifeCyclesPageList />
        </Paper>
      </PageContent>
    </Page>
  );
};
