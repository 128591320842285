import { Box, FormControl, NativeSelect, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { AddButton } from '../../../../common/button/AddButton';
import { DeleteIconButton } from '../../../../common/button/iconButton/DeleteIconButton';

export const Parameters = () => {
  const { register, getValues, setValue } = useFormContext();
  const parameters = getValues('parameters') || [];

  const staticTypesParameters = ['String', 'Boolean', 'Number'];

  const addParam = () => {
    const newParams = {
      name: 'new name',
      type: 'String',
    };

    const updatedParameters = [...parameters, newParams];
    setValue('parameters', updatedParameters);
  };

  const deleteParam = (index: number) => {
    const updatedParameters = parameters.filter((_: any, i: any) => i !== index);
    setValue('parameters', updatedParameters);
  };

  return (
    <Box>
      <Box>
        <AddButton onClick={addParam} variant="contained">
          Add parameter
        </AddButton>
      </Box>
      {parameters.map((par: any, i: any) => (
        <Box key={i}>
          <Box display="flex" gap="10px" alignItems="center">
            <TextField
              label="Name"
              defaultValue={par.name}
              {...register(`parameters.${i}.name`)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <FormControl
              sx={{
                width: '200px',
                border: '1px solid #f4f4f4',
                padding: '11px',
                borderRadius: '4px',
              }}
              variant="standard"
            >
              <NativeSelect
                id={`input_type_${i}`}
                defaultValue={par.type}
                {...register(`parameters.${i}.type`)}
              >
                {staticTypesParameters.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <DeleteIconButton onClick={() => deleteParam(i)} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
