import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setUserPackage(state, action){
      state.userPackage = action.payload.userPackage;
      localStorage.setItem("nuxeoPackage", JSON.stringify(action.payload.userPackage));
    },
    setCompany(state, action){
      state.userPackage.company = action.payload.company;
      localStorage.setItem("nuxeoPackage", JSON.stringify(action.payload.userPackage));
    }
    
  }
})

export const { setUserPackage, setCompany } = packagesSlice.actions;
export default packagesSlice.reducer;

