export const DefaultPermissionsForWorkflow: string[] = [
  'Edit',
  'Read',
  'Browse',
  'Read & Edit',
  'Add Children',
  'Remove Children',
  'Read Children',
  'Edit Security',
  'Edit LifeCycle',
  'Read LifeCycle',
  'Read Properties',
  'Edit Properties',
  'Manage Workflows',
  'Review Participant',
  'Manage Everything',
  'Version',
  'Read Version',
  'Write Version',
  'Remove',
  'Unlock',
  'Can ask for publishing',
  'Can Collect',
  'Data Visualization',
  'Comment',
];
