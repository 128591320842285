import {
  Box,
  Button,
  Grid,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { StructureTemplateProps } from "../../../../../../types/content/structureTemplates/StructureTemplateProps";
import { StructureTemplateTree } from "./StructureTemplateTree";
import { TreeManager } from "./TreeManager";
import { SimpleAccordion } from "../../../../../common/accordion/SimpleAccordion";
import { StructureTemplateForm } from "./StructureTemplateForm/StructureTemplateForm";
import { CreateUpdateStructureTemplateProps } from "../../../../../../types/content/structureTemplates/CreateUpdateStructureTemplateProps";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ConfirmDiscardDialog from "../../../../../common/dialog/ConfirmDiscardDialog";
import { GetStructureTemplateProps } from "../../../../../../types/content/structureTemplates/GetStructureTemplateProps";
import { STRUCTURE_TEMPLATES_ENDPOINT } from "../../../../../../serverInteraction/services/endpointsConstants";
import useUpdate from "../../../../../../serverInteraction/hooks/entity/useUpdate";
import useAlert from "../../../../../../context/alertContext/useAlert";
import { Loader } from "../../../../../common/loader/Loader";
import useLoader from "../../../../../../context/loaderContext/useLoader";

interface IProps {
  structureTemplate: StructureTemplateProps;
  customDoctypes: string[];
  refetch: any;
}

export function StructureTemplateView({
  structureTemplate,
  customDoctypes,
  refetch,
}: IProps) {
  const { t } = useTranslation();
  const { showSuccess } = useAlert();
  const onSuccessUpdate = (data: GetStructureTemplateProps) => {
    showSuccess(t("dataUpdated"));
    //перерисуем
    refetch();
  };
  const { update, isUpdating } = useUpdate<
    CreateUpdateStructureTemplateProps,
    GetStructureTemplateProps
  >(STRUCTURE_TEMPLATES_ENDPOINT, onSuccessUpdate);

  const { setIsLoaderOpen } = useLoader();

  const [selectedTemplate, setSelectedTemplate] = useState<{
    featureId: string;
    isRoot: boolean;
    childName?: string;
    childIndex: number;
  }>({ featureId: structureTemplate.featureId, isRoot: true, childIndex: -1 });

  const methods = useForm<CreateUpdateStructureTemplateProps>({
    defaultValues: {
      ...structureTemplate,
    },
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const { append, remove, fields } = useFieldArray({
    name: "childs",
    control: control,
    shouldUnregister: true,
  });

  // EFFECTS
  useEffect(() => {
    setIsLoaderOpen(isUpdating);
  }, [isUpdating]);

  // EVENTS
  const handleCreateChild = (doctype: string) => {
    const newNode: CreateUpdateStructureTemplateProps = {
      company: structureTemplate.company,
      document: structureTemplate.document,
      featureId: `${structureTemplate.featureId}_${structureTemplate.childs.length}`,
      type: doctype,
      targetDocType: doctype,
      name: "Unnamed",
      accessControlList: {
        elements: [],
        blockInheritance: false,
      },
      childs: [],
      deploymentMode: structureTemplate.deploymentMode,
    };
    //Добавляем узел, Встаем на него
    const newIndex = fields.length;
    append(newNode);
    setSelectedTemplate({
      featureId: newNode.featureId,
      isRoot: false,
      childName: newNode.name || "",
      childIndex: newIndex,
    });
  };

  const handleDeleteChild = () => {
    //Удаляем выбранный узел
    const index = fields.findIndex(
      (elem) => selectedTemplate.featureId === elem.featureId
    );
    remove(index);
    setSelectedTemplate({
      featureId: structureTemplate.featureId,
      isRoot: true,
      childIndex: -1,
    });
  };

  const onSubmit = async (
    newStructureTemplate: CreateUpdateStructureTemplateProps
  ) => {
    console.log(newStructureTemplate);
    const data: CreateUpdateStructureTemplateProps = {
      company: newStructureTemplate.company,
      document: newStructureTemplate.document,
      featureId: newStructureTemplate.featureId,
      type: newStructureTemplate.targetDocType,
      targetDocType: newStructureTemplate.targetDocType,
      deploymentMode: newStructureTemplate.deploymentMode,
      accessControlList: newStructureTemplate.accessControlList,
      childs: newStructureTemplate.childs,
    };

    try {
      await update("id", data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    reset();
  };

  //RENDERING
  return (
    <Stack spacing={3}>
      <FormProvider {...methods}>
        <Stack>
          <Box
            sx={{
              width: "100%",
              justifyContent: "right",
              display: "flex",
              paddingTop: "1em",
            }}
          >
            <ConfirmDiscardDialog
              buttonTitle={t("button_discard")}
              isNeedConfirm={isDirty}
              onConfirm={handleDiscard}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginLeft: "1em" }}
              onClick={handleSubmit(onSubmit)}
            >
              {t("save")}
            </Button>
          </Box>
        </Stack>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <TreeManager
            isRoot={selectedTemplate.isRoot}
            onCreateChild={handleCreateChild}
            customDoctypes={customDoctypes}
            onDeleteChild={handleDeleteChild}
          />
          <Grid
            container
            columns={12}
            spacing={1}
            justifyContent="center"
            alignItems="start"
          >
            <Grid item xs={2} sx={{ height: "100%" }}>
              <StructureTemplateTree
                structureTemplate={structureTemplate}
                children={fields}
                selectedNode={selectedTemplate.featureId}
                setSelectedTemplate={setSelectedTemplate}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                minHeight: "3rem",
                alignItems: "center",
              }}
            >
              <StructureTemplateForm
                isRoot={selectedTemplate.isRoot}
                childName={selectedTemplate.childName || ""}
                childIndex={selectedTemplate.childIndex}
              />
            </Grid>
          </Grid>
          <SimpleAccordion
            title={t("advancedConfigiration")}
            sx={{ mt: 3 }}
            defaultExpanded={true}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ marginRight: 10 }}>
                {t("deploymentMode")}
              </Typography>
              <Select {...register("deploymentMode")} native>
                <option key="MERGE" value="MERGE">
                  Merge
                </option>
                <option key="OVERRIDE" value="OVERRIDE">
                  Override
                </option>
              </Select>
            </div>
          </SimpleAccordion>
        </Paper>
      </FormProvider>
    </Stack>
  );
}
