import { useSelector } from 'react-redux';
import { FacetProps, FacetWithJsonProps } from '../../../../types/content/facets/FacetProps';
import { FACETS_ENDPOINT } from '../../../services/endpointsConstants';
import useGet from '../../entity/useGet';
import { PackageProps } from '../../../../types/packages/PackageProps';

const useFacet = (id: string) => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const params = `company=${userPackage.company}&document=${userPackage.document}&id=${id}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<FacetProps>(
    FACETS_ENDPOINT,
    params,
  );
  
  return {
    status,
    facet: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useFacet;
