import { Link, Stack, Typography, Box } from "@mui/material";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../../../styling/Theme";
import useFacets from "../../../../serverInteraction/hooks/content/facets/useFacets";
import { FacetsDeleteDialog } from "./delete/FacetsDeleteDialog";
import { FacetWithJsonProps } from "../../../../types/content/facets/FacetProps";
import FacetsRenameDialog from "./rename/FacetsRenameDialog";
import FacetsCopyDialog from "./copy/FacetsCopyDialog";

export function FacetsPageList() {
  const { status, facets, isLoading, isFetching, error, refetch } = useFacets();
  if (isLoading || isFetching || facets === undefined) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const facetsList = JSON.parse(facets?.toString());
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {facetsList.map((facet: FacetWithJsonProps) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={facet.featureId}
          >
            <Link underline="hover" component={RouterLink} to={`${facet.id}`}>
              {facet.featureId}
            </Link>
            <Stack
              justifyContent="space-between"
              alignItems="right"
              spacing={2}
            >
              <Box display="flex">
                <FacetsRenameDialog
                  featureId={facet.featureId}
                  existingIds={facetsList.map((elem: any) =>
                    elem.featureId.toLowerCase()
                  )}
                  refetch={() => refetch()}
                />
                <FacetsCopyDialog
                  facet={facet}
                  existingIds={facetsList.map((elem: any) =>
                    elem.featureId.toLowerCase()
                  )}
                  refetch={() => refetch()}
                />
                <FacetsDeleteDialog
                  featureId={facet.featureId}
                  onDelete={() => refetch()}
                />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
