import { Stack, Button, Box, FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SimpleModal } from "../../../../../../../../common/modal/SimpleModal";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../../../../../common/grid/GridOneToThree";
import { ControlledInput } from "../../../../../../../../common/input/ControlledInput";
import {
  AccessControlListElementProps,
  AccessControlListProps,
} from "../../../../../../../../../types/content/structureTemplates/StructureTemplateProps";
import { ControlledSelect } from "../../../../../../../../common/select/ControlledSelect";

type Props = {
  mode: "CREATE" | "UPDATE";
  isOpen: boolean;
  namePrefix: string;
  aclIndex: number;
  initialValue: AccessControlListElementProps;
  update: (index: number, obj: object) => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  control: any;
  setValue: any;
  getValues: any;
  register: any;
  trigger: any;
  errors: any;
};

export function EditAclDialog({
  mode,
  isOpen,
  namePrefix,
  aclIndex,
  initialValue,
  update,
  onCancel,
  onConfirm,
  control,
  setValue,
  getValues,
  register,
  trigger,
  errors,
}: Props) {
  const { t } = useTranslation();

  /*const namePrefix = isRoot
    ? "accessControlList.elements"
    : `childs.0.accessControlList.elements`;
  const childNamePrefix = `childs.${aclIndex}.accessControlList.elements`;*/

  // EVENTS
  const hadleChangeGrant = (e: any) => {
    if (aclIndex) {
      setValue(
        `${namePrefix}.${aclIndex}.grant`,
        e.target.value === "true" ? true : false
      );
    }
  };

  const validate = async () => {
    return await trigger([
      `${namePrefix}.${aclIndex}.name`,
      `${namePrefix}.${aclIndex}.permission`,
    ]);
  };

  const handleUpdate = async () => {
    const result = await validate();
    if (result) {
      const c = getValues(`${namePrefix}.${aclIndex}`);
      update(aclIndex, c);
      onConfirm?.();
    }
  };

  const handleCancel = async () => {
    if (mode === "UPDATE") setValue(`${namePrefix}.${aclIndex}`, initialValue);
    onCancel?.();
  };

  // RENDER
  const formItems: GridOneToThreeItemProps[] = [
    {
      title: t("user_groupNames"),
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register(`${namePrefix}.${aclIndex}.name`, {
              required: {
                value: true,
                message: t("inputRequired").replace("%1", t("user_groupNames")),
              },
            }),
          }}
          helperText={
            errors?.accessControlList?.elements?.[aclIndex]?.name?.message ||
            undefined
          }
          error={
            errors?.accessControlList?.elements?.[aclIndex]?.name !== undefined
          }
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: t("perms_title"),
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register(`${namePrefix}.${aclIndex}.permission`, {
              required: {
                value: true,
                message: t("inputRequired").replace("%1", t("perms_title")),
              },
            }),
          }}
          helperText={
            errors?.accessControlList?.elements?.[aclIndex]?.permission
              ?.message || undefined
          }
          error={
            errors?.accessControlList?.elements?.[aclIndex]?.permission !==
            undefined
          }
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: t("grant"),
      content: (
        <Controller
          name={`${namePrefix}.${aclIndex}.grant`}
          control={control}
          render={({ field }) => (
            <FormControl variant="outlined">
              <ControlledSelect
                {...field}
                value={field.value}
                valueList={[
                  { value: "true", label: "true" },
                  { value: "false", label: "false" },
                ]}
                size="small"
                sx={{ width: "200px" }}
                {...register(`${namePrefix}.${aclIndex}.grant`)}
                onChange={(e) => {
                  field.onChange(e);
                  hadleChangeGrant(e);
                }}
              />
            </FormControl>
          )}
        />
      ),
    },
  ];

  return (
    <SimpleModal
      title={`${t("edit")} ${t("accessControlList")}`}
      isOpen={isOpen}
      handleClose={handleCancel}
    >
      <Box>
        <hr />
        <Box sx={{ minHeight: 300 }}>
          <GridOneToThree items={formItems} />
        </Box>
        <Stack
          direction="row-reverse"
          spacing={3}
          alignItems="right"
          justifyContent="end"
          sx={{ pt: 3 }}
        >
          <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{ width: 150 }}
          >
            OK
          </Button>
          <Button variant="outlined" onClick={handleCancel} sx={{ width: 150 }}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </SimpleModal>
  );
}
