import { Box } from "@mui/material";
import { AddButton } from "../../../../../common/button/AddButton";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "../../../../../common/button/DeleteButton";
import { ChildCreateDialog } from "../createChild/ChildCreateDialog";

interface IProps {
  isRoot: boolean;
  onCreateChild: (doctype: string) => void;
  customDoctypes: string[];
  onDeleteChild: () => void;
}
export function TreeManager({
  isRoot,
  onCreateChild,
  customDoctypes,
  onDeleteChild,
}: IProps) {
  const { t } = useTranslation();
  if (isRoot) {
    return (
      <ChildCreateDialog
        onConfirm={onCreateChild}
        initialValue={""}
        customDoctypes={customDoctypes}
      />
    );
  }
  return (
    <Box sx={{ border: "1px solid grey", mb: 2 }}>
      <DeleteButton onClick={onDeleteChild}>{t("remove")}</DeleteButton>
    </Box>
  );
}
