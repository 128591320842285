import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IProps {
    name: string,
    control: any,
    register: any,
    getValues: any,
    fieldTypeInputName: string
}

function DefaultValueInput({ name, control, register, getValues, fieldTypeInputName }:IProps) {
    const { t } = useTranslation();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
            <TextField
                {...field}
                disabled = {getValues('isImported') || ['BLOB', 'COMPLEX'].includes(getValues(fieldTypeInputName))}
                label={t('fieldDefault')}
                {...register(name, {})}
                variant="outlined"
                size="small"
            
            />
            )}
        />
    )
}

export default DefaultValueInput