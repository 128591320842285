import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { useTranslation } from 'react-i18next';
import CodeEditor from './tabs/CodeEditor';
import { AutomationScriptingCreateDialog } from './create/AutomationScriptingCreateDialog';
import { AutomationsScriptingListPage } from './AutomationsScriptingListPage';

export const AutomationsScriptingPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title="Automation Scripting" />
      <PageContent subtitle="Nuxeo Automation scripting allows you to write scripts (in JavaScript) that are deployed on Nuxeo Platform as custom Automation operations that you can use in chains and bind like other operations and chains to buttons, workflows and event handlers.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <AutomationScriptingCreateDialog />
          <AutomationsScriptingListPage />
        </Paper>
      </PageContent>
    </Page>
  );
};
