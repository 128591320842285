import { FormControl } from "@mui/material";
import React from "react"
import { Controller } from "react-hook-form";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";
import { useTranslation } from "react-i18next";
import { FieldsSelectValue } from "../../../../../../../staticValues/Constants";

interface IProps {
    variant: "FIELD" | "SUBFIELD",
    fieldNamePrefix: string,
    name: string,
    isImported: boolean,
    control: any,
    register: any,
    setValue: any
    helperText: string,
    error: any,
    defaultInputName: string,
    referenceConfigurationInputName: string
}

function FieldTypeSelect( {variant, fieldNamePrefix, name, isImported, control, register, setValue, helperText, error, defaultInputName, referenceConfigurationInputName}: IProps) {
    const { t } = useTranslation();

    const hadleChangeType = (e: any) => {
        const newType = e.target.value;
        
        if (["BLOB", "COMPLEX"].includes(newType)) {
          setValue(defaultInputName, "");
        }

        //Сбросим все constraints (они зависят от типа), кроме required (общие)
        let i;
        for (i = 2 ; i < 6; i++){
            setValue(`${fieldNamePrefix}.constraints.${i}`, null);
        }
        
        //Для типов Document, UserGroup, Directory установим referenceConfiguration.referenceName
        //для остальных типов сбросим referenceConfiguration
        if (["DOCUMENT", "USER_GROUP", "DIRECTORY"].includes(newType)) {
            setValue(referenceConfigurationInputName, {referenceName: newType.toLowerCase(), firstParameterValue: null, secondParameterValue: null});
        } else {
            setValue(referenceConfigurationInputName, {});
        }
    };

    //RENDER
    const options = variant === "FIELD" ? FieldsSelectValue : FieldsSelectValue.filter(option => option.value !== "COMPLEX")
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl variant="outlined">
                    <ControlledSelect
                        {...field}
                        value = {field.value}
                        valueList={options}
                        label={t("fieldType")}
                        disabled={isImported}
                        size="small"
                        sx={{ width: "200px" }}
                        {...register(name, {
                            required: {
                            value: true,
                            message: t("inputRequired").replace("%1", t("fieldType")),
                            },
                        })}
                        helperText={helperText}
                        error={error}
                        onChange={(e) => {
                            field.onChange(e);
                            hadleChangeType(e);
                        }}
                    />
                </FormControl>
            )}
        />
    )
}

export default FieldTypeSelect