import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { SCHEMAS_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { SchemaProps, SchemaRenameProps } from '../../../../../types/content/schemas/SchemaProps';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import usePatch from '../../../../../serverInteraction/hooks/entity/usePatch';
import { BaseEntityProps } from '../../../../../types/BaseEntityProps';
import { Loader } from '../../../../common/loader/Loader';
import RenameDialog from '../../../../common/dialog/RenameDialog';

type Props = {
  featureId: string;
  refetch: () => void;
};

function SchemasRenameDialog({ refetch, featureId }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { patch, isUpdating } = usePatch<SchemaRenameProps, SchemaRenameProps & BaseEntityProps>(SCHEMAS_ENDPOINT);
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<SchemaRenameProps>({
    defaultValues: {
      oldFeatureId: featureId,
      company: userPackage.company,
      document: userPackage.document,
    },
  });

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('newFeatureId', {
              required: {
                value: true,
                message: 'Feature ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              maxLength: {
                value: 40,
                message: 'Must be at more 40 symbols',
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
            }),
          }}
          helperText={errors.newFeatureId?.message}
          error={errors.newFeatureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];
  
  const onSubmit = async (data: SchemaRenameProps) => {
    isUpdating && <Loader />;
    try {
      const res: SchemaProps = JSON.parse(`${await patch(data)}`);
      reset();
      
    } catch (error: any ) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <RenameDialog
      title={`${t('rename')} ${featureId}`}
      update={handleSubmit(onSubmit)}
      validate={validate}
      isUpdate={refetch}
    >
      <hr />
      <Box sx={{ height: 'auto', marginTop: 1 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </RenameDialog>
  );
}

export default SchemasRenameDialog;