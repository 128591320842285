import { Link, Stack, Typography, Box } from "@mui/material";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../../../styling/Theme";
import useStructureTemplates from "../../../../serverInteraction/hooks/content/structureTemplates/useStructureTemplates";
import { GetStructureTemplateProps } from "../../../../types/content/structureTemplates/GetStructureTemplateProps";
import { StructureTemplatesRenameDialog } from "./rename/StructureTemplatesRenameDialog";
import { StructureTemplatesCopyDialog } from "./copy/StructureTemplatesCopyDialog";
import { StructureTemplatesDeleteDialog } from "./delete/StructureTemplatesDeleteDialog";

export function StructureTemplatesList() {
  const { status, structureTemplates, isLoading, isFetching, error, refetch } =
    useStructureTemplates();
  if (isLoading || isFetching || structureTemplates === undefined) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const structureTemplatesList = JSON.parse(structureTemplates?.toString());
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {structureTemplatesList.map(
          (structureTemplate: GetStructureTemplateProps) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ py: 1, px: 2 }}
              key={structureTemplate.featureId}
            >
              <Link
                underline="hover"
                component={RouterLink}
                to={`${structureTemplate.id}`}
              >
                {structureTemplate.featureId}
              </Link>
              <Stack
                justifyContent="space-between"
                alignItems="right"
                spacing={2}
              >
                <Box display="flex">
                  <StructureTemplatesRenameDialog
                    featureId={structureTemplate.featureId}
                    existingIds={structureTemplatesList.map((elem: any) =>
                      elem.featureId.toLowerCase()
                    )}
                    refetch={() => refetch()}
                  />
                  <StructureTemplatesCopyDialog
                    structureTemplate={structureTemplate}
                    existingIds={structureTemplatesList.map((elem: any) =>
                      elem.featureId.toLowerCase()
                    )}
                    refetch={() => refetch()}
                  />
                  <StructureTemplatesDeleteDialog
                    featureId={structureTemplate.featureId}
                    onDelete={() => refetch()}
                  />
                </Box>
              </Stack>
            </Stack>
          )
        )}
      </Stack>
    </Box>
  );
}
