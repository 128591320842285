import { useSearchParams } from "react-router-dom";
import { AppBar, Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PageHeader } from "../../../common/page/PageHeader";
import { Page } from "../../../common/page/Page";
import { PageContent } from "../../../common/page/PageContent";
import { DefinitionForm } from "./tabs/DefinitionForm";
import { GetDocTypesProps } from "../../../../types/content/documentTypes/GetDocTypesProps";
import useUpdate from "../../../../serverInteraction/hooks/entity/useUpdate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DOCTYPES_ENDPOINT } from "../../../../serverInteraction/services/endpointsConstants";
import { CreateUpdateDocTypesProps } from "../../../../types/content/documentTypes/CreateUpdateDocTypesProps";
import { CommonEntityProps } from "../../../../types/common/CommonEntityProps";
import { getDoctypeValidationErrors } from "../../../../store/validationErrors/utils";
import useAlert from "../../../../context/alertContext/useAlert";
import ValidationErrorsAccordion from "../../../common/accordion/ValidationErrorsAccordion/ValidationErrorsAccordion";
import useValidationErrors from "../../../../serverInteraction/hooks/validation-errors/useValidationErrors";
import { ValidationErrorProps } from "../../../../types/validation-errors/ValidationErrorProps";
import SchemaTab from "./tabs/schemaTab/SchemaTab";
import { formatFieldConstrants } from "../schemas/SchemaPage/FieldAdvancedSettingsV1/validationViews/utils";
import { useNavigationPrompt } from "../../../../hooks/useNavigationPrompt/useNavigationPrompt";
import ConfirmDiscardDialog from "../../../common/dialog/ConfirmDiscardDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index?: any;
  value?: any;
}

interface IProps {
  initialData: CreateUpdateDocTypesProps,
  documentTypesList: string[],
  facetsList: CommonEntityProps[],
  facetsRequired: string[],
  lifeCyclesList: any[],
  schemasList: string[],
  refetch: any
}

export function DocumentTypeView({initialData, documentTypesList, facetsList, facetsRequired, lifeCyclesList, schemasList, refetch }: IProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab") || "0");
  const fieldParam = searchParams.get("field");
  const subfieldParam = searchParams.get("subfield");
  
  const {data: validationErrors, update: updateValidationErrors} = useValidationErrors("document-types", initialData.featureId);
  
  const onSuccessUpdate = (data: GetDocTypesProps) => {
    showSuccess(t("dataUpdated"));
    //перерисуем
    refetch();
    //обновим ValidationErrors
    const doctypeValidationErrors: ValidationErrorProps[] = getDoctypeValidationErrors(JSON.parse(data?.toString()));
    updateValidationErrors(doctypeValidationErrors);
  }
  
  const { update } = useUpdate<CreateUpdateDocTypesProps, GetDocTypesProps>(DOCTYPES_ENDPOINT, onSuccessUpdate);

  const { control, getValues, setValue, register, watch, handleSubmit, formState: { isDirty, errors}, reset } = useForm<CreateUpdateDocTypesProps>({
    defaultValues: initialData
  });
  const { showSuccess } = useAlert();
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const onSubmit = async (formData: CreateUpdateDocTypesProps) => {
    //отформатируем перед отправкой
    //customSchema.fields.constraints: обнулим все неподходящее, отправляем только ненулевые
    formData.fields?.forEach(field => {
      field.constraints = formatFieldConstrants(field.constraints, field.fieldType, field.isMultiValue)
      field.subFields?.forEach((subfield: any) => {
        subfield.constraints = formatFieldConstrants(subfield.constraints, subfield.fieldType, subfield.isMultiValue)
      })
    })

    //фасеты пишем только необязательные 
    const newFacets: string[] | undefined = formData.facets?.filter((elem: string) => !facetsRequired.includes(elem))

    const newData: CreateUpdateDocTypesProps = {
      featureId: initialData.featureId,
      company: initialData.company,
      document: initialData.document,
      label: formData.label,
      description: formData.description,
      extendType: formData.extendType,
      iconUrl: formData.iconUrl,
      bigIconUrl: formData.bigIconUrl,
      lifeCycle: formData.lifeCycle,
      containerTypes: formData.containerTypes,
      subTypes: formData.subTypes,
      facets: newFacets,
      deploymentMode: formData.deploymentMode,
      schemas: formData.schemas,
      customSchema: {
        featureId: formData.customSchema.featureId,
        prefix: formData.customSchema.prefix,
        companyName: initialData.company,
        documentName: initialData.document,
        fields: formData.fields || []
      }
    }

    try {
      await update("id", newData);
    } catch (error: any ) {
      console.log(error);
    }
  };

  const onNavigationSave = () => {
    onSubmit(getValues());
  }

  useNavigationPrompt({isDirty, onSave: onNavigationSave});

  const handleDiscardClick = () => {
    reset();
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (!fieldParam) {
      setSearchParams({tab: newValue.toString()});
    } else if (!subfieldParam) {
      setSearchParams({tab: newValue.toString(), field: fieldParam});
    } else {
      setSearchParams({tab: newValue.toString(), field: fieldParam, subfield: subfieldParam});
    }
  };

  return (
    <Page>
      <PageHeader title={`Document Type ${initialData.featureId}`}></PageHeader>
      <PageContent subtitle={`Define the main characteristics of your document type`}>
        <ValidationErrorsAccordion 
          variant="ENTITY"
          errors={validationErrors}
        />
        <form id="doctype__form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Stack >
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "right",
                  display: "flex",
                  paddingTop: "1em",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon/>}
                  sx={{alignItems:"center"}}
                >
                  {`${t("configureLayoutsButton")} Designer`}
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "right",
                  display: "flex",
                  paddingTop: "1em",
                }}
              >
                <ConfirmDiscardDialog 
                  buttonTitle = {t("button_discard")}
                  isNeedConfirm = {isDirty}
                  onConfirm = {handleDiscardClick}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  
                  sx={{ marginLeft: "1em" }}
                >
                  {t("save")}
                </Button>
              </Box>
            </Stack>
            <AppBar style={{ backgroundColor: "white", color: "black" }} position="static">
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="simple tabs example">
                <Tab label="Definition" />
                <Tab label="Schema" />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
              <DefinitionForm
                documentTypesList = {documentTypesList}
                facetsList = {facetsList}
                facetsRequired={facetsRequired}
                lifeCyclesList={lifeCyclesList}
                initialContainerTypes = {initialData.containerTypes}
                initialFacets = {initialData.facets || []}
                control = {control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue = {setValue}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <SchemaTab
                extendType={initialData.extendType}
                inheritedSchemas={initialData.inheritedSchemas || ""}
                schemasBroughtByFacets={initialData.schemasBroughtByFacets || ""}
                schemasList={schemasList}
                initialCustomSchema={initialData.customSchema}
                control={control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                watch={watch}              />
            </TabPanel>
          </Stack>
        </form>
      </PageContent>
    </Page>
  );
}
