import { Box, Typography } from "@mui/material";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { AclForm } from "./AclForm";
import { useFormContext } from "react-hook-form";

export function StructureTemplateFormRoot() {
  const { getValues } = useFormContext();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box sx={{ display: "flex", gap: "10px", mt: "4px" }}>
        <AccountTreeOutlinedIcon />
        <Typography>{getValues("targetDocType")}</Typography>
      </Box>
      <AclForm fieldsName="accessControlList.elements" />
    </Box>
  );
}
