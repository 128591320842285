import { Box, Button, TextField } from "@mui/material";
import { Node } from "react-flow-renderer";
import { useState, useEffect } from "react";

interface NodeEditorProps {
  selectedNode: Node | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<Node | null>>;
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  setBoxNameMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  setEdges: React.Dispatch<React.SetStateAction<any[]>>;
}

const NodeEditor = ({
  selectedNode,
  setSelectedNode,
  nodes,
  setNodes,
  setBoxNameMap,
  setEdges,
}: NodeEditorProps) => {
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");

  useEffect(() => {
    if (selectedNode) {
      setNewName(selectedNode.data.label);
      setNewDescription(selectedNode.data.description);
    }
  }, [selectedNode]);

  const handleNodeNameChange = () => {
    if (selectedNode) {
      const oldName = selectedNode.id;

      setNodes((nds) =>
        nds.map((node) =>
          node.id === selectedNode.id
            ? {
                ...node,
                id: newName,
                data: {
                  ...node.data,
                  label: newName,
                  description: newDescription,
                },
              }
            : node
        )
      );

      setBoxNameMap((prev) => ({
        ...prev,
        [oldName]: newName,
      }));

      setEdges((eds) =>
        eds.map((edge) => ({
          ...edge,
          source: edge.source === oldName ? newName : edge.source,
          target: edge.target === oldName ? newName : edge.target,
        }))
      );

      handleCancel();
    }
  };

  const handleCancel = () => {
    setSelectedNode(null);
    setNewName("");
    setNewDescription("");
  };

  if (!selectedNode) return null;

  return (
    <Box
      sx={{
        marginTop: "20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        border: "1px solid #000",
        padding: "10px",
      }}
    >
      <TextField
        value={newName}
        label="Change the name of the box"
        variant="outlined"
        onChange={(e) => setNewName(e.target.value)}
      />
      <TextField
        value={newDescription}
        label="Change the description of the box"
        variant="outlined"
        onChange={(e) => setNewDescription(e.target.value)}
      />
      <Button variant="contained" onClick={handleNodeNameChange}>
        Confirm
      </Button>
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
    </Box>
  );
};

export default NodeEditor;
