import { Params, useParams } from 'react-router-dom';
import { Loader } from '../../../../common/loader/Loader';
import useSchemaWithFields from '../../../../../serverInteraction/hooks/content/schemas/useSchemaWithFields';
import { convertSchemaFieldsJsonToSchemaFieldsType } from '../../../../../utils/CommonUtils';
import SchemaView from './SchemaView';


const SchemaPage = () => {
  const { featureId } = useParams<Params>();
  
  const { schema, isLoading, isFetching, refetch } = useSchemaWithFields(featureId || '');
  if (isLoading || isFetching || schema === undefined) {
    return <Loader />;
  }

  const schemaData = convertSchemaFieldsJsonToSchemaFieldsType(JSON.parse(schema.toString()));

  return (
   <SchemaView schema = {schemaData} refetch = {refetch}/>
  );
}


export default SchemaPage;
