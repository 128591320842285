import { useParams } from 'react-router-dom';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import useAutomationChains from '../../../../serverInteraction/hooks/automation/automationChains/useAutomationChains';
import { useTranslation } from 'react-i18next';
import useUpdate from '../../../../serverInteraction/hooks/entity/useUpdate';
import {
  AUTOMATION_CHAINS,
  AUTOMATION_SCRIPTING,
} from '../../../../serverInteraction/services/endpointsConstants';
import { FormProvider, useForm } from 'react-hook-form';
import { SyntheticEvent, useEffect, useState } from 'react';
import { AutomationChainsProps } from '../../../../types/automation/automationChains/AutomationChainsProps';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '../../../common/tabPanel/tabPanel';
import { ChainEditor } from './tabs/ChainEditor';
import { Parameters } from '../automationScripting/tabs/Parameters';
import { InitialData } from './tabs/iProps';

export const AutomationChainsView = () => {
  const { id } = useParams();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, isLoading, isFetching, error, automationChains } = useAutomationChains(
    userPackage.company,
    userPackage.document,
    id,
  );

  const { t } = useTranslation();
  const { update } = useUpdate(AUTOMATION_CHAINS);
  const methods = useForm();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (automationChains) {
      const initialData = JSON.parse(`${automationChains}`);
      const { featureId, parameters, operations, documentation } = initialData;

      const enrichedOperations = operations.map((op: any) => ({
        ...op,
        params: op.params.map((param: any) => ({
          ...param,
          value: param.value || false,
        })),
      }));

      methods.reset({
        featureId,
        documentation,
        parameters,
        operations: enrichedOperations,
      });
    }
  }, [automationChains, methods]);

  const onSubmit = async (data: any) => {
    const formatParams = data.parameters.map((par: any) => {
      return {
        name: par.name,
        type: par.type.toUpperCase(),
      };
    });
    const operations = (data.droppedItems || []).map((item: any) => ({
      name: item.name ? `${item.name}` : item.subTypeName,
      category: item.category ? item.category : item.typeName,
      params: (item.fields || item.params)?.map((field: any) => ({
        name: field.name,
        required: field.required,
        value:
          data[field.name] !== undefined
            ? data[field.name] === false
              ? ''
              : data[field.name] !== null
              ? data[field.name]
              : ''
            : field.value !== null
            ? field.value
            : '',
        tagType: field.tagType,
      })),
    }));

    const allData = {
      featureId: id,
      company: userPackage.company,
      document: userPackage.document,
      operations,
      parameters: formatParams || [],
      documentation: data.documentation,
    };

    console.log('Собранные данные для отправки на сервер:', allData);
    await update(`${id}`, allData);
  };

  if (isLoading || isFetching || !automationChains) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Ошибка: {error.message}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3, mt: '50px' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="process tabs">
            <Tab label={`${t('chainEditorTabName')}`} />
            <Tab label={`${t('chaniParametersTabName')}`} />
          </Tabs>
          {/* // Здесь должен быть switch editor */}
          <TabPanel value={selectedTab} index={0}>
            <ChainEditor />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Parameters />
          </TabPanel>
          <Button type="submit" variant="contained" color="primary">
            Submit All Data
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
};
