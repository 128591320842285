import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { SCHEMAS_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { SchemaProps } from '../../../../../types/content/schemas/SchemaProps';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { Loader } from '../../../../common/loader/Loader';
import useSchemaWithFields from '../../../../../serverInteraction/hooks/content/schemas/useSchemaWithFields';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { SchemaWithFieldsPropsCreate, SchemaWithFieldsPropsGet } from '../../../../../types/content/schemas/SchemaWithFieldsProps';
import { CopyContentDialog } from '../../../../common/dialog/CopyContentDialog';
import { convertSchemaFieldsJsonToSchemaFieldsType } from '../../../../../utils/CommonUtils';

type Props = {
  schema: SchemaProps;
  refetch: () => void;
};

function SchemasCopyDialog({ schema, refetch }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  
  const { schema: schemaWithFields, isLoading, isFetching } = useSchemaWithFields(schema.featureId);
  const { create, isCreating } = useCreate<SchemaWithFieldsPropsCreate, SchemaWithFieldsPropsGet>(SCHEMAS_ENDPOINT);

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<SchemaWithFieldsPropsCreate>();

  if (isLoading || isFetching || schemaWithFields === undefined) {
    return <Loader />;
  }

  const schemaForCopy: SchemaWithFieldsPropsGet = convertSchemaFieldsJsonToSchemaFieldsType(JSON.parse(schemaWithFields.toString()));
  
  const copyItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              required: {
                value: true,
                message: 'Feature ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              maxLength: {
                value: 40,
                message: 'Must be at more 40 symbols',
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];
  
  const onSubmit = async (data:  SchemaWithFieldsPropsCreate) => {
    isCreating && <Loader />;
    try {
      const newSchema = {
        featureId: data.featureId,
        prefix: schemaForCopy.prefix,
        companyName: userPackage.company,
        documentName: userPackage.document,
        fields: schemaForCopy.fields,
      }
      const res: SchemaWithFieldsPropsGet = JSON.parse(`${await create(newSchema)}`);
      reset();
      
    } catch (error: any ) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <CopyContentDialog
      title={`${t('copy')} ${schema.featureId}`}
      copyContent={handleSubmit(onSubmit)}
      validate={validate} 
      isCopyContent={refetch}
    >
      <Box sx={{ height: 'auto', marginTop: 1 }}>
        <GridOneToThree items={copyItems} />
      </Box>
    </CopyContentDialog>

    
  );
}

export default SchemasCopyDialog;
