import { Outlet } from "react-router-dom";
import { LoaderProvider } from "../../context/loaderContext/LoaderContextProvider";
import { LoaderModal } from "../common/modal/LoaderModal";
import { UserProvider } from "../../context/userContext/UserProvider";

function App() {
  return (
    <UserProvider>
      <LoaderProvider>
        <Outlet />
        <LoaderModal />
      </LoaderProvider>
    </UserProvider>
  );
}

export default App;
