import { Box, Button } from '@mui/material';
import { FC } from 'react';

interface ActionButtonsProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ onClose, onConfirm }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: '16px' }}>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
      <Button variant="contained" onClick={onConfirm}>
        OK
      </Button>
    </Box>
  );
};
