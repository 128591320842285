import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { SimpleAccordion } from "../../../../../common/accordion/SimpleAccordion";
import { ControlledInput } from "../../../../../common/input/ControlledInput";
import SchemaFieldsTableV1 from "../../../schemas/SchemaPage/SchemaFieldsTableV1/SchemaFieldsTableV1";

interface IProps {
  control: any,
  register: any,
  errors: any,
  getValues: any,
  setValue: any,
  watch: any
}

export const SchemaForm = ({ control, register, errors, getValues, setValue, watch}: IProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  // EFFECTS 
  //если была ошибка несоответствия параметров и данных, сотрем параметры
  useEffect(()=>{
    if (searchParams.get("field")) {
      searchParams.delete("field")
    }
    if (searchParams.get("subfield")) {
      searchParams.delete("subfield")
    }
  }, [])
  
  const navToAdvancedSettings = (index: number)=> {
    setSearchParams({tab: "1", field: `${index}`});
  };

    return (
      <Box>
          <SimpleAccordion title="Add custom schema" sx={{ mt: 3 }} defaultExpanded={true}>
            <Box display="flex" gap="20px" marginBottom="20px">
              <Box display="flex" alignItems="start">
                <Typography variant="h6" style={{ marginRight: 10, paddingTop: 4 }}>
                  {t("name")}
                </Typography>
                <ControlledInput
                  type="text"
                  register={{
                    ...register("customSchema.featureId", {
                      pattern: {
                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                        message: t("patternValidation")
                      },
                      required: {
                        value: true,
                        message: t("inputRequired").replace("%1", t("Feature ID")),
                      },
                      minLength: {
                        value: 3,
                        message: t("minValidation").replace("%1", "2"),
                      },
                    }),
                   }}
                  helperText={errors.customSchema?.featureId?.message}
                  error={errors.customSchema?.featureId !== undefined}
                  sx={{ width: 300 }}
                />
              </Box>
              <Box display="flex" alignItems="start">
                <Typography variant="h6" style={{ marginRight: 10, paddingTop: 4 }}>
                  Prefix
                </Typography>
                <ControlledInput
                  type="text"
                  register={{
                    ...register("customSchema.prefix", {
                      pattern: {
                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                        message: t("patternValidation")
                      },
                      required: {
                        value: true,
                        message: t("inputRequired").replace("%1", t("Schema Prefix")),
                      },
                      minLength: {
                        value: 3,
                        message: t("minValidation").replace("%1", "2"),
                      }
                    }),
                  }}
                  helperText={errors.customSchema?.prefix?.message}
                  error={errors.customSchema?.prefix !== undefined}
                  sx={{ width: 300 }}
                />
              </Box>
            </Box>
            
            <SchemaFieldsTableV1 
              variant={"FIELD"}
              namePrefix={"customSchema.fields"}
              errorsObj={errors.customSchema?.fields}
              control={control}
              register={register}
              getValues={getValues}
              setValue={setValue}
              onNavigate={navToAdvancedSettings} 
              errors={errors} 
              watch={watch}
            />
            
            </SimpleAccordion>
          </Box>
       
    );
  
};

export default SchemaForm;
