import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TransferList } from '../../../../common/transfetList/TransferList';
import { DefaultPermissionsForWorkflow } from '../../../../../enum/DefaultPermissionsForWorkflow';
import { DefaultTypesForDocument } from '../../../../../enum/DefaultTypesForDocument';
import { DefaultFacetsForWorkflow } from '../../../../../enum/DefaultFacetsForWorkflow';
import useFacets from '../../../../../serverInteraction/hooks/content/facets/useFacets';
import { AxiosError } from 'axios';
import { Loader } from '../../../../common/loader/Loader';
import useDocTypes from '../../../../../serverInteraction/hooks/content/document-types/useDocTypes';

const ActivasionTab: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const activation = watch('activation') || {};

  const { facets, isFetching, isLoading, error, status } = useFacets();
  const {
    documentTypes,
    isLoading: documentTypesLoading,
    isFetching: documentTypesFetching,
  } = useDocTypes();

  if (
    isLoading ||
    documentTypesLoading ||
    isFetching ||
    documentTypesFetching ||
    facets === undefined ||
    documentTypes === undefined
  ) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const customFacetsNamesList = JSON.parse(`${facets}`).map((facet: any) => facet.featureId);
  const allFacetsData = DefaultFacetsForWorkflow.concat(customFacetsNamesList);

  const defaultDocTypes = Object.keys(DefaultTypesForDocument)
    .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
    .map((key: any) => key);

  const customDoctypesNameList = JSON.parse(`${documentTypes}`).map(
    (document: any) => document.featureId,
  );
  const allDocTypesData = defaultDocTypes.concat(customDoctypesNameList);

  const handlePermissionsChange = (newPermissions: string[]) => {
    setValue('activation.permissions', newPermissions);
  };

  const handleDocumentTypesChange = (newDocumentTypes: string[]) => {
    setValue('activation.documentTypes', newDocumentTypes || '');
  };

  const handleFieldChange = (field: string, value: any) => {
    setValue(`activation.${field}`, value || '');
  };

  return (
    <Box sx={{ maxWidth: 800, padding: 4 }}>
      <Typography variant="h6" gutterBottom>
        Workflow definition is available only if all of the following conditions are met:
      </Typography>

      {/* Transfer List для Permissions */}
      <Typography variant="subtitle1" gutterBottom>
        Current user has one of the permissions:
      </Typography>
      <TransferList
        entityList={DefaultPermissionsForWorkflow} // здесь нужно добавить еще массив из кастомных пермишенов
        loadEntities={activation.permissions || []}
        updateParent={handlePermissionsChange}
        width="100%"
        height={220}
      />

      {/* Transfer List для Document Types */}
      <Typography variant="subtitle1" gutterBottom>
        Current document has one of the types:
      </Typography>
      <TransferList
        entityList={allDocTypesData}
        loadEntities={activation.documentTypes || []}
        updateParent={handleDocumentTypesChange}
        width="100%"
        height={220}
      />

      {/* Остальные поля */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Current document has facet</InputLabel>
        <Select
          value={activation.currentFacet || []}
          onChange={(e) => handleFieldChange('currentFacet', e.target.value)}
        >
          {allFacetsData.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Current document state is"
        margin="normal"
        value={activation.documentState || ''}
        onChange={(e) => handleFieldChange('documentState', e.target.value)}
      />
      <TextField
        fullWidth
        label="Document path starts with"
        margin="normal"
        value={activation.documentPath || ''}
        onChange={(e) => handleFieldChange('documentPath', e.target.value)}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Current document is</InputLabel>
        <Select
          value={activation.currentDocument || []}
          onChange={(e) => handleFieldChange('currentDocument', e.target.value)}
        >
          {[
            'Any',
            'Regular Document',
            'Document Link',
            'Published Document',
            'Document Proxy',
            'Document Version',
            'Immutable Document',
            'Mutable Document',
          ].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Current user is member of"
        margin="normal"
        value={activation.userGroup || ''}
        onChange={(e) => handleFieldChange('userGroup', e.target.value)}
      />

      {/* Флажки */}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={activation.isAdmin || false}
              onChange={(e) => handleFieldChange('isAdmin', e.target.checked)}
            />
          }
          label="Current user is administrator"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activation.isClipboardNotEmpty || false}
              onChange={(e) => handleFieldChange('isClipboardNotEmpty', e.target.checked)}
            />
          }
          label="Nuxeo clipboard is not empty"
        />
      </FormGroup>

      {/* Custom EL Expression */}
      <TextField
        fullWidth
        label="Custom EL expression"
        multiline
        rows={4}
        margin="normal"
        placeholder="Enter your custom expression..."
        value={activation.customExpression || ''}
        onChange={(e) => handleFieldChange('customExpression', e.target.value)}
      />
    </Box>
  );
};

export default ActivasionTab;
