import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import useDocTypes from '../../../../serverInteraction/hooks/content/document-types/useDocTypes';
import { DocumentTypesDeleteDialog } from './delete/DocumentTypesDeleteDialog';
import { GetDocTypesProps } from '../../../../types/content/documentTypes/GetDocTypesProps';
import { UpdateDialogDocTypes } from './update/UpdateDialogDocTypes';
import useValidationErrors from '../../../../serverInteraction/hooks/validation-errors/useValidationErrors';
import EntityTitle from '../../../common/entitiesList/entitiyTitle';


export function DocumentTypesPageList() {
  const { status, documentTypes, isLoading, isFetching, error, refetch } = useDocTypes();
  const {data: validationErrors} = useValidationErrors('document-types');

  if (isLoading || isFetching || documentTypes === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  let returnDocTypes = JSON.parse(documentTypes?.toString());
  
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {
          <>
            {returnDocTypes.length > 0
              ? returnDocTypes.map((docType: GetDocTypesProps) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ py: 1, px: 2 }}
                    key={docType.featureId}
                  >
                    <EntityTitle
                      navTo = {`${docType.id}`}
                      label = {docType.featureId}
                      countErrors={validationErrors.filter((elem: any) => elem.featureId === docType.featureId).length} 
                    />
                    <Stack justifyContent="space-between" alignItems="right" spacing={2}>
                      <Box display="flex">
                        <UpdateDialogDocTypes
                          featureId={docType.featureId}
                          refetch={() => refetch()}
                        />

                        <DocumentTypesDeleteDialog
                          featureId={docType.featureId}
                          onDelete={() => refetch()}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                ))
              : 'no data'}
          </>
        }
      </Stack>
    </Box>
  );
}
