import { Header } from '../header/Header';
import { Sidebar } from '../sidebar/Sidebar';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AlertProvider } from '../../context/alertContext/AlertContextProvider';
import { AlertSnackbar } from '../common/snackbar/AlertSnackbar';
import { LoaderProvider } from '../../context/loaderContext/LoaderContextProvider';
import { LoaderModal } from '../common/modal/LoaderModal';
import { DocumentProvider } from '../../DocumentContext';
import { ProjectProvider } from '../../ProjectContext';
import { NavigationConfirmProvider } from '../../context/navigationConfirmContext/NavigationConfirmProvider';

function App() {

  return (
    <ProjectProvider>
      <DocumentProvider>
        <LoaderProvider>
          <AlertProvider>
            <NavigationConfirmProvider>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Header />
                <Sidebar />
                <Outlet />
                <AlertSnackbar />
              </Box>
            <LoaderModal />
            </NavigationConfirmProvider>
          </AlertProvider>
        </LoaderProvider>
      </DocumentProvider>
    </ProjectProvider>
  );
}

export default App;
