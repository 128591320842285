import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FC } from 'react';
import { SubpropertySelector } from './SubpropertySelector';

interface ContextSelectorProps {
  context: { id: number; value: string };
  onChange: (id: number, newValue: string) => void;
  options: { name: string; description?: string; properties?: any[]; parent?: boolean }[];
  selectedContext: any | null;
  onSubpropertyChange: (newValue: string) => void; // Добавлено новое свойство
}

export const ContextSelector: FC<ContextSelectorProps> = ({
  context,
  onChange,
  options,
  selectedContext,
  onSubpropertyChange,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormControl fullWidth>
        <InputLabel>Browse Context</InputLabel>
        <Select
          value={context.value}
          onChange={(e) => onChange(context.id, e.target.value)}
          label="Browse Context"
        >
          {options.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedContext && selectedContext.properties && (
        <>
          <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
            {selectedContext.description}
          </Typography>
          <SubpropertySelector
            subproperties={selectedContext.properties}
            onSubpropertyChange={onSubpropertyChange}
          />
        </>
      )}
    </Box>
  );
};
