import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  NativeSelect,
  Select,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { ExpressionEditor } from './expressionEditor/ExpressionEditor';
import { useFormContext } from 'react-hook-form';
import { typesChains } from './constants';

type FieldRendererProps = {
  parentName: string;
  fields: any;
  isDisabled: boolean;
  register?: any;
};

export const FieldRenderer: React.FC<FieldRendererProps> = ({
  fields,
  isDisabled,
  register,
  parentName,
}) => {
  return (
    <>
      {fields && (
        <Box
          key={fields.name}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            marginBottom: '10px',
          }}
        >
          <label htmlFor={fields.name}>{fields.name}</label>
          {RenderField(fields, isDisabled, register, parentName)}
        </Box>
      )}
    </>
  );
};

const RenderField = (field: any, isDisabled: boolean, register: any, parentName: string) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(field.value);
  const { setValue, getValues } = useFormContext();

  const getFieldValues = (parentName: string) => {
    if (Array.isArray(typesChains)) {
      for (const data of typesChains) {
        if (Array.isArray(data.types)) {
          const type = data.types.find((t) => t.name === parentName);

          if (type && Array.isArray(type.fields)) {
            const values: any[] = [];
            type.fields.forEach((field) => {
              if (Array.isArray(field.value)) {
                values.push(...field.value);
              }
            });
            return values;
          }
        }
      }
    }

    return [];
  };

  switch (field.tagType) {
    case 'input':
      return (
        <>
          <TextField
            type="text"
            id={field.name}
            name={field.name}
            value={!fieldValue ? '' : fieldValue}
            onChange={(e) => {
              const newValue = e.target.value;
              setFieldValue(newValue);
              setValue(field.name, newValue);
            }}
            required={field.required}
            disabled={isDisabled}
          />
          <Button onClick={() => setIsOpen(true)} variant="contained">
            Expression Editor
          </Button>

          <ExpressionEditor
            isOpen={isOpen}
            header={field.name}
            fieldValue={fieldValue}
            onClose={() => setIsOpen(false)}
            onConfirm={(value) => {
              setFieldValue(value);
              setValue(field.name, value);
              setIsOpen(false);
            }}
          />
        </>
      );
    case 'textarea':
      return (
        <>
          <TextareaAutosize
            minRows={3}
            id={field.name}
            name={field.name}
            defaultValue={field.value}
            required={field.required}
            disabled={isDisabled}
          />

          <ExpressionEditor
            isOpen={isOpen}
            header={field.name}
            fieldValue={fieldValue}
            onClose={() => setIsOpen(false)}
            onConfirm={(value) => {
              setFieldValue(value);
              setValue(field.name, value);
              setIsOpen(false);
            }}
          />
        </>
      );
    case 'checkbox':
      return (
        <Checkbox
          id={field.name}
          name={field.name}
          defaultChecked={field.value}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setValue(field.name, isChecked);
          }}
          required={field.required}
          disabled={isDisabled}
        />
      );

    case 'select':
      return (
        <FormControl
          sx={{
            width: '200px',
            border: '1px solid #f4f4f4',
            padding: '11px',
            borderRadius: '4px',
          }}
          variant="standard"
        >
          <Select
            id={field.name}
            name={field.name}
            required={field.required}
            defaultValue={field.value}
            onChange={(e) => {
              const newValue = e.target.value;
              setValue(`${field.name}`, newValue);
            }}
          >
            {getFieldValues(parentName).map((list: any, index: any) => {
              return (
                <MenuItem key={index} value={list}>
                  {list}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );

    default:
      return (
        <TextField
          type="text"
          id={field.name}
          name={field.name}
          disabled={isDisabled}
          defaultValue={field.value}
        />
      );
  }
};
