import { useFormContext } from 'react-hook-form';
import { Autocomplete, Box, Button, Checkbox, TextareaAutosize, TextField } from '@mui/material';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState, useEffect } from 'react';
import { typesChains } from './constants';
import { DeleteIconButton } from '../../../../common/button/iconButton/DeleteIconButton';
import useAutomationScripting from '../../../../../serverInteraction/hooks/automation/automationScripting/useAutomationScripting';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { Loader } from '../../../../common/loader/Loader';
import { FieldRenderer } from './FieldRenderer';
import _ from 'lodash';

export const ChainEditor = () => {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { automationScripting, isFetching, isLoading } = useAutomationScripting(
    userPackage.company,
    userPackage.document,
  );

  const { setValue, watch, register, getValues } = useFormContext();
  const [selectedTypeData, setSelectedTypeData] = useState<any>(null);
  const [selectedSubTypeData, setSelectedSubTypeData] = useState<any>(null);
  const [editAllOperation, setEditAllOperation] = useState<boolean>(true);

  const formFields = watch();

  const droppedItems = watch('droppedItems') || formFields.operations;

  const selectedType = watch('selectedType');
  const selectedSubType = watch('selectedSubType');

  useEffect(() => {
    const typeData = typesChains.find((type: any) => type.name === selectedType);
    setSelectedTypeData(typeData || null);
  }, [selectedType]);

  useEffect(() => {
    setSelectedSubTypeData(selectedSubType || null);
  }, [selectedSubType]);

  useEffect(() => {
    setValue('droppedItems', droppedItems);
  }, [droppedItems, formFields, setValue]);

  if (isLoading || isFetching || automationScripting === undefined) {
    return <Loader />;
  }

  const handleSelectType = (typeName: any) => {
    setValue('selectedType', typeName);
    setValue('selectedSubType', null);

    if (typeName === 'Scripting') {
      setSelectedSubTypeData(automationScripting);
    } else {
      setSelectedSubTypeData(null);
    }
  };

  const handleSelectSubType = (description: any, accepts: any, produces: any, fields: [] = []) => {
    setValue('selectedSubType', { description, accepts, produces, fields });
  };

  const handleAddItem = (subType: any) => {
    const newItem = JSON.parse(
      JSON.stringify({
        typeName: selectedType,
        subTypeName: subType.name || subType.featureId,
        fields: subType.fields || subType.parameters,
      }),
    );
    const updatedItems = [...droppedItems, newItem];
    setValue('droppedItems', updatedItems);
    handleSelectSubType(subType.description, subType.accepts, subType.produces, subType.fields);
  };

  const handleDeleteItem = (index: any) => {
    const updatedItems = droppedItems.filter((_: any, i: any) => i !== index);
    setValue('droppedItems', updatedItems);
  };

  const handleChangeEditingOrReadingAllOperation = (isEdit: boolean) => {
    setEditAllOperation(isEdit);
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const parsedAutomationScripting = JSON.parse(`${automationScripting}`);
    const combinedItems =
      selectedType === 'Scripting'
        ? [...(selectedTypeData?.types || []), ...parsedAutomationScripting]
        : selectedTypeData?.types || [];

    if (source.droppableId === 'droppable-list' && destination.droppableId === 'droppable-target') {
      const draggedItem = JSON.parse(JSON.stringify(combinedItems[source.index]));

      if (draggedItem) {
        const newItem = {
          typeName: selectedType,
          subTypeName: draggedItem.name || draggedItem.featureId,
          fields: draggedItem.fields || draggedItem.parameters || [],
        };

        const updatedItems = [...droppedItems, newItem];
        setValue('droppedItems', updatedItems);
      }
    } else if (
      source.droppableId === 'droppable-target' &&
      destination.droppableId === 'droppable-target'
    ) {
      const reorderedItems = JSON.parse(JSON.stringify(droppedItems));
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);
      setValue('droppedItems', reorderedItems);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <SimpleAccordion title={`${t('chainDocumentationField')}`} defaultExpanded={false}>
        <TextareaAutosize
          {...register('documentation', {})}
          defaultValue={formFields.documentation}
          minRows={2}
          style={{
            width: '100%',
            border: '1px solid #f3f3f3',
            padding: '5px',
            alignContent: 'center',
          }}
        />
      </SimpleAccordion>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { xs: '1fr', sm: '200px 1fr 1fr' },
        }}
      >
        <Box>
          {typesChains.map((type: any, index: any) => (
            <Box
              key={type.name}
              onClick={() => handleSelectType(type.name)}
              sx={{
                cursor: 'pointer',
                marginBottom: '10px',
                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                padding: '5px',
                borderRadius: '4px',
                '&:hover': { transform: 'scale(1.1)' },
              }}
            >
              {type.name}
            </Box>
          ))}
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list" isDropDisabled={true}>
            {(provided: any) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  maxHeight: '515px',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#d0d0d0',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#b0b0b0',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f3f3f3',
                  },
                }}
              >
                {selectedTypeData ? (
                  <>
                    {selectedTypeData.types.map((subType: any, index: any) => (
                      <Draggable
                        key={subType.name}
                        draggableId={`list-item-${subType.name}`}
                        index={index}
                      >
                        {(provided: any) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                              padding: '5px',
                              borderRadius: '4px',
                            }}
                          >
                            <Box
                              onClick={() =>
                                handleSelectSubType(
                                  subType.description,
                                  subType.accepts,
                                  subType.produces,
                                  subType.fields,
                                )
                              }
                            >
                              <strong>{subType.name}</strong>
                            </Box>
                            <Button variant="contained" onClick={() => handleAddItem(subType)}>
                              Add
                            </Button>
                          </Box>
                        )}
                      </Draggable>
                    ))}

                    {selectedType === 'Scripting' &&
                      JSON.parse(`${automationScripting}`).map((script: any, index: any) => (
                        <Draggable
                          key={script.featureId}
                          draggableId={`list-item-script-${script.featureId}`}
                          index={selectedTypeData.types.length + index}
                        >
                          {(provided: any) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                                padding: '5px',
                                borderRadius: '4px',
                              }}
                            >
                              <Box
                                onClick={() =>
                                  handleSelectSubType(
                                    script.description,
                                    script.inputType.toLowerCase(),
                                    script.outputType.toLowerCase(),
                                    script.parameters,
                                  )
                                }
                              >
                                <strong>{script.featureId}</strong>
                              </Box>
                              <Button variant="contained" onClick={() => handleAddItem(script)}>
                                Add
                              </Button>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                  </>
                ) : (
                  <Box>Select a constant to see the types</Box>
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>

          <Droppable droppableId="droppable-target">
            {(provided: any) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  border: '1px solid #d0d0d0',
                  padding: '10px',
                  minHeight: '100px',
                  maxHeight: '580px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  marginTop: '10px',
                  borderRadius: '4px',
                  backgroundColor: '#fafafa',
                  color: 'var(--main-black)',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#d0d0d0',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#b0b0b0',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f3f3f3',
                  },
                }}
              >
                <Box>
                  The context document represents either the current document in the user interface
                  or the target document of a repository event.
                </Box>

                <Button
                  onClick={() => handleChangeEditingOrReadingAllOperation(!editAllOperation)}
                  variant="outlined"
                  sx={{ my: 1 }}
                >
                  {editAllOperation ? 'Read all operation' : 'Edit all operation'}
                </Button>

                {droppedItems.map((item: any, index: any) => (
                  <Draggable
                    key={`${item.typeName}-${item.subTypeName}-${index}`}
                    draggableId={`target-item-${item.typeName}-${item.subTypeName}-${index}`}
                    index={index}
                  >
                    {(provided: any) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          padding: '15px',
                          marginBottom: '5px',
                          backgroundColor: '#fff',
                          border: '1px solid #e0e0e0',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box>
                          <strong>
                            {item.typeName
                              ? `${item.typeName} // ${item.subTypeName}`
                              : `${item.category} // ${item.name}`}
                          </strong>
                          {item.params
                            ? item.params?.map((operation: any, opIndex: any) => (
                                <Box key={`operation-${opIndex}`} sx={{ marginTop: '10px' }}>
                                  <Box
                                    key={operation.name}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '15px',
                                      marginBottom: '10px',
                                    }}
                                  >
                                    <FieldRenderer
                                      parentName={item.name}
                                      fields={operation}
                                      isDisabled={!editAllOperation}
                                    />
                                  </Box>
                                </Box>
                              ))
                            : item.fields?.map((field: any) => (
                                <Box
                                  key={field.name}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px',
                                    marginBottom: '10px',
                                  }}
                                >
                                  <FieldRenderer
                                    fields={field}
                                    isDisabled={!editAllOperation}
                                    parentName={item.subTypeName}
                                  />
                                </Box>
                              ))}
                        </Box>
                        <DeleteIconButton
                          style={{
                            position: 'absolute',
                            top: '15px',
                            right: '15px',
                          }}
                          onClick={() => handleDeleteItem(index)}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box
          sx={{
            padding: '10px',
            border: '1px solid #f3f3f3',
            borderRadius: '4px',
            gridColumn: '1 / 3',
          }}
        >
          {selectedSubTypeData ? (
            <>
              <p>
                <strong>Description:</strong> {selectedSubTypeData.description}
              </p>
              <p>
                <strong>Accepts:</strong> {selectedSubTypeData.accepts}
              </p>
              <p>
                <strong>Produces:</strong> {selectedSubTypeData.produces}
              </p>
            </>
          ) : (
            <p>Select a subtype to see the details</p>
          )}
        </Box>
      </Box>
    </Box>
  );
};
