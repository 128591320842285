import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Transition {
  name: string;
  condition: string;
  automation: string;
}

interface NodeData {
  outputsNames?: string[];
  transitions?: Transition[];
  outputs?: number;
}

interface TransitionsTabProps {
  data: {
    data: NodeData;
  };
  onChange: (key: string, value: any) => void; // Для передачи изменений в родительский компонент
}

const TransitionsTab: React.FC<TransitionsTabProps> = ({ data, onChange }) => {
  const [transitions, setTransitions] = useState<Transition[]>(data.data.transitions || []);
  const [followFirstTrue, setFollowFirstTrue] = useState(false);
  const [outputs, setOutputs] = useState(data.data.outputs);

  // Передача обновленных transitions в родительский компонент при изменении
  useEffect(() => {
    onChange('transitions', transitions);
  }, [transitions, onChange]);

  useEffect(() => {
    onChange('followFirstTrue', followFirstTrue);
  }, [followFirstTrue, onChange]);

  useEffect(() => {
    if (outputs !== undefined) {
      onChange('outputs', outputs);
    }
  }, [outputs, onChange]);

  // Инициализация transitions только при первом рендере
  useEffect(() => {
    if (!data.data.transitions || data.data.transitions.length === 0) {
      if (data.data.outputsNames && data.data.outputsNames.length > 0) {
        const initialTransitions = data.data.outputsNames.map((outputName) => ({
          name: outputName,
          condition: 'true',
          automation: '',
        }));
        setTransitions(initialTransitions);
      }
    }
  }, [data.data.outputsNames, data.data.transitions]);

  const handleAddTransition = () => {
    const newOutputs = outputs ? outputs + 1 : 1;
    const newTransition = { name: '', condition: 'true', automation: '' };
    const updatedTransitions = [...transitions, newTransition];
    setTransitions(updatedTransitions);
    setOutputs(newOutputs);

    // Обновляем данные узла
    onChange('outputs', newOutputs);
    onChange('transitions', updatedTransitions);
  };

  const handleChange = (index: number, key: keyof Transition, value: string) => {
    const updatedTransitions = [...transitions];
    updatedTransitions[index][key] = value;
    setTransitions(updatedTransitions);
  };

  const handleDelete = (index: number) => {
    setOutputs((prevOutputs) => (prevOutputs ? prevOutputs - 1 : prevOutputs));
    const updatedTransitions = transitions.filter((_, i) => i !== index);
    setTransitions(updatedTransitions);
  };

  const handleEdit = (index: number) => {
    alert(`Edit automation for transition ${index + 1}`);
  };

  const handleCreate = (index: number) => {
    alert(`Create automation for transition ${index + 1}`);
  };

  return (
    <Box>
      {/* Чекбокс для Follow Only First True Transition */}
      <FormControlLabel
        control={
          <Checkbox
            checked={followFirstTrue}
            onChange={() => setFollowFirstTrue(!followFirstTrue)}
          />
        }
        label="Follow only first true transition"
      />

      {/* Кнопка для добавления перехода */}
      <Box mt={2} mb={2}>
        <Button variant="outlined" onClick={handleAddTransition}>
          + Add Transition
        </Button>
      </Box>

      {/* Таблица для списка переходов */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell>Automation</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transitions.map((transition, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={transition.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                  placeholder="Enter name"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={transition.condition}
                  onChange={(e) => handleChange(index, 'condition', e.target.value)}
                  placeholder="Enter condition"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  {/* Выпадающий список */}
                  <Select
                    value={transition.automation}
                    onChange={(e) => handleChange(index, 'automation', e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Automation
                    </MenuItem>
                    <MenuItem value="Option 1">Option 1</MenuItem>
                    <MenuItem value="Option 2">Option 2</MenuItem>
                  </Select>
                  {/* Кнопки */}
                  <Button variant="outlined" color="primary" onClick={() => handleEdit(index)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={() => handleCreate(index)}>
                    Create
                  </Button>
                </Box>
              </TableCell>
              <TableCell>
                {/* Кнопка удаления */}
                <IconButton color="error" onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TransitionsTab;
