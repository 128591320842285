import { useForm } from "react-hook-form";
import useCreate from "../../../../../serverInteraction/hooks/entity/useCreate";
import { CreateLifeCyclesProps } from "../../../../../types/content/life-cycles/CreateLifeCyclesProps";
import { LifeCycleProps } from "../../../../../types/content/life-cycles/LifeCycleProps";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../common/grid/GridOneToThree";
import { ControlledInput } from "../../../../common/input/ControlledInput";
import { AddDialog } from "../../../../common/dialog/AddDialog";
import { Box } from "@mui/material";
import useLifeCycles from "../../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import { useSelector } from "react-redux";
import { LIFECYCLES_ENDPOINT } from "../../../../../serverInteraction/services/endpointsConstants";

export const LifeCyclesCreateDialog = () => {
  const { create } = useCreate<CreateLifeCyclesProps, LifeCycleProps>(
    LIFECYCLES_ENDPOINT
  );
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { refetch } = useLifeCycles(userPackage.company, userPackage.document);

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<CreateLifeCyclesProps>();

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("featureId", {
              required: {
                value: true,
                message: "ID is required",
              },
              minLength: {
                value: 3,
                message: "Must be at least 3 symbols",
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
  ];

  const onSubmit = async (newLifeCycle: CreateLifeCyclesProps) => {
    const lifeCyclesCreateData = {
      ...newLifeCycle,
      company: userPackage.company,
      document: userPackage.document,
      boxes: new Array(),
      connections: new Array(),
    };

    await create(lifeCyclesCreateData);
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Life-Cycle"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
};
