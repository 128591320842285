import { useTranslation } from 'react-i18next';
import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { SchemasView } from './SchemasPageList';

export function SchemasPage() {
  const { t } = useTranslation();
  
  return (
    <Page>
      <PageHeader title={t('titleSchemas')} />
      <PageContent subtitle={t('headerSchemas')}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <SchemasView />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
