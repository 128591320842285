import { ExpressionItem } from '../iProps';

export const processExpressionEditorConstants = (
  constants: ExpressionItem[],
  parentProperties: ExpressionItem[] = [],
): ExpressionItem[] => {
  return constants.map((item) => {
    if (item.parent && !item.subproperties && parentProperties.length > 0) {
      return {
        ...item,
      };
    }

    if (item.properties) {
      return {
        ...item,
        properties: processExpressionEditorConstants(item.properties, item.properties),
      };
    }

    return item;
  });
};
