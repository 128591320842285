import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { LifeCycleProps } from '../../../../../types/content/life-cycles/LifeCycleProps';
import { LIFECYCLES_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../../types/packages/PackageProps';

type Props = {
  lifeCycle: LifeCycleProps;
  onDelete?: () => void;
};

export function LifeCyclesDeleteDialog({ lifeCycle, onDelete }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess('Deleted successfully!');
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove(LIFECYCLES_ENDPOINT, onSuccess);
  const handleDelete = async () => {
    const lifeCycleForDelete = {
      company: userPackage.company,
      document: userPackage.document,
      featureId: lifeCycle.featureId,
      commitMessage: `Delete ${lifeCycle.featureId}`,
    };
    await remove(lifeCycleForDelete);
  };

  return (
    <DeleteDialog title={`Delete life cycle ${lifeCycle.featureId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${lifeCycle.featureId}?`}</Box>
    </DeleteDialog>
  );
}
