import { useTranslation } from "react-i18next";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';

type Props = {
  title?: string;
} & IconButtonProps;

export function UpdateIconButton({ title, ...props }: Props) {
  const  { t } = useTranslation();

  return (
    <Tooltip title={title || t("update")}>
      <IconButton aria-label="Update" {...props}>
        <UpdateIcon />
      </IconButton>
    </Tooltip>
  );
}
