import { PropsWithChildren, useState } from "react";
import { SimpleModal } from "../modal/SimpleModal";
import { Stack, Button, BoxProps, Box } from "@mui/material";
import { DeleteIconButton } from "../button/iconButton/DeleteIconButton";

type Props = {
  title: string;
  remove: () => Promise<void>;
} & BoxProps &
  PropsWithChildren;

export function DeleteDialog({ title, remove, children, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    setIsOpen(false);
    await remove();
  };

  return (
    <div>
      <DeleteIconButton title={title} onClick={() => setIsOpen(true)} size="small" />
      <SimpleModal
        title={title}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      >
        <Box {...props}>
          {children}
          <Stack
            direction="row-reverse"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button
              variant="contained"
              onClick={handleDelete}
              sx={{ width: 150 }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsOpen(false)}
              sx={{ width: 150 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </div>
  );
}
