import { useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { SimpleAccordion } from "../../../../../common/accordion/SimpleAccordion";
import { TransferList } from "../../../../../common/transfetList/TransferList";
import { DefaultSchemasForDocument } from "../../../../../../enum/DefaultSchemasForDocument";
import { SchemaForm } from "./SchemaForm";
import FieldAdvancedSettingsV1 from "../../../schemas/SchemaPage/FieldAdvancedSettingsV1/FieldAdvancedSettingsV1";
import { SchemaWithFieldsPropsUpdate } from "../../../../../../types/content/schemas/SchemaWithFieldsProps";
import SubFieldAdvancedSettingsV1 from "../../../schemas/SchemaPage/SubFieldAdvancedSettingsV1/SubFieldAdvancedSettingsV1";


interface IProps {
  extendType: string,
  inheritedSchemas: string,
  schemasBroughtByFacets: string,
  schemasList: string[],
  initialCustomSchema: SchemaWithFieldsPropsUpdate,
  control: any,
  register: any,
  errors: any,
  getValues: any,
  setValue: any,
  watch: any
}

const SchemaTab = ({extendType, schemasList, inheritedSchemas, schemasBroughtByFacets, initialCustomSchema, control, register, errors, getValues, setValue, watch}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const fieldSearchParam = searchParams.get("field") || null;
  const subfieldSearchParam = searchParams.get("subfield") || null;
  const loadedSchemas = getValues("schemas");
  const schemasFullList: string[] = DefaultSchemasForDocument.concat(schemasList).sort();
    
  const updateSchemas = (newList: any) => {
    setValue("schemas", newList)
  };

  const navToSchema = ()=> {
    setSearchParams({tab: "1"});
  };

  //Проверяем параметры адресной строки и (во избежание ошибки) наличие данных
  if (fieldSearchParam && subfieldSearchParam && getValues(`fields.${fieldSearchParam}.subFields.${subfieldSearchParam}`)) {
    return (
      <SubFieldAdvancedSettingsV1 
        index={Number(fieldSearchParam)} 
        subindex = {Number(subfieldSearchParam)} 
        control={control} 
        register={register} 
        errors={errors} 
        getValues={getValues} 
        setValue={setValue} 
        watch={watch}/>
    )
  }

  if (fieldSearchParam && getValues(`fields.${fieldSearchParam}`)) {
    return (
      <FieldAdvancedSettingsV1 
        index={Number(fieldSearchParam)}
        schemaName={getValues("customSchema.featureId")}
        fieldName={getValues(`customSchema.fields.${fieldSearchParam}.name`)}
        namePrefix={`customSchema.fields.${fieldSearchParam}`}
        errorsObj={errors.customSchema?.fields?.[Number(fieldSearchParam)]}
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        onSchemaClick={navToSchema} 
        watch={watch}
         />
    )
  }

  return (
    <>
      <Box>
        <Stack direction="row" gap="10px">
          <Typography >
            This document inherits from:
          </Typography>
            <Typography fontWeight="bold">
              {extendType}
          </Typography>
        </Stack>
        <Stack direction="row" gap="10px">
          <Typography >
            Inherited schemas:
          </Typography>
          <Typography fontWeight="bold">
            {inheritedSchemas}
          </Typography>
        </Stack>
        <Stack direction="row" gap="10px">
          <Typography >
            Schemas brought by facets:
          </Typography>
            <Typography fontWeight="bold">
              {schemasBroughtByFacets}
            </Typography>
        </Stack>
      </Box>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <SimpleAccordion
                title="Add extra schemas to this document type"
                sx={{ mt: 3 }}
                defaultExpanded={true}
              >
                <TransferList
                  entityList={schemasFullList}
                  updateParent={updateSchemas}
                  loadEntities={loadedSchemas}
                />
              </SimpleAccordion>
      </div>
      <SchemaForm 
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        setValue={setValue} 
        watch={watch}
      /> 
    </>
  ) 
};

export default SchemaTab;