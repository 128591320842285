import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

// const apiClient = axios.create({
//   baseURL: "http://localhost:3001/",
//   headers: {
//     "Content-type": "application/json",
//   },
// });
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'stream',
  headers: {
    'Content-type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
  // withCredentials: true,
});

const Get = async <T>(url: string, axiosConfig?: AxiosRequestConfig) => {
  const response = await apiClient.get<T>(url, axiosConfig);
  return response.data;
};

const List = async <T>(url: string, parameters?: any) => {
  const bakedUrl = parameters ? url + '?' + queryString.stringify(parameters) : url;
  const response = await apiClient.get<T>(bakedUrl);
  return response.data;
};

const Post = async <T>(url: string, item: any, config?: any) => {
  const response = await apiClient.post<T>(url, item, config);
  return response.data;
};

const Put = async <T>(url: string, item: any) => {
  const response = await apiClient.put<T>(url, item);
  return response.data;
};

const Patch = async <T>(url: string, item: any) => {
  const response = await apiClient.patch<T>(url, item);
  return response.data;
};

const Delete = async <T>(url: string, item: any) => {
  const response = await apiClient.delete<T>(url, {data: item});
  return response.data;
};

const BaseServerInteractionService = {
  Get,
  List,
  Post,
  Put,
  Patch,
  Delete,
};

export default BaseServerInteractionService;
