import { useContext } from 'react';
import { ConfirmCtx } from './NavigationConfirmProvider';


export const useNavigationConfirm = () => {
  const context = useContext(ConfirmCtx);

  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }

  return context;
};