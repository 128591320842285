import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AddButton } from "../../../../../../../../common/button/AddButton";
import { EditAclDialog } from "../EditAclDialog/EditAclDialog";
import { defaultAcl } from "../EditAclDialog/defaultValues";

interface IProps {
  append: (obj: object | object[]) => void;
  update: (index: number, obj: object) => void;
  remove: (index?: number | number[]) => void;
  addedIndex: number;
  namePrefix: string;
  control: any;
  setValue: any;
  getValues: any;
  register: any;
  trigger: any;
  errors: any;
}
export function CreateAclDialog({
  append,
  update,
  remove,
  addedIndex,
  namePrefix,
  control,
  setValue,
  getValues,
  register,
  trigger,
  errors,
}: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [aclIndex, setAclIndex] = useState<number | undefined>(undefined);

  const handleAdd = () => {
    append(defaultAcl);
    setAclIndex(addedIndex);
    setIsOpen(true);
  };

  const handleConfirm = () => {
    setAclIndex(undefined);
    setIsOpen(false);
  };

  const handleCancel = () => {
    if (aclIndex !== undefined) remove(aclIndex);
    setAclIndex(undefined);
    setIsOpen(false);
  };

  // RENDER

  return (
    <div>
      <AddButton onClick={handleAdd}>{t("add")}</AddButton>
      {aclIndex !== undefined && (
        <EditAclDialog
          mode="CREATE"
          isOpen={isOpen}
          aclIndex={aclIndex}
          namePrefix={namePrefix}
          initialValue={defaultAcl}
          update={update}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          trigger={trigger}
          errors={errors}
        />
      )}
    </div>
  );
}
