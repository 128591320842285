import { ECreateSchemaMode } from "../../../../../enum/ECreateSchemaMode";

export const modeItems = [
    {
      value: ECreateSchemaMode.DEFAULT,
      item: 'Default',
      helperText: 'Create an empty schema where you can add fields',
      helperDescription: ''
    },
    {
      value: ECreateSchemaMode.INITIALIZE,
      item: 'Initialize',
      helperText: 'Initialize the schema from an existing XSD file',
      helperDescription: 'the file will be generated again based on Studio configuration'
    },
    {
      value: ECreateSchemaMode.IMPORT,
      item: 'Import',
      helperText: 'Import an existing XSD file',
      helperDescription: 'the resulting Nuxeo schema will be readonly and the XSD file will be deployed as is into the Nuxeo Repository'
    },
    {
      value: ECreateSchemaMode.REFERENCE,
      item: 'Reference',
      helperText: 'Reference an XSD file',
      helperDescription: "make Studio aware of the corresponding schema, but don't deploy it into the Nuxeo Repository"
    },
  ];

