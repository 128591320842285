import { v4 as uuidv4 } from 'uuid';

import React, { useState } from 'react';
import {
  Node,
  Edge,
  Connection,
  NodeChange,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  EdgeChange,
  OnEdgesChange,
  Position,
} from 'react-flow-renderer';
import FlowChart from './FlowChart';
import SidebarPanel from './SidebarPanel';
import ErrorMessage from '../../CustomErrorMessage';
import EditNodePropertiesModal from './editPropertiesModal/EditNodePropertiesModalView';
import { useFormContext } from 'react-hook-form';

interface GraphViewProps {
  nodes: Node[];
  edges: Edge[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
}

const GraphView: React.FC<GraphViewProps> = ({ nodes, edges, setNodes, setEdges }) => {
  const [selectedElement, setSelectedElement] = useState<Node | Edge | null>(null);
  const [newLabel, setNewLabel] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entity, setEntity] = useState<any>();

  const onNodesChange = (changes: NodeChange[]) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  };

  const onEdgesChange: OnEdgesChange = (changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };

  const updateNode = (nodeId: string, updatedData: any) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                ...updatedData.data, // Обновляем данные узла
              },
            }
          : node,
      ),
    );
  };

  const onConnect = (params: Connection) => {
    setError(null);

    const sourceNode = nodes.find((node) => node.id === params.source);
    const targetNode = nodes.find((node) => node.id === params.target);

    if (!sourceNode || !targetNode) {
      setError('Source or target node not found.');
      return;
    }

    // Проверяем, запрещено ли соединение от input
    if (params.sourceHandle?.startsWith('input')) {
      setError('Connections from inputs are not allowed.');
      return;
    }

    // Проверяем, можно ли добавить связь от конкретного sourceHandle
    if (params.sourceHandle?.startsWith('output')) {
      const existingSourceConnections = edges.filter(
        (edge) => edge.source === params.source && edge.sourceHandle === params.sourceHandle,
      );
      if (existingSourceConnections.length > 0) {
        setError('This output already has a connection.');
        return;
      }
    }

    // Определяем дефолтное название связи
    let defaultLabel = ''; // Общее название по умолчанию
    if (sourceNode.data.label) {
      switch (sourceNode.data.label) {
        case 'Accept/Reject':
          defaultLabel = params.sourceHandle === 'output-1' ? 'validate' : 'reject';
          break;
        case 'Approve':
          defaultLabel = 'approve';
          break;
        case 'Multiple Tasks':
          defaultLabel = 'approve';
          break;
        case 'Fork 2 Ways':
          defaultLabel =
            params.sourceHandle === 'output-0'
              ? 'trans1'
              : params.sourceHandle === 'output-1'
              ? 'trans2'
              : 'transition';
          break;
        case 'Fork 3 Ways':
          defaultLabel =
            params.sourceHandle === 'output-0'
              ? 'trans1'
              : params.sourceHandle === 'output-1'
              ? 'trans2'
              : params.sourceHandle === 'output-2'
              ? 'trans3'
              : '';
          break;
        case 'Merge':
          defaultLabel = 'after-merge';
          break;
        case 'Start':
          defaultLabel = 'automatic_transition';
          break;
        case 'Sub Workflow':
          defaultLabel = 'transition';
          break;
        default:
          defaultLabel = '';
          break;
      }
    }

    // Добавляем новую связь с дефолтным названием
    const newEdge: Edge = {
      // id: `edge-${params.source}-${params.target}`,
      id: `edge-${uuidv4()}`,
      source: params.source || '',
      target: params.target || '',
      sourceHandle: params.sourceHandle || '',
      targetHandle: params.targetHandle || '',
      label: defaultLabel,
    };

    // Добавляем новую связь
    setEdges((eds) => [...eds, newEdge]);
  };

  const addNode = (type: string) => {
    const id = (nodes.length + 1).toString();
    let newNode: Node;

    switch (type) {
      case 'node':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Node',
            inputs: 1,
            outputs: 0,
            outputsNames: [], // Нет выходов, массив пуст
            bgc: 'lightblue',
            parentType: 'AUTOMATED_TASK',
            subtype: 'NODE',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'acceptReject':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Accept/Reject',
            inputs: 1,
            outputs: 2,
            outputsNames: ['validate', 'reject'], // Имена выходов
            bgc: 'green',
            parentType: 'USER_TASKS',
            subtype: 'ACCEPT_REJECT',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'approve':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Approve',
            inputs: 1,
            outputs: 1,
            outputsNames: ['approve'], // Один выход
            bgc: 'violet',
            parentType: 'USER_TASKS',
            subtype: 'APPROVE',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'multipletasks':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Multiple Tasks',
            inputs: 1,
            outputs: 2,
            outputsNames: ['task1', 'task2'], // Два выхода
            bgc: 'lightcoral',
            parentType: 'USER_TASKS',
            subtype: 'MULTIPLE_TASKS',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'simpletask':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Simple Task',
            inputs: 1,
            outputs: 0,
            outputsNames: [], // Нет выходов
            bgc: 'orange',
            parentType: 'USER_TASKS',
            subtype: 'SIMPLE_TASK',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'fork2ways':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Fork 2 Ways',
            inputs: 0,
            outputs: 2,
            outputsNames: ['trans1', 'trans2'], // Два выхода
            bgc: 'gray',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'FORK_2WAYS',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'fork3ways':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Fork 3 Ways',
            inputs: 0,
            outputs: 3,
            outputsNames: ['trans1', 'trans2', 'trans3'], // Три выхода
            bgc: 'gray',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'FORK_3WAYS',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'merge':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Merge',
            inputs: 1,
            outputs: 1,
            outputsNames: ['after-merge'], // Один выход
            bgc: 'tomato',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'MERGE',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'start':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Start',
            inputs: 0,
            outputs: 1,
            outputsNames: ['automatic_transition'], // Один выход
            bgc: 'red',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'START',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'stop':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Stop',
            inputs: 1,
            outputs: 0,
            outputsNames: [], // Нет выходов
            bgc: 'blue',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'STOP',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      case 'subworkflow':
        newNode = {
          id,
          type: 'customNode',
          data: {
            label: 'Sub Workflow',
            inputs: 1,
            outputs: 1,
            outputsNames: ['transition'], // Один выход
            bgc: 'brown',
            parentType: 'STRUCTURAL_NODE',
            subtype: 'SUB_WORKFLOW',
          },
          position: { x: Math.random() * 500, y: Math.random() * 500 },
        };
        break;

      default:
        return;
    }

    setNodes((nds) => [...nds, newNode]);
  };
  const handleNodeDoubleClick = (node: Node) => {
    setIsModalOpen(!isModalOpen);
    setEntity(node);
  };

  const handleEdgeDoubleClick = (edge: Edge) => {
    setSelectedElement(edge);
    setNewLabel(edge.label ? String(edge.label) : '');
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(e.target.value);
  };

  const handleLabelSubmit = () => {
    if (selectedElement) {
      if ('data' in selectedElement) {
        setNodes((nds) =>
          nds.map((node) =>
            node.id === selectedElement.id
              ? { ...node, data: { ...node.data, label: newLabel } }
              : node,
          ),
        );
      } else if ('label' in selectedElement) {
        setEdges((eds) =>
          eds.map((edge) => (edge.id === selectedElement.id ? { ...edge, label: newLabel } : edge)),
        );
      }
    }
    setSelectedElement(null);
    setNewLabel('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalSave = (updatedNode: any) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === updatedNode.id ? updatedNode : node)),
    );
    setIsModalOpen(false);
  };

  const updateEdge = (sourceId: string, label: string) => {
    const newEdge: Edge = {
      id: `edge-${uuidv4()}`, // Генерируем уникальный ID
      source: sourceId, // ID узла-источника
      target: '', // Укажите целевой узел, если известен (или оставьте пустым)
      label, // Имя транзишна становится лейблом
      sourceHandle: `output-${label}`, // Привязываем к соответствующему выходу
      type: 'default', // Тип связи
    };

    setEdges((prevEdges) => [...prevEdges, newEdge]); // Обновляем состояние связей
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {error && <ErrorMessage message={error} />}
      <SidebarPanel onAddNode={addNode} />
      <FlowChart
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeDoubleClick={handleNodeDoubleClick}
        onEdgeDoubleClick={handleEdgeDoubleClick}
      />
      {isModalOpen && (
        <EditNodePropertiesModal
          onSave={handleModalSave}
          data={entity}
          onClose={handleCloseModal}
          updateNode={updateNode}
        />
      )}
      {selectedElement && (
        <div>
          <h4>Rename {selectedElement.id}</h4>
          <input type="text" value={newLabel} onChange={handleLabelChange} />
          <button onClick={handleLabelSubmit}>Save</button>
        </div>
      )}
    </div>
  );
};

export default GraphView;
