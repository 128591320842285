import { useParams } from "react-router-dom";
import { Loader } from "../../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Typography } from "@mui/material";
import { Page } from "../../../../common/page/Page";
import { PageHeader } from "../../../../common/page/PageHeader";
import { PageContent } from "../../../../common/page/PageContent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import useStructureTemplate from "../../../../../serverInteraction/hooks/content/structureTemplates/useStructureTemplate";
import { convertStructureTemplateString } from "../../../../../utils/structureTemplateUtils";
import { Subtitle } from "./Subtitle";
import { StructureTemplateView } from "./StructureTemplateView/StructureTemplateView";
import useDocTypes from "../../../../../serverInteraction/hooks/content/document-types/useDocTypes";

type Params = {
  id: string;
};

export function StructureTemplatePage() {
  const { t } = useTranslation();
  const { id } = useParams<Params>();

  const {
    status,
    structureTemplate: structureTemplateWithJson,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useStructureTemplate(`${id}`);

  const { documentTypes, isLoading: documentTypesLoading } = useDocTypes();

  if (
    isLoading ||
    isFetching ||
    structureTemplateWithJson === undefined ||
    documentTypesLoading ||
    documentTypes === undefined
  ) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const structureTemplate = convertStructureTemplateString(
    structureTemplateWithJson.toString()
  );

  const customDoctypes = JSON.parse(`${documentTypes}`).map(
    (documentType: any) => documentType.featureId
  );

  //RENDERING

  return (
    <Page>
      <PageHeader
        title={`${t("structureTemplate")} ${t("for")} ${
          structureTemplate.targetDocType
        }`}
      ></PageHeader>
      <PageContent subtitle={<Subtitle />}>
        <StructureTemplateView
          structureTemplate={structureTemplate}
          customDoctypes={customDoctypes || []}
          refetch={refetch}
        />
      </PageContent>
    </Page>
  );
}
