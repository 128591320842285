import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useLifeCycles from "../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import LifeCycleEditor from "./LifeCycleEditor";
import { PackageProps } from "../../../../types/packages/PackageProps";
import useUpdate from "../../../../serverInteraction/hooks/entity/useUpdate";
import { LIFECYCLES_ENDPOINT } from "../../../../serverInteraction/services/endpointsConstants";

const LifeCyclePage = () => {
  const { id } = useParams();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { status, isLoading, isFetching, error, lifeCycles } = useLifeCycles(
    userPackage.company,
    userPackage.document,
    id
  );
  const { update } = useUpdate(LIFECYCLES_ENDPOINT);

  const wrappedUpdate = async (id: string, data: any): Promise<void> => {
    try {
      await update(id, data);
    } catch (error) {
      console.error("Ошибка при обновлении:", error);
    }
  };

  if (isLoading || isFetching || !lifeCycles) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Ошибка: {error.message}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        marginTop: "73px",
        padding: "10px",
      }}
    >
      <LifeCycleEditor
        lifeCycles={lifeCycles}
        userPackage={userPackage}
        update={wrappedUpdate}
        featureId={id}
      />
    </Box>
  );
};

export default LifeCyclePage;
