import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputBase, Stack} from '@mui/material';
import { PackageProps } from '../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { CONTENT_ENDPOINT } from '../../../serverInteraction/services/endpointsConstants';
import useCreate from '../../../serverInteraction/hooks/entity/useCreate';
import { Loader } from '../loader/Loader';
import BaseServerInteractionService from '../../../serverInteraction/services/BaseServerInteractionService';
import { AxiosError } from 'axios';

type Props = {
  title: string,
  variant: "ICON" | "XSD",
  handleChangeFile: (newFileName: string | undefined) => void;
};

export function UploadDialog({ title, variant, handleChangeFile, ...props }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCommit = async () => {
    //#TODO Ждем бэкенд. Пока закидываем в doctype название. Договоренность в telegram backend 06.09.2024 
    if (file && variant === 'ICON') {
      console.log('Uploading file...');
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('company', userPackage.company);
      formData.append('document', userPackage.document);
      
      try {
        setIsProcessing(true);
        const contentEndpoint = `${process.env.REACT_APP_API_URL}${CONTENT_ENDPOINT}`;
        const response = await fetch(contentEndpoint, {
          method: 'POST',
          body: formData,
        })
        if(response?.ok) {
          response.text().then(text => handleChangeFile(text));
        } else {
          throw new Error()
        }
        
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          showError(error.message);
        } else {
          showError('Loading error')
        }
        handleChangeFile(undefined);
      } finally {
        setIsProcessing(false);
      }

    } else {//XSD
      handleChangeFile(file?.name);
    }
    setOpen(false);
  };

  const handleClose = async () => {
    /*if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const d = 1;
      }
    handleChangeIconFile(file);*/
    if (file) {
      console.log('Uploading file...');
  
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        // You can write the URL of your server or any other endpoint used for file upload
        const result = await fetch('https://api.ruxeo.ru/content/upload?document=document4&company=company4', {
          method: 'POST',
          body: formData,
        });
  
        const data = await result.json();
  
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  }

  if (isProcessing)
    return <Loader/>
    
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Select Resource
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            <InputBase
              type="file"
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleCommit} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function showError(message: string) {
  throw new Error('Function not implemented.');
}

