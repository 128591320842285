import {
  Drawer,
  Toolbar,
  List,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { SidebarItem } from "./SidebarItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadValidationErrors } from "../../store/validationErrors/validationErrorsSlice";
import { drawerWidth, lists } from "./constants";
import SidebarItemTitle from "./SidebarItemTitle";
import { ValidationErrorProps } from "../../types/validation-errors/ValidationErrorProps";
import usePackageEntities from "../../serverInteraction/hooks/packages/usePackageEntities";
import useLoader from "../../context/loaderContext/useLoader";
import { ChevronLeft, ChevronRight } from "@mui/icons-material"; // Иконки стрелок

export function Sidebar() {
  const dispatch = useDispatch();
  const { setIsLoaderOpen } = useLoader();
  const validationErrors: ValidationErrorProps[] = useSelector(
    (state: any) => state.validationErrors.errors
  );
  const { data: packageEntitiesGet, isLoading } = usePackageEntities();

  const [isCollapsed, setIsCollapsed] = useState(false);

  //EFFECTS
  useEffect(() => {
    if (packageEntitiesGet) {
      const packageEntities = JSON.parse(packageEntitiesGet?.toString());
      dispatch(loadValidationErrors({ packageEntities: packageEntities }));
    }
  }, [packageEntitiesGet]);

  useEffect(() => {
    setIsLoaderOpen(isLoading ? true : false);
  }, [isLoading]);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: isCollapsed ? 0 : drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isCollapsed ? 0 : drawerWidth,
            boxSizing: "border-box",
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
        open={!isCollapsed}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto", pb: 3 }}>
          <Stack>
            {lists.map((list) => (
              <Box key={list.label}>
                <Typography variant="h6" sx={{ pl: "30px", py: 2 }}>
                  {list.label.toUpperCase()}
                </Typography>
                <List disablePadding>
                  {list.items.map((item) => (
                    <SidebarItem
                      key={item.link}
                      label={
                        <SidebarItemTitle
                          label={item.label}
                          countErrors={
                            validationErrors.filter(
                              (elem) => elem.chapter === item.link
                            ).length
                          }
                        />
                      }
                      link={item.link}
                      children={item.children}
                    />
                  ))}
                </List>
              </Box>
            ))}
          </Stack>
        </Box>
      </Drawer>

      <IconButton
        onClick={handleToggleSidebar}
        sx={{
          position: "fixed",
          top: isCollapsed ? "95%" : 75,
          left: isCollapsed ? 10 : drawerWidth - 60,
          zIndex: "10000",
          backgroundColor: "#fff",
          boxShadow: 1,
        }}
        className="shit"
      >
        {isCollapsed ? <ChevronRight /> : <ChevronLeft />}{" "}
      </IconButton>
    </>
  );
}
