import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

import { AccessControlListElementProps } from "../../../../../../../../../types/content/structureTemplates/StructureTemplateProps";
import { useState } from "react";
import { EditAclDialog } from "../EditAclDialog/EditAclDialog";
import { NoEntityWarningDialog } from "../NoEntityWarning/NoEntityWarningDialog";

interface IProps {
  selectedIndex: number | undefined;
  initialValue: AccessControlListElementProps;
  update: (index: number, obj: object) => void;
  namePrefix: string;
  control: any;
  setValue: any;
  getValues: any;
  register: any;
  trigger: any;
  errors: any;
}
export function UpdateAclDialog({
  selectedIndex,
  initialValue,
  update,
  namePrefix,
  control,
  setValue,
  getValues,
  register,
  trigger,
  errors,
}: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = () => {
    setIsOpen(true);
  };

  const handleConfirm = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  // RENDER

  return (
    <div>
      <Button startIcon={<EditIcon />} onClick={handleUpdate}>
        {t("edit")}
      </Button>
      {selectedIndex === undefined && (
        <NoEntityWarningDialog
          isOpen={isOpen}
          title={`${t("edit")} ${t("accessControlList")}`}
          label={`${t("noAclEntrySelected")}!`}
          handleClose={handleCancel}
        />
      )}
      {selectedIndex !== undefined && (
        <EditAclDialog
          mode="UPDATE"
          isOpen={isOpen}
          aclIndex={selectedIndex}
          initialValue={initialValue}
          namePrefix={namePrefix}
          update={update}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          trigger={trigger}
          errors={errors}
        />
      )}
    </div>
  );
}
