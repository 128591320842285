import {
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/userContext/UserProvider";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout, user } = useUserContext();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip arrow title={`${user?.name} ${user?.surname}`}>
        <Box sx={{ py: 1, px: 0 }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar>{`${user?.name?.[0].toUpperCase()}${user?.surname?.[0].toUpperCase()}`}</Avatar>
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={"Profile"}
          onClick={() => {
            navigate("/profile");
          }}
        >
          <Typography textAlign="center">{t("profile")}</Typography>
        </MenuItem>
        <MenuItem
          key={"Logout"}
          onClick={() => {
            logout();
          }}
        >
          <Typography textAlign="center">{t("logout")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
