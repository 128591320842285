import { Box, CssBaseline } from "@mui/material";
import { Header } from "../../header/Header";
import { Sidebar } from "../../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { AlertSnackbar } from "../../common/snackbar/AlertSnackbar";
import { ProjectProvider } from "../../../ProjectContext";
import { DocumentProvider } from "../../../DocumentContext";
import { AlertProvider } from "../../../context/alertContext/AlertContextProvider";
import { NavigationConfirmProvider } from "../../../context/navigationConfirmContext/NavigationConfirmProvider";

function HomePage() {
  return (
    <ProjectProvider>
      <DocumentProvider>
        <AlertProvider>
          <NavigationConfirmProvider>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <Header />
              <Sidebar />
              <Outlet />
              <AlertSnackbar />
            </Box>
          </NavigationConfirmProvider>
        </AlertProvider>
      </DocumentProvider>
    </ProjectProvider>
  );
}

export default HomePage;
