import { useForm } from 'react-hook-form';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import { Box } from '@mui/material';

import { PackageProps } from '../../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { AUTOMATION_CHAINS } from '../../../../../serverInteraction/services/endpointsConstants';

import { AutomationChainsProps } from '../../../../../types/automation/automationChains/AutomationChainsProps';
import useAutomationChains from '../../../../../serverInteraction/hooks/automation/automationChains/useAutomationChains';

export const AutomationChainsCreateDialogs = () => {
  const { create } = useCreate<AutomationChainsProps, AutomationChainsProps>(AUTOMATION_CHAINS);
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { refetch } = useAutomationChains(userPackage.company, userPackage.document);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<AutomationChainsProps>();

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              required: {
                value: true,
                message: 'ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];

  const onSubmit = async (newAutomationChains: AutomationChainsProps) => {
    const automationChainsCreateData = {
      ...newAutomationChains,
      company: userPackage.company,
      document: userPackage.document,
      parameters: [],
      operations: [],
    };

    await create(automationChainsCreateData);
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Atomation Chains"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
};
