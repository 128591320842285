import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AddDialog } from "../../../../common/dialog/AddDialog";
import useCreate from "../../../../../serverInteraction/hooks/entity/useCreate";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../../../common/input/ControlledInput";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../common/grid/GridOneToThree";
import { ControlledTextarea } from "../../../../common/textarea/ControlledTextarea";
import { CreateUpdateFacetsProps } from "../../../../../types/content/facets/CreateUpdateFacetsProps";
import { FacetProps } from "../../../../../types/content/facets/FacetProps";
import useFacets from "../../../../../serverInteraction/hooks/content/facets/useFacets";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";

export function FacetsCreateDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { create } = useCreate<CreateUpdateFacetsProps, FacetProps>("facets");
  const { refetch } = useFacets();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<CreateUpdateFacetsProps>();

  const { facets, isLoading } = useFacets();
  if (isLoading) {
    return <Loader />;
  }
  const facetsIds = facets
    ? JSON.parse(facets?.toString()).map((item: FacetProps) =>
        item.featureId.toLowerCase()
      )
    : [];
  const isUnique = (value: string) =>
    facetsIds.indexOf(value.toLowerCase()) === -1;

  const onSubmit = async (newFacet: CreateUpdateFacetsProps) => {
    const data = {
      featureId: newFacet.featureId,
      label: newFacet.label,
      company: userPackage.company,
      document: userPackage.document,
      schemasFeatureId: [],
    };
    try {
      const res: FacetProps = JSON.parse(`${await create(data)}`);
      reset();
      navigate(`${res.id}`);
    } catch (error: any) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  // RENDER
  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("featureId", {
              required: {
                value: true,
                message: t("inputRequired").replace("%1", t("Feature ID")),
              },
              minLength: {
                value: 3,
                message: t("minValidation").replace("%1", "3"),
              },
              validate: (value) =>
                isUnique(value) ||
                t("uniqueValidation")
                  .replace("%1", "Facet")
                  .replace("%2", value),
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Label",
      required: false,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("label", {}),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Description",
      required: false,
      content: (
        <ControlledTextarea
          register={{
            ...register("description", {}),
            maxRows: 4,
            minRows: 3,
          }}
        />
      ),
    },
  ];

  return (
    <AddDialog
      title="Create Facet"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
}
