import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack, Typography, Box } from '@mui/material';
import ErrorLabel from '../errorLabel/ErrorLabel';

interface IProps {
    navTo: string,
    label: string,
    countErrors: number
}

const EntityTitle = ({navTo, label, countErrors }:IProps) => {
    return (
        <Box 
            sx = {{display: 'flex', alignItems: 'center', gap: '10px'}}
        >
            <Link 
                underline="hover" 
                component={RouterLink} 
                to={navTo}
            >
                {label}
            </Link>
            {countErrors ? 
                <ErrorLabel
                    countErrors={countErrors}
                /> : null
            }
        </Box>
    )
}

export default EntityTitle;