import { Box, Button, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { SimpleAccordion } from "../../../common/accordion/SimpleAccordion";
import { FacetProps } from "../../../../types/content/facets/FacetProps";
import { CreateUpdateFacetsProps } from "../../../../types/content/facets/CreateUpdateFacetsProps";
import { useTranslation } from "react-i18next";
import ConfirmDiscardDialog from "../../../common/dialog/ConfirmDiscardDialog";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../common/grid/GridOneToThree";
import { ControlledInput } from "../../../common/input/ControlledInput";
import { ControlledTextarea } from "../../../common/textarea/ControlledTextarea";
import NavToDesigner from "../../../common/buttonsBox/NavToDesigner";
import { DefaultSchemasForDocument } from "../../../../enum/DefaultSchemasForDocument";
import useUpdate from "../../../../serverInteraction/hooks/entity/useUpdate";
import { FACETS_ENDPOINT } from "../../../../serverInteraction/services/endpointsConstants";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../types/packages/PackageProps";
import useAlert from "../../../../context/alertContext/useAlert";
import { TransferList } from "../../../common/transfetList/TransferList";
import { useForm } from "react-hook-form";

interface IProps {
  facet: FacetProps;
  schemasList: string[];
  refetch: any;
}

export default function FacetView({ facet, schemasList, refetch }: IProps) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const schemasFullList: string[] =
    DefaultSchemasForDocument.concat(schemasList).sort();

  const { showSuccess } = useAlert();

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = useForm<CreateUpdateFacetsProps>({
    defaultValues: facet,
  });

  const [loadedSchemas, setLoadedSchemas] = useState<string[]>(
    facet.schemasFeatureId
  );

  const updateSchemas = (newList: any) => {
    setValue("schemasFeatureId", newList, { shouldDirty: true });
  };

  const onSuccessUpdate = (data: FacetProps) => {
    showSuccess(t("dataUpdated"));
    //перерисуем
    refetch();
  };

  const { update } = useUpdate<CreateUpdateFacetsProps, FacetProps>(
    FACETS_ENDPOINT,
    onSuccessUpdate
  );

  const onSubmit = async (newFacet: CreateUpdateFacetsProps) => {
    const newData: CreateUpdateFacetsProps = {
      featureId: facet.featureId,
      company: userPackage.company,
      document: userPackage.document,
      label: newFacet.label,
      description: newFacet.description,
      schemasFeatureId: newFacet.schemasFeatureId,
    };

    try {
      await update("id", newData);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    reset();
    setLoadedSchemas(getValues("schemasFeatureId"));
  };

  // RENDER
  const formItems: GridOneToThreeItemProps[] = [
    {
      title: "Label",
      required: false,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("label", {}),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Description",
      required: false,
      content: (
        <ControlledTextarea
          register={{
            ...register("description", {}),
            maxRows: 4,
            minRows: 3,
          }}
        />
      ),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack>
          <NavToDesigner />
          <Box
            sx={{
              width: "100%",
              justifyContent: "right",
              display: "flex",
              paddingTop: "1em",
            }}
          >
            <ConfirmDiscardDialog
              buttonTitle={t("button_discard")}
              isNeedConfirm={isDirty}
              onConfirm={handleDiscard}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginLeft: "1em" }}
            >
              {t("save")}
            </Button>
          </Box>
        </Stack>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <GridOneToThree items={formItems} />
          <Box style={{ display: "flex", width: "100%" }}>
            <SimpleAccordion
              title={t("associatedSchemas")}
              sx={{ mt: 3 }}
              defaultExpanded={true}
            >
              <TransferList
                entityList={schemasFullList}
                updateParent={updateSchemas}
                loadEntities={loadedSchemas}
                isUserTransferList={true}
              />
            </SimpleAccordion>
          </Box>
        </Paper>
      </Stack>
    </form>
  );
}
