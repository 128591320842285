import useGetAll from '../../entity/useGetAll';
import { AutomationScriptingProps } from '../../../../types/automation/automationScripting/AutomationScriptingProps';
import { AUTOMATION_SCRIPTING } from '../../../services/endpointsConstants';

const useAutomationScripting = (company: string, document: string, featureId?: string) => {
  let entityName = '';

  if (featureId) {
    entityName = `${AUTOMATION_SCRIPTING}?company=${company}&document=${document}&featureId=${featureId}`;
  } else {
    entityName = `${AUTOMATION_SCRIPTING}?company=${company}&document=${document}`;
  }

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<AutomationScriptingProps>(entityName);

  return {
    status,
    automationScripting: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useAutomationScripting;
