import { Box, Checkbox, FormControlLabel, FormLabel, Grid, Stack, Typography } from '@mui/material';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { SchemaProjectProps, SchemaProps } from '../../../../../types/content/schemas/SchemaProps';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { ControlledRadioGroup } from '../../../../common/radioGroup/ControlledRadioGroup';
import { ECreateSchemaMode } from '../../../../../enum/ECreateSchemaMode';
import { Loader } from '../../../../common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { SchemaWithFieldsPropsGet, SchemaWithFieldsPropsCreate } from '../../../../../types/content/schemas/SchemaWithFieldsProps';
import { useNavigate } from 'react-router-dom';
import { convertSchemaProjectToSchema } from '../../../../../utils/CommonUtils';
import { modeItems } from './constants';
import { UploadDialog } from '../../../../common/dialog/UploadDialog';
import { useState } from 'react';
import { SCHEMAS_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';

export function SchemasCreateDialog() {
  const { getValues: getValuesMainForm } = useFormContext<{listSchemas: SchemaProps[]}>();
  const isUniqueFeature = (value: string) => getValuesMainForm('listSchemas').map((item: SchemaProps) => item.featureId).indexOf(value) === -1;

  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { t } = useTranslation();
  const { create, isCreating: isLoading } = useCreate<SchemaWithFieldsPropsCreate, SchemaWithFieldsPropsGet>(SCHEMAS_ENDPOINT);
  const navigate = useNavigate();
  const [xsdFileName, setXsdFileName] = useState<string | undefined>(undefined);

  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<SchemaWithFieldsPropsCreate>({
    defaultValues: {
      companyName: userPackage.company,
      documentName: userPackage.document,
      mode: ECreateSchemaMode.DEFAULT,
      isAllowNestedComplexTypes: false,
      isActivateVcsSanityChecks: false,
      fields: [],
    },
  });

  const watchMode = watch("mode");

  // EVENTS
  const handleChangeXsdFile = async (newFileName: string | undefined) => {
    setXsdFileName(newFileName);
    setValue('xsdSchemaFileId', newFileName || undefined);
  };


  const onSubmit = async (data: SchemaWithFieldsPropsCreate) => {
    isLoading && <Loader />;
    try {
      const newSchema = {
        featureId: data.featureId,
        prefix: data.prefix,
        companyName: data.companyName,
        documentName: data.documentName,
        fields: data.fields,
      }
      const res: SchemaProjectProps = JSON.parse(`${await create(newSchema)}`);
      reset();
      navigate(`${res.featureId}`);
    } catch (error: any ) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  //RENDER
  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: t('patternValidation')
              },
              required: {
                value: true,
                message: t('inputRequired').replace('%1', t('Feature ID')),
              },
              minLength: {
                value: 3,
                message: t('minValidation').replace('%1', '2'),
              },
              validate: value => isUniqueFeature(value) || 'FeatureId is not unique'
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Schema Prefix',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('prefix', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: t('patternValidation')
              },
              required: {
                value: true,
                message: t('inputRequired').replace('%1', t('Schema Prefix')),
              },
              minLength: {
                value: 3,
                message: t('minValidation').replace('%1', '2'),
              }
            }),
          }}
          helperText={errors.prefix?.message}
          error={errors.prefix !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ]  
  

  const xsdSchemaFileItems: GridOneToThreeItemProps[] = [
       {
         title: `XSD ${t('schemaFile')}`,
         required: true,
         content: (
           <Stack sx={{marginTop:"-8px"}}>
            <div
              style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}
            >
              <Typography style={xsdFileName ? { marginRight: 10 } : {}}>{xsdFileName}</Typography>
                <UploadDialog title="Select Resource" variant="XSD" handleChangeFile={handleChangeXsdFile} />
              </div>
             {/*<ControlledSingleResource control={control} name="xsdSchemaFileId" />*/}
             <Controller
               control={control}
               name="isActivateVcsSanityChecks"
               render={({ field: { onChange, value } }) => (
                 <FormControlLabel
                   control={
                     <Checkbox checked={value} onChange={(event, checked) => onChange(checked)} />
                   }
                   label="Activate VCS sanity checks"
                 />
               )}
             />
           </Stack>
         ),
       },
     ];

  return (
    <AddDialog
      title= {`${t("button_create")} ${t("titleSchemas")}`}
      add={handleSubmit(onSubmit)}
      validate={validate}
    >
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
        <SimpleAccordion
          title="Advanced configuration"
          sx={{ mt: 3 }}
          defaultExpanded={false}
          // children={''}
        >
        <Grid container columns={4} spacing={1} justifyContent="center" alignItems="start">
          <Grid item xs={1} sx={{ height: '100%', marginTop: '8px'}}>
            <FormLabel required={true} >
              {t('Mode')} 
            </FormLabel>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              minHeight: '3rem',
              alignItems: 'center',
            }}
          >
            <Grid container columns={4} spacing={1} justifyContent="center" alignItems="start">
              <Grid item xs={1} sx={{ height: '100%' }}>
                <Controller
                  control={control}
                  name="mode"
                  render={({ field: { onChange, value } }) => (
                    <ControlledRadioGroup
                      id="mode-radio-group"
                      {...register('mode')}
                      value={value}
                      items={modeItems.map((modeItem) => {
                        return {
                          // label: ECreateDocTypeMode[modeItem.item],
                          // label: translate('', ECreateDocTypeMode[modeItem.item]),
                          label: modeItem.item,
                          value: modeItem.value,
                          name: Math.random().toFixed(2),
                        };
                      })}
                      onChange={onChange}
                      style={{display: 'flex', flexDirection: 'column'}}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sx={{ height: '100%' }}>
                <Typography style={{ marginLeft: '25px', marginTop: '8px', minWidth: '20px' }}>
                  {t(modeItems.find((modeItem) => modeItem.value === watchMode)?.helperText || "")}
                </Typography>
                <Typography style={{ marginLeft: '25px', marginTop: '8px', minWidth: '20px', fontStyle: 'italic'}}>
                  {t(modeItems.find((modeItem) => modeItem.value === watchMode)?.helperDescription || "")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {watchMode !== ECreateSchemaMode.DEFAULT && 
          <GridOneToThree items={xsdSchemaFileItems} sx={{alignItems:"start", marginTop: "8px"}}/> 
        }
        <Controller
          control={control}
          name="isAllowNestedComplexTypes"
          render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={watchMode !== ECreateSchemaMode.DEFAULT}
                    checked={
                      watchMode === ECreateSchemaMode.DEFAULT ? value : true
                    }
                    onChange={(event, checked) => onChange(checked)}
                  />
                }
                label={t('allowNestedComplexTypes')}
              />
            )}
          /> 
        </SimpleAccordion>
      </Box>
    </AddDialog>
  );
}
