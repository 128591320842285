 //отформатируем fields.constraints перед отправкой

import { typesConstraints } from "./constants";

 //обнулим все неподходящее, отправляем только ненулевые
export const formatFieldConstrants = (constrants: Array<any>, fieldType: string, isMultiValue: boolean) => {
    return constrants.map(elem => formatFieldConstrant(elem, fieldType, isMultiValue)).filter(elem => elem!== null)
}

const formatFieldConstrant = (constrant: any, fieldType: string, isMultiValue: boolean) => {
    //null - уже null
    if (constrant === null)
        return null;
    const key = Object.keys(constrant)[0];
    //неподходящие по типу
    const typeConstraints = typesConstraints.find(elem => elem.type === fieldType)?.constrants || [];
    if (!typeConstraints.includes(key))
        return null;
    //проверяем в зависимости от key
    switch (key) {
        case 'mandatoryConstraint':
            //интересует только при value === true
            return (constrant[key].value === true) ? constrant : null
        case 'listElementsMandatoryConstraint':
            //только для multiValue, интересует только при value === true
            return (isMultiValue && constrant[key].value === true) ? constrant : null
        case 'textFormatConstraint':
            //интересует только при наличии pattern
            return (constrant[key].pattern) ? constrant : null
        case 'textLengthConstraint':
             //интересует только при наличии max || min, нулевые max, min не берем
             let objText: {textLengthConstraint:{maximum?: any, minimum?: any}} = {textLengthConstraint:{}};
             if (constrant[key].maximum)
                 objText.textLengthConstraint.maximum = constrant[key].maximum; 
             if (constrant[key].minimum)
                 objText.textLengthConstraint.minimum = constrant[key].minimum; 
 
             return (objText.textLengthConstraint.maximum || objText.textLengthConstraint.minimum) ? objText : null;

        case 'numericRangeConstraint':
            //интересует только при наличии max || min, нулевые max, min не берем. inclusive интересует только при наличии соответствующего значения
            let objNumeric: {numericRangeConstraint:{maximum?: any, minimum?: any, maximumInclusive?: boolean, minimumInclusive?: boolean}} = {numericRangeConstraint:{}};
            if (constrant[key].maximum){
                objNumeric.numericRangeConstraint.maximum = constrant[key].maximum; 
                objNumeric.numericRangeConstraint.maximumInclusive = constrant[key].maximumInclusive || false;
            }
            if (constrant[key].minimum){
                objNumeric.numericRangeConstraint.minimum = constrant[key].minimum; 
                objNumeric.numericRangeConstraint.minimumInclusive = constrant[key].minimumInclusive || false;
            }
            return (objNumeric.numericRangeConstraint.maximum || objNumeric.numericRangeConstraint.minimum) ? objNumeric : null
        
        case 'dateRangeConstraint':
            //интересует только при наличии max || min, нулевые max, min не берем. inclusive интересует только при наличии соответствующего значения
            let objDate: {dateRangeConstraint:{maximum?: any, minimum?: any, maximumInclusive?: boolean, minimumInclusive?: boolean}} = {dateRangeConstraint:{}};
            if (constrant[key].maximum){
                objDate.dateRangeConstraint.maximum = constrant[key].maximum; 
                objDate.dateRangeConstraint.maximumInclusive = constrant[key].maximumInclusive || false;
            }
            if (constrant[key].minimum){
                objDate.dateRangeConstraint.minimum = constrant[key].minimum; 
                objDate.dateRangeConstraint.minimumInclusive = constrant[key].minimumInclusive || false;
            }
            return (objDate.dateRangeConstraint.maximum || objDate.dateRangeConstraint.minimum) ? objDate : null
        
    }
    return null;
}