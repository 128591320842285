import { Paper, Stack } from "@mui/material";
import { Page } from "../../../common/page/Page";
import { PageContent } from "../../../common/page/PageContent";
import { PageHeader } from "../../../common/page/PageHeader";
import { WorkflowsCreateDialog } from "./create/WorkflowsCreateDialog";
import { ProcessDefinitionPageList } from "./ProcessDefinitionPageList";
import { useTranslation } from "react-i18next";

export function ProcessDefinitionsPage() {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title={`${t("workflowTitle")}`} />
      <PageContent subtitle="A workflow definition is a template that is used in Nuxeo Platform to start workflows on documents.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <WorkflowsCreateDialog />
          <ProcessDefinitionPageList />
        </Paper>
      </PageContent>
    </Page>
  );
}
