export const AUTH_LOGIN = 'auth/login';
export const CONTENT_ENDPOINT = 'content/upload';
export const DOCTYPES_ENDPOINT = 'doctypes';
export const FACETS_ENDPOINT = 'facets';
export const PACKAGE_ENTITIES_ENDPOINT = 'package/fetch';
export const SCHEMAS_ENDPOINT = 'schemas';
export const LIFECYCLES_ENDPOINT = 'lifecycles';
export const STRUCTURE_TEMPLATES_ENDPOINT = "structure-templates";
export const BUILD_PACKAGE_ENDPOINT = 'package/build';
export const AUTOMATION_SCRIPTING = 'automation/scripts';
export const AUTOMATION_CHAINS = 'automation/chains';
export const WORKFLOWS_ENDPOINT = "workflows";
