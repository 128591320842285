import { PropsWithChildren, useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, BoxProps, Box } from '@mui/material';
import { CopyIconButton } from '../button/iconButton/CopyIconButton';
import { SimpleModal } from '../modal/SimpleModal';


type Props = {
  title: string;
  copyContent: () => Promise<void>;
  validate: () => Promise<boolean>;
  isCopyContent?: () => void;
  style?: CSSProperties;
} & BoxProps &
  PropsWithChildren;

export function CopyContentDialog({ title, copyContent, validate, children, isCopyContent, style, ...props }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = async () => {
    const result = await validate();

    if (result) {
      setIsOpen(false);
      await copyContent();
      isCopyContent && isCopyContent();
    }
  };

  return (
    <div>
      <CopyIconButton title={title} onClick={() => setIsOpen(true)} size="small"/>
      <SimpleModal title={title} isOpen={isOpen} handleClose={() => setIsOpen(false) }>
        <Box {...props}>
          {children}
          <Stack
            direction="row-reverse"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="contained" onClick={handleAdd} sx={{ width: 150 }}>
              {t('copy')}
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
              {t('cancel')}
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </div>
  );
}
