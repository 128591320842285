// UniversalNode.tsx
import { Tooltip } from '@mui/material';
import { Handle, Position } from 'react-flow-renderer';
import InfoIcon from '@mui/icons-material/Info';

const UniversalNode = ({ data }: any) => {
  const inputHandles = [];
  const outputHandles = [];

  // Динамическое добавление входов
  if (data.inputs && data.inputs > 0) {
    for (let i = 0; i < data.inputs; i++) {
      inputHandles.push(
        <Handle
          key={`input-handle-${i}`}
          type="target"
          position={Position.Top}
          id={`input-${i}-${data.label}`}
          style={{
            left: `${(i + 1) * (100 / (data.inputs + 1))}%`,
            width: '15px',
            height: '15px',
            background: 'lightblue',
          }}
        />,
      );
    }
  }

  if (data.outputs && data.outputs > 0) {
    for (let i = 0; i < data.outputs; i++) {
      outputHandles.push(
        <Handle
          key={`output-handle-${i}`}
          type="source"
          position={Position.Bottom}
          id={`output-${i}`}
          style={{
            left: `${(i + 1) * (100 / (data.outputs + 1))}%`,
            width: '15px',
            height: '15px',
            background: 'lightgreen',
          }}
        />,
      );
    }
  }

  return (
    <div
      style={{
        width: 100,
        height: 100,
        background: `${data.bgc}`,
        color: '#fff',
        position: 'relative',
        borderRadius: '5px',
        textAlign: 'center',
        boxShadow: '2px 2px 5px rgba(0,0,0)',
      }}
    >
      <Tooltip
        title="to open the properties, double-click"
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}
      >
        <InfoIcon />
      </Tooltip>
      <div style={{ paddingTop: '40px' }}>{data.label}</div>

      {/* Входные точки */}
      {inputHandles}

      {/* Выходные точки */}
      {outputHandles}
    </div>
  );
};

export default UniversalNode;
