import { useParams } from "react-router-dom";
import useFacet from "../../../../serverInteraction/hooks/content/facets/useFacet";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { Typography } from "@mui/material";
import { Page } from "../../../common/page/Page";
import { PageHeader } from "../../../common/page/PageHeader";
import { PageContent } from "../../../common/page/PageContent";
import useSchemas from "../../../../serverInteraction/hooks/content/schemas/useSchemas";
import { useTranslation } from "react-i18next";
import { convertFacet } from "../../../../utils/facetUtils";
import FacetView from "./FacetView";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../types/packages/PackageProps";

type Params = {
  id: string;
};

export function FacetPage() {
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );

  const {
    status,
    facet: facetWithJson,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useFacet(`${id}`);

  const { schemas, isLoading: schemasLoading } = useSchemas(
    userPackage.company,
    userPackage.document
  );

  if (
    isLoading ||
    isFetching ||
    facetWithJson === undefined ||
    schemasLoading ||
    schemas === undefined
  ) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const facet = convertFacet(facetWithJson.toString());
  const schemasId =
    JSON.parse(`${schemas}`).map((featureId: any) => featureId.featureId) || [];

  return (
    <Page>
      <PageHeader title={`${t("facet")} ${facet.featureId}`}></PageHeader>
      <PageContent subtitle={t("facetSubtitle")}>
        <FacetView facet={facet} schemasList={schemasId} refetch={refetch} />
      </PageContent>
    </Page>
  );
}
