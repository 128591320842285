import { FacetProps, FacetWithJsonProps } from "../types/content/facets/FacetProps";

export function convertFacet(data: string): FacetProps {
    const dataWithJson: FacetWithJsonProps = JSON.parse(data);
    const facet = {
      id: dataWithJson.id,
      featureId: dataWithJson.featureId,
      label: dataWithJson.label,
      description: dataWithJson.description,
      schemasFeatureId: dataWithJson.schemaFeatureIdsJson ? JSON.parse(dataWithJson.schemaFeatureIdsJson) : [],
    }
    return facet;
  }
  