import { Box, Stack, Typography } from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import LoginForm from "./LoginForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/userContext/UserProvider";

const LoginPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    // Если был вход в приложение, на страницу пароля не ходим, идем на домашнюю
    if (isLoggedIn()) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <Stack direction="row" spacing={2} sx={{ height: "100vh" }}>
      <Box sx={{ width: "30%", display: "flex", p: 2, bgcolor: "WhiteSmoke" }}>
        <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          STUDIO 2.0
        </Typography>
      </Box>

      <Box
        sx={{
          width: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoginForm />
      </Box>
    </Stack>
  );
};

export default LoginPage;
