import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  entityList: string[];
  loadEntities?: string[];
  updateParent?: (newRight: string[]) => void;
  isUserTransferList?: boolean;
  width?: string | number;
  height?: string | number;
  onSelect?: (value: string) => void;
};

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function TransferList({
  entityList,
  loadEntities,
  updateParent,
  width,
  height,
  isUserTransferList,
  onSelect,
}: Props) {
  const [checked, setChecked] = useState<string[]>([]);
  const [isFirstPass, setFirstPass] = useState<boolean>(true);
  const [left, setLeft] = useState<string[]>(
    entityList.filter((elem) => !loadEntities?.includes(elem)),
  );
  const [right, setRight] = useState<string[]>(loadEntities || []);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (onSelect) onSelect(value);
  };

  useEffect(() => {
    if (updateParent) {
      updateParent(right);
    }
  }, [right]);

  useEffect(() => {
    if (loadEntities && isFirstPass) {
      const commonSchemas = intersection(loadEntities, entityList);
      const uniqueSchemas = commonSchemas.filter((schema) => !right.includes(schema));

      setRight([...right, ...uniqueSchemas]);
      setLeft(not(left, uniqueSchemas));
      setFirstPass(false);
    } else if (loadEntities && !isFirstPass && isUserTransferList) {
      const isRightList = intersection(loadEntities, entityList);
      setRight(isRightList);
      setLeft(not(entityList, isRightList));
    }
  }, [loadEntities]);

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: string[], title: string) => (
    <Paper
      style={{
        width: width || 400,
        height: height || 220,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '8px 0',
          borderBottom: '1px solid #ccc',
        }}
      >
        {title}
      </div>
      <List
        component="div"
        role="list"
        style={{
          flexGrow: 1,
          minHeight: '150px', // Устанавливаем минимальную высоту списка
        }}
      >
        {items.length > 0 ? (
          items.sort().map((value, index) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem key={index} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText primary="No items" style={{ textAlign: 'center' }} />
          </ListItem>
        )}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left, 'Available')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, 'Selected')}</Grid>
    </Grid>
  );
}
