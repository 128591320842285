import { Box } from "@mui/material";
import { AddDialog } from "../../../../common/dialog/AddDialog";
import useCreate from "../../../../../serverInteraction/hooks/entity/useCreate";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../../../common/input/ControlledInput";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../common/grid/GridOneToThree";
import { CreateWorkflowProps } from "../../../../../types/content/workflows/CreateWorkflowProps";
import { WorkflowProps } from "../../../../../types/content/workflows/WorkflowProps";
import { ControlledTextarea } from "./../../../../common/textarea/ControlledTextarea";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import { useSelector } from "react-redux";
import { WORKFLOWS_ENDPOINT } from "../../../../../serverInteraction/services/endpointsConstants";
import useWorkflows from "../../../../../serverInteraction/hooks/content/workflow/useWorkflows";

export function WorkflowsCreateDialog() {
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { refetch } = useWorkflows(userPackage.company, userPackage.document);
  const { create } = useCreate<CreateWorkflowProps, WorkflowProps>(
    WORKFLOWS_ENDPOINT
  );

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<CreateWorkflowProps>();

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("featureId", {
              required: {
                value: true,
                message: "ID is required",
              },
              minLength: {
                value: 3,
                message: "Must be at least 3 symbols",
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Label",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("label", {
              required: {
                value: true,
                message: "Label is required",
              },
            }),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Description",
      required: false,
      content: (
        <ControlledTextarea
          register={{
            maxRows: 4,
            minRows: 3,
            ...register("description", {
              required: {
                value: true,
                message: "Label is required",
              },
            }),
          }}
        />
      ),
    },
  ];

  const onSubmit = async (newWorkflow: CreateWorkflowProps) => {
    const workflowCreateData: CreateWorkflowProps = {
      ...newWorkflow,
      company: userPackage.company,
      document: userPackage.document,
      description: newWorkflow.description,
      featureId: newWorkflow.featureId,
      graphEditorPointX: 1,
      graphEditorPointY: 1,
      iconUrl: "http://example.com/icon.png",
      id: newWorkflow.id,
      label: newWorkflow.label,
      largeIconUrl: "http://example.com/large-icon.png",
      workflowFieldList: {
        name: "FieldList1",
        prefix: "FL",
        fieldList: [],
      },
      workflowNodeTransitionUpsertRequestList: [],
      workflowNodeUpsertRequestList: [],
    };

    await create(workflowCreateData);
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Process Definition"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={refetch}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
}
