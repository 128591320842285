import { Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

type Props = ButtonProps & PropsWithChildren;

export function DeleteButton({ children, ...props }: Props) {
  const { t } = useTranslation();
  return (
    <Button startIcon={<DeleteIcon />} {...props}>
      {children ? children : t("delete")}
    </Button>
  );
}
