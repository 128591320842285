import { Box, FormControl, NativeSelect, TextareaAutosize } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const GeneralDescAndTypes = () => {
  const { watch, register } = useFormContext();
  const formValues = watch();
  const { t } = useTranslation();

  const staticTypesNames: any[] = ['VOID', 'DOCUMENT', 'DOCUMENTS', 'BLOB', 'BLOBS'];

  return (
    <form style={{ marginTop: '30px' }}>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <Box alignSelf="center" component="label" htmlFor="description">
          {`${t('autoScriptDes')}`}
        </Box>
        <TextareaAutosize
          defaultValue={formValues.description}
          {...register('description')}
          id="description"
          minRows={2}
          style={{
            minWidth: '300px',
            border: '1px solid #f3f3f3',
            padding: '5px',
            alignContent: 'center',
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '30px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Box component="label" htmlFor="input_type">
            {`${t('inputType')}`}
          </Box>
          <FormControl sx={{ width: '200px' }} variant="standard">
            <NativeSelect
              {...register('inputType', {})}
              id="input_type"
              defaultValue={formValues.inputType}
            >
              {staticTypesNames.map((name, i) => (
                <option key={i} value={name}>
                  {name.toLowerCase()}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Box component="label" htmlFor="output_type">
            {`${t('outputType')}`}
          </Box>
          <FormControl sx={{ width: '200px' }} variant="standard">
            <NativeSelect
              {...register('outputType', {})}
              id="output_type"
              defaultValue={formValues.outputType}
            >
              {staticTypesNames.map((name, i) => (
                <option key={i} value={name}>
                  {name.toLowerCase()}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Box>
      </Box>
    </form>
  );
};

export default GeneralDescAndTypes;
