import { useTranslation } from 'react-i18next';
import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import { SchemasDeleteDialog } from './delete/SchemasDeleteDialog';
import useSchemas from '../../../../serverInteraction/hooks/content/schemas/useSchemas';
import { SchemaProps, SchemaProjectProps } from '../../../../types/content/schemas/SchemaProps';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { SchemasCreateDialog } from './create/SchemasCreateDialog';
import { convertSchemaProjectToSchema } from '../../../../utils/CommonUtils';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import SchemasRenameDialog from './rename/SchemasRenameDialog';
import SchemasCopyDialog from './copy/SchemasCopyDialog';

export function SchemasView() {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, schemas, isLoading, isFetching, error, refetch } = useSchemas(userPackage.company, userPackage.document);

  if (isLoading || isFetching || schemas === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }
  
  const schemasArr: Array<SchemaProps> = JSON.parse(schemas?.toString()).map((item: SchemaProjectProps)=>{
    return convertSchemaProjectToSchema(item);
  });

  return <SchemasForm schemas={schemasArr} refetch = {refetch}/>
}

interface IFormProps {
  schemas: SchemaProps[]
  refetch: () => void;
}

function SchemasForm({ schemas, refetch }:IFormProps){
  const schemaFormMethods = useForm<{listSchemas: SchemaProps[]}>({
    defaultValues: {
      listSchemas: schemas
    }
  });

  return (
    <FormProvider {...schemaFormMethods}>
      <SchemasCreateDialog />
      <SchemaFormList refetch = {refetch}/>
    </FormProvider>
  );
}

interface IListProps {
  refetch: () => void;
}

function SchemaFormList({refetch}: IListProps){
  const { t } = useTranslation();
  const { control } = useFormContext<{listSchemas: SchemaProps[]}>();
  const { fields, remove } = useFieldArray({
    control, 
    name: 'listSchemas',
    keyName: '_id'
  });
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        {t("name")}
      </Typography>
      <Stack>
        {fields.map((schema: SchemaProps, index: number) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={schema.featureId}
          >
            <Link underline="hover" 
              component={RouterLink} 
              to={`${schema.featureId}`}
            >
              {schema.featureId}
            </Link>

            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <Box display="flex">
                <SchemasRenameDialog
                  featureId={schema.featureId}
                  refetch={() => refetch()}
                />
                <SchemasCopyDialog
                  schema={schema}
                  refetch={() => refetch()}
                />
                <SchemasDeleteDialog 
                  featureId={schema.featureId}
                  onDelete={() => refetch()}
                />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
