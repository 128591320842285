import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Button,
  BoxProps,
  Box,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { AddButton } from "../../../../../common/button/AddButton";
import { DefaultTypesForDocument } from "../../../../../../enum/DefaultTypesForDocument";

interface IProps {
  onConfirm: (value: string) => void;
  initialValue: string;
  customDoctypes: string[];
}

export function ChildCreateDialog({
  onConfirm,
  initialValue,
  customDoctypes,
}: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDoctype, setSelectedDoctype] = useState(initialValue || "");
  const doctypesList: string[] = customDoctypes
    .concat(
      Object.keys(DefaultTypesForDocument)
        .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
        .map((key: any) => key)
        .sort()
    )
    .sort();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    elem: string
  ) => {
    setSelectedDoctype(elem);
  };

  const handleDialogButton = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(selectedDoctype);
    setIsOpen(false);
  };

  return (
    <Box sx={{ border: "1px solid grey", mb: 2 }}>
      <AddButton onClick={handleDialogButton}>{t("add")}</AddButton>
      <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
        <DialogTitle>{t("selectDoctype")}</DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <List
            style={{
              width: "400px",
              maxHeight: "300px",
              overflow: "auto",
              margin: "0 auto",
            }}
          >
            {doctypesList.map((elem: string) => (
              <ListItemButton
                key={elem}
                selected={selectedDoctype === elem}
                onClick={(event) => handleListItemClick(event, elem)}
                style={{
                  borderLeft:
                    selectedDoctype === elem ? "2px solid blue" : "none",
                }}
              >
                <ListItemText primary={elem} key={elem} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Ок
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
