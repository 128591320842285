import { Box, Button, TextField } from "@mui/material";
import { Edge } from "react-flow-renderer";
import { useState, useEffect } from "react";

interface EdgeEditorProps {
  selectedEdge: Edge | null;
  setSelectedEdge: React.Dispatch<React.SetStateAction<Edge | null>>;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
}

const EdgeEditor = ({
  selectedEdge,
  setSelectedEdge,
  edges,
  setEdges,
}: EdgeEditorProps) => {
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (selectedEdge) {
      setNewName(selectedEdge.label as string);
    }
  }, [selectedEdge]);

  const handleEdgeNameChange = () => {
    if (selectedEdge) {
      setEdges((eds) =>
        eds.map((edge) =>
          edge.id === selectedEdge.id ? { ...edge, label: newName } : edge
        )
      );

      handleCancel();
    }
  };

  const handleCancel = () => {
    setSelectedEdge(null);
    setNewName("");
  };

  if (!selectedEdge) return null;

  return (
    <Box
      sx={{
        marginTop: "20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <TextField
        value={newName}
        label="Change the name of the connection"
        variant="outlined"
        onChange={(e) => setNewName(e.target.value)}
      />
      <Button variant="contained" onClick={handleEdgeNameChange}>
        Confirm
      </Button>
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
    </Box>
  );
};

export default EdgeEditor;
