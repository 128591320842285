import { GetDocTypesProps } from "../../../types/content/documentTypes/GetDocTypesProps";
import useGetAll from "../entity/useGetAll";
import { PackageProps } from "../../../types/packages/PackageProps";
import { DOCTYPES_ENDPOINT, PACKAGE_ENTITIES_ENDPOINT } from "../../services/endpointsConstants";
import { useSelector } from "react-redux";
import useGet from "../entity/useGet";
import { delay } from "lodash";

const usePackageEntities = () => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = `${PACKAGE_ENTITIES_ENDPOINT}?company=${userPackage.company}&document=${userPackage.document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<any>(entityName);
   
  return {
    status,
    data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default usePackageEntities;

