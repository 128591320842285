import { configureStore } from '@reduxjs/toolkit';
import packagesReducer from './packages/packagesSlice';
import validationErrorsReducer from './validationErrors/validationErrorsSlice'

export default configureStore({
  reducer: {
    packages: packagesReducer,
    validationErrors: validationErrorsReducer
  }
});
