import { Box, Typography, TextareaAutosize, Button } from '@mui/material';
import { useState } from 'react';
import { ExpressionEditorProps, ExpressionItem } from '../iProps';
import { expressionEditorConstants } from './expressionEditorConstants';
import { processExpressionEditorConstants } from './expressionEditorConstantsProcessor';
import { ContextSelector } from './ContextSelector';
import { ActionButtons } from './ActionButtons';

const processedConstants: ExpressionItem[] =
  processExpressionEditorConstants(expressionEditorConstants);

export const ExpressionEditor = ({
  header,
  fieldValue,
  isOpen,
  onClose,
  onConfirm,
}: ExpressionEditorProps) => {
  const [value, setValue] = useState(fieldValue);
  const [contexts, setContexts] = useState([{ id: Date.now(), value: '' }]);
  const [selectedContext, setSelectedContext] = useState<ExpressionItem | null>(null);
  const [selectedValues, setSelectedValues] = useState<string[]>([]); // Храним выбранные значения

  const resetContexts = () => {
    setContexts([{ id: Date.now(), value: '' }]);
    setSelectedContext(null);
    setSelectedValues([]);
  };

  const handleMainContextChange = (id: number, newValue: string) => {
    const selected = processedConstants.find((item) => item.name === newValue);
    setSelectedContext(selected || null);
    setContexts((prevContexts) =>
      prevContexts.map((context) =>
        context.id === id ? { ...context, value: newValue } : context,
      ),
    );

    setSelectedValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[0] = newValue;
      return updatedValues;
    });
  };

  const handleSubpropertyChange = (newValue: string) => {
    setSelectedValues((prevValues) => {
      const updatedValues = [...prevValues, newValue];
      return updatedValues;
    });
  };

  const handleInsert = () => {
    const expression = `@{${selectedValues.join('.')}`;
    setValue((prevValue) => `${prevValue}${expression}}`);
  };

  if (!isOpen) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          width: '400px',
          maxWidth: '90%',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6" component="h3" sx={{ marginBottom: '16px' }}>
          {header}
        </Typography>

        {contexts.map((context) => (
          <ContextSelector
            key={context.id}
            context={context}
            onChange={handleMainContextChange}
            options={processedConstants}
            selectedContext={selectedContext}
            onSubpropertyChange={handleSubpropertyChange}
          />
        ))}

        <TextareaAutosize
          value={value}
          onChange={(e) => setValue(e.target.value)}
          minRows={3}
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <Button variant="contained" onClick={handleInsert}>
            Insert
          </Button>
          <ActionButtons
            onClose={() => {
              onClose();
              resetContexts();
            }}
            onConfirm={() => {
              onConfirm(value);
              resetContexts();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
