import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { ValidationErrorProps } from "../../../../types/validation-errors/ValidationErrorProps";
import { SimpleAccordion } from "../SimpleAccordion";
import { uniq } from "lodash";
import CircleIcon from '@mui/icons-material/Circle';
import ValidationErrorsAccordionDetails from "./ValidationErrorsAccordionDetails";

interface IProps {
    variant: 'OBJECT' | 'ENTITY',
    errors: ValidationErrorProps[]
}

const ValidationErrorsAccordion = ({errors, variant}: IProps) => {
    const {t} = useTranslation();
    
    if (!errors.length)
        return null;
    
    return (
      <SimpleAccordion 
        title={t('validationErrors')} 
        sx={{borderLeft: '2px solid #BA0000'}}
      >
        <ValidationErrorsAccordionDetails 
          errors = {errors}
          variant = {variant}
        />
      </SimpleAccordion>
    )
}

export default ValidationErrorsAccordion;
