import { Box } from "@mui/material";
import useRemove from "../../../../../serverInteraction/hooks/entity/useRemove";
import { DeleteDialog } from "../../../../common/dialog/DeleteDialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import {
  FACETS_ENDPOINT,
  STRUCTURE_TEMPLATES_ENDPOINT,
} from "../../../../../serverInteraction/services/endpointsConstants";

type Props = {
  featureId: string;
  onDelete?: () => void;
};

export function StructureTemplatesDeleteDialog({ featureId, onDelete }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );

  const { remove } = useRemove(STRUCTURE_TEMPLATES_ENDPOINT);
  const handleDelete = async () => {
    const facetForDelete = {
      featureId: featureId,
      company: userPackage.company,
      document: userPackage.document,
    };
    try {
      await remove(facetForDelete);
      onDelete?.();
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <DeleteDialog
      title={`${t("button_delete")} ${featureId}`}
      remove={handleDelete}
    >
      <Box>{`${t("confirmDelete")} ${featureId}?`}</Box>
    </DeleteDialog>
  );
}
