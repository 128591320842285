import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import useWorkflows from './../../../../serverInteraction/hooks/content/workflow/useWorkflows';
import { WorkflowsDeleteDialog } from './delete/WorkflowsDeleteDialog';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { WorklowRenameDialog } from './rename/WorklowRenameDialog';

export function ProcessDefinitionPageList() {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);

  const { status, workflows, isLoading, isFetching, error, refetch } = useWorkflows(
    userPackage.company,
    userPackage.document,
  );

  if (isLoading || isFetching || workflows === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {JSON.parse(`${workflows}`)?.map((workflow: any) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={workflow.featureId}
          >
            <Link
              underline="hover"
              component={RouterLink}
              to={`/workflow/process-definitions/${workflow.featureId}`}
            >
              {workflow.featureId}
            </Link>
            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <Box sx={{ display: 'flex' }}>
                <WorklowRenameDialog featureId={workflow.featureId} refetch={() => refetch()} />
                <WorkflowsDeleteDialog workflow={workflow} onDelete={() => refetch()} />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
