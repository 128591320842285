import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { useTranslation } from 'react-i18next';
import { AutomationChainsCreateDialogs } from './create/AutomationChainsCreateDialog';
import { AutomationChainsView } from './AutomationChainsView';
import { AutomationsChainsListPage } from './AutomationsChainsListPage';

export const AutomationsChainsPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title={t('automationPageTitle')} />
      <PageContent subtitle={t('automationPageSubtitle')}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <AutomationChainsCreateDialogs />
          <AutomationsChainsListPage />
        </Paper>
      </PageContent>
    </Page>
  );
};
