import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import { AddDialog } from "../../../../common/dialog/AddDialog";
import useCreate from "../../../../../serverInteraction/hooks/entity/useCreate";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../../../common/input/ControlledInput";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../common/grid/GridOneToThree";
import { ControlledTextarea } from "../../../../common/textarea/ControlledTextarea";
import { CreateUpdateFacetsProps } from "../../../../../types/content/facets/CreateUpdateFacetsProps";
import { FacetProps } from "../../../../../types/content/facets/FacetProps";
import useFacets from "../../../../../serverInteraction/hooks/content/facets/useFacets";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";
import useStructureTemplates from "../../../../../serverInteraction/hooks/content/structureTemplates/useStructureTemplates";
import {
  AccessControlListProps,
  StructureTemplateProps,
} from "../../../../../types/content/structureTemplates/StructureTemplateProps";
import { GetStructureTemplateProps } from "../../../../../types/content/structureTemplates/GetStructureTemplateProps";
import { STRUCTURE_TEMPLATES_ENDPOINT } from "../../../../../serverInteraction/services/endpointsConstants";
import { CreateUpdateStructureTemplateProps } from "../../../../../types/content/structureTemplates/CreateUpdateStructureTemplateProps";
import { DefaultDocumentTypesForStructureTemplate } from "../../../../../enum/DefaultDocumentTypesForStructureTemplate";

export function StructureTemplatesCreateDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { create } = useCreate<
    CreateUpdateStructureTemplateProps,
    GetStructureTemplateProps
  >(STRUCTURE_TEMPLATES_ENDPOINT);
  const { refetch } = useStructureTemplates();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<CreateUpdateStructureTemplateProps>();

  const { structureTemplates, isLoading } = useStructureTemplates();
  if (isLoading) {
    return <Loader />;
  }
  const structureTemplatesIds = structureTemplates
    ? JSON.parse(structureTemplates?.toString()).map(
        (item: StructureTemplateProps) => item.featureId.toLowerCase()
      )
    : [];

  const isUnique = (value: string) =>
    structureTemplatesIds.indexOf(value.toLowerCase()) === -1;

  //Опции выбора targetDocеype
  //Список ключей DefaultDocumentTypesForStructureTemplate. Порядок вывода – как указано в списке (Root + остальное по алфавиту)
  //типы документов, для которых созданы шаблоны должны быть исключены из списка выбора.
  const existingDoctypes = structureTemplates
    ? JSON.parse(structureTemplates?.toString()).map(
        (item: StructureTemplateProps) => item.targetDocType.toLowerCase()
      )
    : [];

  const documentTypesList = Object.keys(
    DefaultDocumentTypesForStructureTemplate
  )
    .filter(
      (key: any) =>
        !isNaN(Number(DefaultDocumentTypesForStructureTemplate[key]))
    )
    .map((key: any) => key)
    .filter((elem) => !existingDoctypes.includes(elem.toLowerCase()));

  const onSubmit = async (
    newStructureTemplate: CreateUpdateStructureTemplateProps
  ) => {
    const data: CreateUpdateStructureTemplateProps = {
      company: userPackage.company,
      document: userPackage.document,
      featureId: newStructureTemplate.featureId,
      type: newStructureTemplate.targetDocType,
      targetDocType: newStructureTemplate.targetDocType,
      accessControlList: getDefaultAcl(newStructureTemplate.targetDocType),
      childs: getDefaultChildren(newStructureTemplate),
      deploymentMode: "MERGE",
    };

    try {
      const res: FacetProps = JSON.parse(`${await create(data)}`);
      reset();
      navigate(`${res.id}`);
    } catch (error: any) {
      console.log(error);
    }
  };

  //Для объектов с targetDocType === Root || Domain автоматически должны создаваться accessControlListJson и children по умолчанию
  function getDefaultChildren(
    data: CreateUpdateStructureTemplateProps
  ): CreateUpdateStructureTemplateProps[] {
    switch (data.targetDocType) {
      case "Root":
        return [
          {
            company: userPackage.company,
            document: userPackage.document,
            featureId: `${data.featureId}_0`,
            type: "Domain",
            targetDocType: "Domain",
            name: "default-domain",
            title: "Default Domain",
            description: "Nuxeo Default Domain",
            accessControlList: {
              elements: [],
              blockInheritance: false,
            },
            childs: [],
            deploymentMode: "MERGE",
          },
        ];
        break;
      case "Domain":
        return [
          {
            company: userPackage.company,
            document: userPackage.document,
            featureId: `${data.featureId}_0`,
            type: "WorkspaceRoot",
            targetDocType: "WorkspaceRoot",
            name: "workspaces",
            title: "Workspaces",
            description: "Workspace Root",
            accessControlList: {
              elements: [],
              blockInheritance: false,
            },
            childs: [],
            deploymentMode: "MERGE",
          },
          {
            company: userPackage.company,
            document: userPackage.document,
            featureId: `${data.featureId}_1`,
            type: "SectionRoot",
            targetDocType: "SectionRoot",
            name: "sections",
            title: "Sections",
            description: "Sections Root",
            accessControlList: {
              elements: [],
              blockInheritance: false,
            },
            childs: [],
            deploymentMode: "MERGE",
          },
          {
            company: userPackage.company,
            document: userPackage.document,
            featureId: `${data.featureId}_2`,
            type: "TemplateRoot",
            targetDocType: "TemplateRoot",
            name: "templates",
            title: "Templates",
            description: "Workspace Templates Root",
            accessControlList: {
              elements: [],
              blockInheritance: false,
            },
            childs: [],
            deploymentMode: "MERGE",
          },
        ];
        break;
    }
    return [];
  }

  function getDefaultAcl(type: string): AccessControlListProps {
    switch (type) {
      case "Root":
        return {
          elements: [
            {
              name: "Administrator",
              permission: "Everything",
              grant: true,
            },
            {
              name: "administrators",
              permission: "Everything",
              grant: true,
            },
            {
              name: "members",
              permission: "Read",
              grant: true,
            },
            {
              name: "members",
              permission: "Version",
              grant: true,
            },
          ],
          blockInheritance: false,
        };
        break;
    }
    return { elements: [], blockInheritance: false };
  }

  const validate = async () => {
    return await trigger();
  };

  // RENDER
  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("featureId", {
              required: {
                value: true,
                message: t("inputRequired").replace("%1", t("Feature ID")),
              },
              maxLength: {
                value: 40,
                message: "Must be at more 40 symbols",
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
              validate: (value) =>
                isUnique(value) ||
                t("uniqueValidation")
                  .replace("%1", t("structureTemplate"))
                  .replace("%2", value),
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
  ];

  return (
    <AddDialog
      title={`${t("button_create")} ${t("structureTemplate")}`}
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
        >
          <Typography style={{ color: "rgba(0, 0, 0, 0.6)", marginRight: 10 }}>
            {`${t("targetDoctypeSelect")} *`}
          </Typography>
          <FormControl error={errors.targetDocType !== undefined}>
            <Select
              {...register("targetDocType", {
                required: {
                  value: true,
                  message: t("inputRequired").replace("%1", t("documentType")),
                },
              })}
              native
            >
              {documentTypesList.map((item: any) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
            <FormHelperText>{errors.targetDocType?.message}</FormHelperText>
          </FormControl>
        </div>
      </Box>
    </AddDialog>
  );
}
