import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../components/app/App';
import { Resources } from '../components/pages/resources/Resources';
import { BrandingPage } from '../components/pages/branding/BrandingPage';
import { SchemasPage } from '../components/pages/content/schemas/SchemasPage';
import { BrandingEditPage } from '../components/pages/branding/edit/BrandingEditPage';
import { ProcessDefinitionsPage } from '../components/pages/content/processDefinition/ProcessDefinitionsPage';
import { DocumentTypesPage } from '../components/pages/content/documentTypes/DocumentTypesPage';
import { FacetsPage } from '../components/pages/content/facets/FacetsPage';
import { DocumentTypePage } from '../components/pages/content/documentTypes/DocumentTypePage';
import PermForDoc from '../components/pages/content/roles-and-permissions/permissions/impl/PermForDoc';
import { FacetPage } from '../components/pages/content/facets/FacetPage';
import PermissionsPage from '../components/pages/content/roles-and-permissions/permissions/Permissions';
import { LifeCyclesPage } from '../components/pages/content/life-cycles/LifeCyclesPage';
import LifeCyclePage from '../components/pages/content/life-cycles/LifeCyclePage';

import UsersGroups from '../components/pages/content/roles-and-permissions/users-and-groups/UsersGroups';
import { PackagesPage } from '../components/pages/packages/PackagesPage';
import PackagePage from '../components/pages/packages/PackagePage';
import ProfilePage from '../components/pages/profile/ProfilePage';
import SchemaPage from '../components/pages/content/schemas/SchemaPage/SchemaPage';
import ProcessDefinitionsView from '../components/pages/content/processDefinition/ProcessDefinitionsView';
import { StructureTemplatesPage } from '../components/pages/content/structureTemplates/StructureTemplatesPage';
import { StructureTemplatePage } from '../components/pages/content/structureTemplates/StructureTemplatePage/StructureTemplatePage';
import { AutomationsScriptingPage } from '../components/pages/automation/automationScripting/AutomationsScriptingPage';
import AutomationScriptingView from '../components/pages/automation/automationScripting/AutomationScriptingView';
import { AutomationsChainsPage } from '../components/pages/automation/automationChains/AutomationsChainsPage';
import { AutomationChainsView } from '../components/pages/automation/automationChains/AutomationChainsView';
import HomePage from '../components/pages/home/HomePage';
import LoginPage from '../components/pages/login/LoginPage';
import ProtectedRoute from './ProtectedRoute';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'packages',
            element: <PackagesPage />,
          },
          {
            path: 'packages/:id',
            element: <PackagePage />,
          },
          {
            path: 'resources',
            element: <Resources />,
          },
          {
            path: 'branding',
            element: <BrandingPage />,
          },
          {
            path: 'branding/:id',
            element: <BrandingEditPage />,
          },
          {
            path: 'content/schemas',
            element: <SchemasPage />,
          },
          {
            path: 'content/schemas/:featureId',
            element: <SchemaPage />,
          },
          {
            path: 'content/document-types',
            element: <DocumentTypesPage />,
          },
          {
            path: 'content/document-types/:id',
            element: <DocumentTypePage />,
          },
          {
            path: 'content/facets',
            element: <FacetsPage />,
          },
          {
            path: 'content/facets/:id',
            element: <FacetPage />,
          },
          {
            path: 'content/life-cycles',
            element: <LifeCyclesPage />,
          },
          {
            path: 'content/life-cycles/:id',
            element: <LifeCyclePage />,
          },
          {
            path: 'content/structure-templates',
            element: <StructureTemplatesPage />,
          },
          {
            path: 'content/structure-templates/:id',
            element: <StructureTemplatePage />,
          },
          {
            path: 'workflow/process-definitions',
            element: <ProcessDefinitionsPage />,
          },
          {
            path: 'workflow/process-definitions/:id',
            element: <ProcessDefinitionsView />,
          },
          {
            path: 'roles-and-permissions/permissions',
            element: <PermissionsPage />,
          },
          {
            path: 'roles-and-permissions/permissions/:featureId',
            element: <PermForDoc />,
          },
          {
            path: 'roles-and-permissions/users-and-groups',
            element: <UsersGroups />,
          },
          {
            path: 'automation/automation-scripting',
            element: <AutomationsScriptingPage />,
          },
          {
            path: 'automation/automation-scripting/:id',
            element: <AutomationScriptingView />,
          },
          {
            path: 'automation/automation-chains',
            element: <AutomationsChainsPage />,
          },
          {
            path: 'automation/automation-chains/:id',
            element: <AutomationChainsView />,
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
