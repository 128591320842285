import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tab,
  Tabs,
  Box,
  Slide,
} from '@mui/material';
import TabPanel from '../../../../../../common/tabPanel/CustomTabPanel';
import GeneralTab from './tabs/GeneralTab';
import { PackageProps } from '../../../../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import TransitionsTab from './tabs/TransitionsTab';

// interface EditNodePropertiesModalViewProps {
//   onClose: () => void;
//   data: any;
//   onSave: (updatedData: any) => void;
// }

interface EditNodePropertiesModalViewProps {
  onClose: () => void;
  data: any;
  onSave: (updatedData: any) => void;
  updateNode: (nodeId: string, updatedData: any) => void; // Новый проп
}

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditNodePropertiesModalView: React.FC<EditNodePropertiesModalViewProps> = ({
  onClose,
  data,
  onSave,
  updateNode,
}) => {
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [editedData, setEditedData] = useState(data);

  useEffect(() => {
    setEditedData(data); // Обновляем локальное состояние при изменении данных
  }, [data]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // const handleSave = () => {
  //   const updatedData = { ...editedData };

  //   if (updatedData.data.transitions.length > (updatedData.data.outputsNames?.length || 0)) {
  //     // Обновляем outputsNames на основе transitions
  //     updatedData.data.outputsNames = updatedData.data.transitions.map(
  //       (transition: any) => transition.name,
  //     );
  //   }

  //   onSave(updatedData); // Передаем обновленные данные обратно
  //   setOpen(false);
  //   onClose();
  // };

  const handleSave = () => {
    const updatedData = { ...editedData };

    if (updatedData.data.transitions.length > (updatedData.data.outputsNames?.length || 0)) {
      updatedData.data.outputsNames = updatedData.data.transitions.map(
        (transition: any) => transition.name,
      );
    }

    // Передаем изменения в ProcessForm
    updateNode(data.id, updatedData);

    onSave(updatedData);
    setOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    onClose(); // Закрываем модалку без сохранения изменений
  };

  const handleFieldChange = (key: string, value: any) => {
    setEditedData((prev: any) => ({
      ...prev,
      data: {
        ...prev.data,
        [key]: value,
      },
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(onClose, 300); // Для задержки перед закрытием
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 4,
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
          animation: `${open ? 'fadeIn' : 'fadeOut'} 0.3s ease`,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ddd',
          fontWeight: 500,
        }}
      >
        {`Edit ${data.data.label} properties`}
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 500,
            },
          }}
        >
          <Tab label="General" />
          <Tab label="Variables" />
          {data.data.parentType === 'USER_TASKS' && <Tab label="Task Buttons" />}
          <Tab label="Transitions" />
          <Tab label="Escalation Rules" />
        </Tabs>
        <Box mt={2}>
          <TabPanel value={activeTab} index={0}>
            <GeneralTab data={editedData} onChange={handleFieldChange} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            Variables
          </TabPanel>
          {data.data.parentType === 'USER_TASKS' ? (
            <TabPanel value={activeTab} index={2}>
              Task Buttons
            </TabPanel>
          ) : (
            ''
          )}
          <TabPanel value={activeTab} index={data.data.parentType === 'USER_TASKS' ? 3 : 2}>
            <TransitionsTab data={editedData} onChange={handleFieldChange} />
          </TabPanel>
          <TabPanel value={activeTab} index={data.data.parentType === 'USER_TASKS' ? 4 : 3}>
            Escalation Rules
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: '#f5f5f5',
          borderTop: '1px solid #ddd',
        }}
      >
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNodePropertiesModalView;
