import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  message: string;
  // clearMessage: () => void;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
      }, 6000); // 6 секунд

      return () => clearTimeout(timer); // Очищаем таймер при размонтировании
    }
  }, [message]);

  if (!message) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: '#f44336', // Красный цвет для ошибки
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        opacity: 0,
        animation: 'fadeInOut 6s forwards', // Добавляем анимацию
        zIndex: 10,
      }}
    >
      <Typography variant="body2" sx={{ display: 'inline-block' }}>
        {message}
      </Typography>
      <style>
        {`
          @keyframes fadeInOut {
            0% { opacity: 0; transform: translateY(-10px); }
            10% { opacity: 1; transform: translateY(0); }
            90% { opacity: 1; transform: translateY(0); }
            100% { opacity: 0; transform: translateY(-10px); }
          }
        `}
      </style>
    </Box>
  );
};

export default ErrorMessage;
