import React, { useEffect, useState } from "react"
import { ReferenceConfigurationProp, TFieldUpdate } from "../../../../../../types/content/schemas/Field"
import { TextField, Typography } from "@mui/material"
import { FieldsSelectValue } from "../../../../../../staticValues/Constants";
import { Controller, useWatch } from "react-hook-form";
interface IProps {
    field: string,
    control: any,
   
}
function FieldComment({field, control}: IProps) {
    
    //const [comment, setComment] = useState("");
    //const { name, fieldType } = field;
    //const d = field
    //const fieldTypeLabel = FieldsSelectValue.find(type => type.value === fieldType)?.label;

    //const comment = `${name || "NewField"}: ${fieldTypeLabel}`
    /*const fieldWatches = useWatch({
        control,
        name: [`${field}.name`, `${field}.fieldType`]
    });
    const d = fieldWatches[0];
    const getComment = (name: string) => {
        return `${name || "NewField"}:`
    }*/

    const getFieldTypeDescription = (fieldType: string , referenceConfiguration: ReferenceConfigurationProp | null) => {
        switch (fieldType) {
            case "DIRECTORY": 
                return `(Directory=${referenceConfiguration?.firstParameterValue || "?"})`;
            case "USER_GROUP": 
                return `(type=${referenceConfiguration?.firstParameterValue || "?"})`;
            case "DOCUMENT":
                return `(store=${referenceConfiguration?.firstParameterValue || "?"})`;
            default:
                return "";
        }
    }
    
    const getConstraints = (constraints: Array<any>) => {
        const constraintsArr = constraints.filter(elem => (elem !== null)).map(elem => {
            switch (Object.keys(elem)[0]) {
                case "mandatoryConstraint":
                    return elem.mandatoryConstraint.value ? "Field Required" : null;
                case "textFormatConstraint":
                    return `Format(Pattern=${elem.textFormatConstraint.pattern || "?"})`;
                case "textLengthConstraint":
                    const lengthStrArr: string[] = [];
                    if (elem.textLengthConstraint.minimum)
                        lengthStrArr.push(`Minimum=${elem.textLengthConstraint.minimum}`)
                    if (elem.numericRangeConstraint.maximum)
                        lengthStrArr.push(`Maximum=${elem.textLengthConstraint.maximum}`)
                    return lengthStrArr.length ? `Length(${lengthStrArr.join(", ")})` : ""
                    
                case "numericRangeConstraint":
                    const rangeStrArr: string[] = [];
                    if (elem.numericRangeConstraint.minimum)
                        rangeStrArr.push(`Minimum=${elem.numericRangeConstraint.minimum}, minimumInclusive=${elem.numericRangeConstraint.minimumInclusive ? "true": "false"}`)
                    if (elem.numericRangeConstraint.maximum)
                        rangeStrArr.push(`Maximum=${elem.numericRangeConstraint.maximum}, maximumInclusive=${elem.numericRangeConstraint.maximumInclusive ? "true": "false"}`)
                    return rangeStrArr.length ? `Range(${rangeStrArr.join(", ")})` : ""
                case "dateRangeConstraint":
                    const dateRangeStrArr: string[] = [];
                    if (elem.dateRangeConstraint.minimum)
                        dateRangeStrArr.push(`Minimum=${elem.dateRangeConstraint.minimum}, minimumInclusive=${elem.dateRangeConstraint.minimumInclusive ? "true": "false"}`)
                    if (elem.dateRangeConstraint.maximum)
                        dateRangeStrArr.push(`Maximum=${elem.dateRangeConstraint.maximum}, maximumInclusive=${elem.dateRangeConstraint.maximumInclusive ? "true": "false"}`)
                    return dateRangeStrArr.length ? `Date Range(${dateRangeStrArr.join(", ")})` : ""
                default:
                    return null;
            }})
        const constraintsStr = constraintsArr.filter(elem => elem!==null).join(", ");
        return constraintsStr.length ? `[${constraintsStr}]` : "";
        
    }

   const fieldWatches = useWatch({
        control,
        name: [`${field}`]
    });
    
    const getComment = () => {
        const { name, fieldType, referenceConfiguration, constraints} = fieldWatches[0];
        const fieldTypeLabel = FieldsSelectValue.find(type => type.value === fieldType)?.label;
        const fieldTypeDescription = getFieldTypeDescription(fieldType, referenceConfiguration);
        const fieldConstraints = constraints ? getConstraints(constraints) : "";
        return `${name || "NewField"}: ${fieldTypeLabel} ${fieldTypeDescription} ${fieldConstraints}`
    }

    const comment = getComment();

    return (
    <Typography style = {{color: "blue", fontSize: "10px", marginBottom: "4px"}}>
       {comment}
    </Typography>
  )
}

export default FieldComment