import { useTranslation } from 'react-i18next';
import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { DocumentTypesCreateDialog } from './create/DocumentTypesCreateDialog';
import { DocumentTypesPageList } from './DocumentTypesPageList'
import useValidationErrors from '../../../../serverInteraction/hooks/validation-errors/useValidationErrors';
import ValidationErrorsAccordion from '../../../common/accordion/ValidationErrorsAccordion/ValidationErrorsAccordion';

export function DocumentTypesPage() {
  const { t } = useTranslation();
  const {data: validationErrors} = useValidationErrors('document-types');
  
  return (
    <Page>
      <PageHeader title={t('titleDoctypes')}/>
      <PageContent subtitle={t('headerDoctypes')}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <ValidationErrorsAccordion 
              variant={'OBJECT'}
              errors={validationErrors}
            />
            <DocumentTypesCreateDialog />
            <DocumentTypesPageList/>
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
