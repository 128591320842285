import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { useTranslation } from "react-i18next";
import { AccessControlListElementProps } from "../../../../../../../types/content/structureTemplates/StructureTemplateProps";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DeleteAclDialog } from "./dialog/DeleteAcl/DeleteAclDialog";
import { CreateAclDialog } from "./dialog/CreateAcl/CreateAclDialog";
import { UpdateAclDialog } from "./dialog/UpdateAcl/UpdateAclDialog";
import { blockAcl, defaultAcl } from "./dialog/EditAclDialog/defaultValues";

interface IProps {
  fieldsName: string;
}

export function AclForm({ fieldsName }: IProps) {
  const { t } = useTranslation();
  const [selectedAclIndex, setSelectedAclIndex] = useState<number | undefined>(
    undefined
  );

  const {
    control,
    setValue,
    getValues,
    register,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { append, remove, update, fields } = useFieldArray({
    name: fieldsName,
    control: control,
  });

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedAclIndex(index);
  };

  const handleBlock = () => {
    append(blockAcl);
  };

  const handleDelete = () => {
    remove(selectedAclIndex);
    setSelectedAclIndex(undefined);
  };

  // RENDERING
  const getAclItemText = (acl: AccessControlListElementProps) => {
    return `${acl.grant ? t("grantPermission") : t("denyPermission")} '${
      acl.permission
    }' ${t("for")} ${acl.name}`;
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography>Document ACL</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "end",
            border: "1px solid grey",
            minWidth: "75%",
          }}
        >
          <UpdateAclDialog
            update={update}
            selectedIndex={selectedAclIndex}
            initialValue={
              selectedAclIndex !== undefined
                ? (fields[
                    selectedAclIndex
                  ] as unknown as AccessControlListElementProps)
                : defaultAcl
            }
            namePrefix={fieldsName}
            control={control}
            setValue={setValue}
            getValues={getValues}
            register={register}
            trigger={trigger}
            errors={errors}
          />
          <CreateAclDialog
            append={append}
            update={update}
            remove={remove}
            addedIndex={fields.length}
            namePrefix={fieldsName}
            control={control}
            setValue={setValue}
            getValues={getValues}
            register={register}
            trigger={trigger}
            errors={errors}
          />
          <Button startIcon={<KeyboardTabIcon />} onClick={handleBlock}>
            {t("blockInheritance")}
          </Button>
          <DeleteAclDialog
            selectedAclIndex={selectedAclIndex}
            onDelete={handleDelete}
          />
        </Box>
      </Box>
      <List
        sx={{
          maxHeight: "150px",
          minHeight: "150px",
          overflow: "auto",
          border: "1px solid grey",
        }}
      >
        {fields.map((elem: any, index: number) => (
          <ListItemButton
            key={index}
            selected={index === selectedAclIndex}
            onClick={(event) => handleListItemClick(event, index)}
            sx={{ padding: "0 16px" }}
          >
            <ListItemText primary={getAclItemText(elem)} key={index} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
