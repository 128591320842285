import { AxiosError } from 'axios';
import useAlert from '../../../context/alertContext/useAlert';
import { BaseEntityProps } from '../../../types/BaseEntityProps';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import EntityService from '../../services/EntityInteractionService';

const usePatch = <T1, T2 extends BaseEntityProps>(
  entityName: string,
  onSuccess?: (entity: T2) => void,
  onError?: (error: any) => void,
) => {
  const { showSuccess, showError } = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(EntityService.patch<T1, T2>, {
    onSuccess: onSuccess
      ? onSuccess
      : (entity: T2) => {
          queryClient.setQueryData<T2 | undefined>([entityName, entity.id], () => {
            showSuccess('Saved successfully!');
            return entity;
          });
        },
    onError: onError
      ? onError
      : (error) => {
          console.log(error);

          if (error instanceof AxiosError) {
            showError(error.message);
          }
        },
  });

  const patch = async (updateProps: T1) => {
    return mutateAsync({ entityName, updateProps });
  };

  return { patch, isUpdating: isLoading };
};

export default usePatch;
