import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Tabs, Tab, Box, Button, Typography } from '@mui/material';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import DefinitionForm from './tabs/DefinitionForm'; // Дочерний компонент
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWorkflows from '../../../../serverInteraction/hooks/content/workflow/useWorkflows';
import { AxiosError } from 'axios';
import { Loader } from '../../../common/loader/Loader';
import GraphView from './tabs/Graph/GraphView';
import { WORKFLOWS_ENDPOINT } from '../../../../serverInteraction/services/endpointsConstants';
import useUpdate from '../../../../serverInteraction/hooks/entity/useUpdate';
import { Edge, Node } from 'react-flow-renderer';
import TabPanel from '../../../common/tabPanel/CustomTabPanel';
import { v4 as uuidv4 } from 'uuid';
import ActivasionTab from './tabs/ActivasionTab';

interface FormInputs {
  label: string;
  iconUrl: string;
  largeIconUrl: string;
  description: string;
  variables: any;
  activation: any;
  graph: {
    nodes: Node[];
    edges: Edge[];
  };
}

const ProcessForm: React.FC = () => {
  const { id } = useParams();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, isLoading, isFetching, error, workflows } = useWorkflows(
    userPackage.company,
    userPackage.document,
    id,
  );

  const { update } = useUpdate(WORKFLOWS_ENDPOINT);
  const methods = useForm<FormInputs>();

  const [selectedTab, setSelectedTab] = useState<number>(2);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (workflows) {
      const initialData = JSON.parse(`${workflows}`);
      const { label, description, iconUrl, largeIconUrl, graph } = initialData;
      methods.reset({
        label,
        description,
        iconUrl,
        largeIconUrl,
        variables: initialData.variables || {},
        activation: initialData.activation || {},
        graph: graph || { nodes: [], edges: [] },
      });

      // Инициализируем узлы и рёбра из графа
      if (graph) {
        setNodes(graph.nodes || []);
        setEdges(graph.edges || []);
      }
    }
  }, [workflows, methods]);

  if (isLoading || isFetching || workflows === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const onSubmit = async (data: FormInputs) => {
    const updateData = {
      featureId: id,
      label: data.label,
      iconUrl: data.iconUrl,
      largeIconUrl: data.largeIconUrl,
      description: data.description,
      activation: data.activation, // Данные с вкладки "Activation"
      workflowNodeUpsertRequestList: nodes.map((node) => ({
        id: `${uuidv4()}`,
        title: node.data.label,
        type: node.data.parentType,
        subtype: node.data.subtype,
        userTaskNodeGeneralProperties: {
          directive: node.data.directive || '',
          dueDateExpression: node.data.dueDateExpression || '',
          assignees: node.data.assignees || [],
          assigneesExpression: node.data.assigneesExpression || '',
          allowTaskReassignment: node.data.allowTaskReassignment || false,
          grantPermission: node.data.grantPermission || '',
          mailNotification: node.data.mailNotification || '',
          taskDocumentType: node.data.taskDocumentType || '',
        },
        workflowFieldList: {
          name: `Field List ${node.id}`,
          prefix: 'FL',
          fieldList: node.data.fieldList || [],
        },
        escalationRules: node.data.escalationRules || [],
        pointX: node.position.x,
        pointY: node.position.y,
        width: 100,
        height: 200,
        isStartNode: node.data.isStartNode || false,
        isEndNode: node.data.isEndNode || false,
        allowsIncomingConnections: node.data.allowsIncomingConnections || false,
        automationId: node.data.automationId || '',
      })),
      workflowNodeTransitionUpsertRequestList: edges.map((edge) => ({
        name: edge.label || '',
        condition: edge.data?.condition || '',
        automationId: edge.data?.automationId || '',
        workflowNodeFeatureIdSource: edge.source || '',
        workflowNodeFeatureIdTarget: edge.target || '',
        sourceAnchor: 0.5,
      })),
      graphEditorPointX: 1,
      graphEditorPointY: 2,
      company: userPackage.company,
      document: userPackage.document,
    };

    console.log('Submitting Data:', updateData);
    await update(`${id}`, updateData);
  };

  // const onSubmit = async (data: FormInputs) => {
  //   const mappedNodes = nodes.map((node) => ({
  //     id: `${uuidv4()}`,
  //     title: node.data.label,
  //     type: node.data.parentType,
  //     // subtype: node.data.label.toUpperCase().replace(/ /g, '_'),
  //     subtype: node.data.subtype,
  //     userTaskNodeGeneralProperties: {
  //       directive: node.data.directive || '',

  //       dueDateExpression: node.data.dueDateExpression || '',
  //       assignees: node.data.assignees || [],
  //       assigneesExpression: node.data.assigneesExpression || '',
  //       allowTaskReassignment: node.data.allowTaskReassignment || false,
  //       grantPermission: node.data.grantPermission || '',
  //       mailNotification: node.data.mailNotification || '',
  //       taskDocumentType: node.data.taskDocumentType || '',
  //     },
  //     workflowFieldList: {
  //       name: `Field List ${node.id}`,
  //       prefix: 'FL',
  //       fieldList: node.data.fieldList || [],
  //     },
  //     escalationRules: node.data.escalationRules || [],
  //     pointX: node.position.x,
  //     pointY: node.position.y,
  //     width: 100, // Или другая логика для ширины узла
  //     height: 200, // Или другая логика для высоты узла
  //     isStartNode: node.data.isStartNode || false,
  //     isEndNode: node.data.isEndNode || false,
  //     allowsIncomingConnections: node.data.allowsIncomingConnections || false,
  //     // automationId: node.data.automationId || null,
  //     automationId: node.data.automationId || '',
  //   }));

  //   const mappedEdges = edges.map((edge) => ({
  //     name: edge.label || '',
  //     condition: edge.data?.condition || '',
  //     automationId: edge.data?.automationId || '',
  //     workflowNodeFeatureIdSource: edge.source || '',
  //     workflowNodeFeatureIdTarget: edge.target || '',
  //     // sourceAnchor: edge.sourceHandle ? parseFloat(edge.sourceHandle) : 0.5,
  //     sourceAnchor: 0.5,
  //   }));

  //   const updateData = {
  //     featureId: id,
  //     label: data.label,
  //     iconUrl: data.iconUrl,
  //     largeIconUrl: data.largeIconUrl,
  //     description: data.description,
  //     workflowNodeUpsertRequestList: mappedNodes,
  //     workflowNodeTransitionUpsertRequestList: mappedEdges,
  //     graphEditorPointX: 1, // Логика для позиции редактора
  //     graphEditorPointY: 2, // Логика для позиции редактора
  //     workflowFieldList: {
  //       name: 'Field List 1',
  //       prefix: 'FL',
  //       fieldList: [
  //         // Можно добавить маппинг для полей, если они есть в форме
  //       ],
  //     },
  //     company: userPackage.company,
  //     document: userPackage.document,
  //   };

  //   console.log('Mapped Data:', updateData);
  //   await update(`${id}`, updateData);
  // };

  return (
    <Box sx={{ width: '100%', p: 3, mt: '50px' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="process tabs">
            <Tab label="Definition" />
            <Tab label="Variables" />
            <Tab label="Activation" />
            <Tab label="Graph" />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <DefinitionForm />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}></TabPanel>

          <TabPanel value={selectedTab} index={2}>
            <ActivasionTab />
          </TabPanel>

          <TabPanel value={selectedTab} index={3}>
            <GraphView nodes={nodes} edges={edges} setNodes={setNodes} setEdges={setEdges} />
          </TabPanel>

          <Button type="submit" variant="contained" color="primary">
            Submit All Data
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ProcessForm;
