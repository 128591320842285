import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import usePatch from '../../../../../serverInteraction/hooks/entity/usePatch';
import { useForm } from 'react-hook-form';
import { AUTOMATION_CHAINS } from '../../../../../serverInteraction/services/endpointsConstants';
import { BaseEntityProps } from '../../../../../types/BaseEntityProps';
import { Loader } from '../../../../common/loader/Loader';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import RenameDialog from '../../../../common/dialog/RenameDialog';
import { Box } from '@mui/material';
import { t } from 'i18next';
import {
  AutomationChainsProps,
  AutomationChainsRenameProps,
} from '../../../../../types/automation/automationChains/AutomationChainsProps';

type Props = {
  featureId: string;
  refetch: () => void;
};

export function AutomationChainsRenameDialog({ featureId, refetch }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { patch, isUpdating } = usePatch<
    AutomationChainsRenameProps,
    AutomationChainsProps & BaseEntityProps
  >(AUTOMATION_CHAINS);
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<AutomationChainsRenameProps>({
    defaultValues: {
      oldFeatureId: featureId,
      company: userPackage.company,
      document: userPackage.document,
    },
  });

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('newFeatureId', {
              required: {
                value: true,
                message: 'Feature ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              maxLength: {
                value: 40,
                message: 'Must be at more 40 symbols',
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
            }),
          }}
          helperText={errors.newFeatureId?.message}
          error={errors.newFeatureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];

  const onSubmit = async (data: AutomationChainsRenameProps) => {
    isUpdating && <Loader />;
    try {
      const res: AutomationChainsProps = JSON.parse(`${await patch(data)}`);
      reset();
    } catch (error: any) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <RenameDialog
      title={`${t('rename')} ${featureId}`}
      update={handleSubmit(onSubmit)}
      validate={validate}
      isUpdate={refetch}
    >
      <hr />
      <Box sx={{ height: 'auto', marginTop: 1 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </RenameDialog>
  );
}
