import {
  Context,
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserProfile } from "../../types/auth/user";
import { IUserContext } from "./types";
import BaseServerInteractionService from "../../serverInteraction/services/BaseServerInteractionService";
import { AuthProps, LoginProps } from "../../types/auth/auth";
import { AUTH_LOGIN } from "../../serverInteraction/services/endpointsConstants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setCompany, setUserPackage } from "../../store/packages/packagesSlice";
import { useDispatch } from "react-redux";
import { PackageProps } from "../../types/packages/PackageProps";
import { initialState as initialStatePackage } from "../../store/packages/constants";

const UserContext = createContext<IUserContext | undefined>(undefined);

type Props = PropsWithChildren;

const UserProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("nuxeoToken");
    const refreshToken = localStorage.getItem("nuxeoRefreshToken");
    const savedUser: UserProfile | null = JSON.parse(
      localStorage.getItem("nuxeoUser") || "{}"
    );
    if (savedUser && token && refreshToken) {
      setUser(savedUser);
      setToken(token);
      setRefreshToken(refreshToken);
      //Установим package и перенаправим дальше
      const newPackage: {
        company: string | undefined;
        document: string | undefined;
      } = initUserPackage(savedUser.company);
      navigate(getNavigateTo(newPackage.company, newPackage.document, "/"));
    }
    setIsReady(true);
  }, []);

  const login = async (loginData: LoginProps, from: string) => {
    try {
      setIsFetching(true);
      //const response = await apiClient1.post(`/${AUTH_LOGIN}`, formData);
      const res = await BaseServerInteractionService.Post<string>(
        `/${AUTH_LOGIN}`,
        loginData
      );
      const authData: AuthProps = JSON.parse(res);
      localStorage.setItem("nuxeoToken", authData.token);
      localStorage.setItem("nuxeoRefreshToken", authData.refreshToken);
      const userObj = {
        company: authData.company,
        name: authData.name,
        surname: authData.surname,
        username: authData.username,
        email: authData.username,
      };
      localStorage.setItem("nuxeoUser", JSON.stringify(userObj));
      setToken(authData.token);
      setUser(userObj);
      setError(null);
      //Установим package и перенаправим дальше
      const newPackage: {
        company: string | undefined;
        document: string | undefined;
      } = initUserPackage(userObj.company);
      navigate(getNavigateTo(newPackage.company, newPackage.document, from));
    } catch (error: any) {
      const errMessage = error?.response?.data
        ? JSON.parse(error.response.data).message || t("error")
        : t("error");
      setError(errMessage);
    } finally {
      setIsFetching(false);
    }
  };

  function initUserPackage(company: string | null): {
    company: string | undefined;
    document: string | undefined;
  } {
    const savingPackage = safeJsonParse<PackageProps>(
      localStorage.getItem("nuxeoPackage") || "{}"
    );
    if (company) {
      if (company === savingPackage?.company) {
        dispatch(setUserPackage({ userPackage: savingPackage }));
        return {
          company: savingPackage.company,
          document: savingPackage.document,
        };
      } else {
        dispatch(setCompany({ company: company }));
        return { company: company, document: undefined };
      }
    }
    return { company: undefined, document: undefined };
  }

  function safeJsonParse<T>(str: string) {
    try {
      const jsonValue: T = JSON.parse(str);
      return jsonValue;
    } catch {
      return undefined;
    }
  }

  //Определяем роут перехода
  //При наличии package идем на from
  //Иначе на profile
  function getNavigateTo(
    company: string | undefined,
    document: string | undefined,
    from: string
  ) {
    return company?.length && document?.length ? from : "/profile";
  }

  const isLoggedIn = () => {
    return !!user;
  };

  const logout = () => {
    dispatch(setUserPackage({ userPackage: initialStatePackage }));
    localStorage.removeItem("nuxeoToken");
    localStorage.removeItem("nuxeoRefreshToken");
    localStorage.removeItem("nuxeoUser");
    localStorage.removeItem("nuxeoPackage");
    setUser(null);
    setToken("");
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{
        login,
        user,
        token,
        refreshToken,
        logout,
        isLoggedIn,
        isFetching,
        error,
      }}
    >
      {isReady ? children : null}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext<IUserContext>(
    UserContext as unknown as Context<IUserContext>
  );

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUserContext };
