import { Box, FormControl, Stack } from "@mui/material";
import { LabelUserGroupContains } from "../validationViews/ValidationLabels";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ListDocsValueStored } from "../../../../../../../staticValues/Constants";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldIndex: number,
    register: any,
    control: any,
    setValue: any,
    errors: any,
}

const DocumentAttrs = ({ schemaName, fieldName, fieldIndex,register, control, errors, setValue}: IProps) => {
    const { t } = useTranslation();
    const hadleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(`fields.${fieldIndex}.referenceConfiguration.firstParameterValue`, event.target.value, {shouldValidate: true});
      
    };

    
    return(
        <Box>
            <Stack display='flex' flexDirection='row' marginTop='1em'>
                <Controller
                    name={`fields.${fieldIndex}.referenceConfiguration.firstParameterValue`}
                    control={control}
                    render={({ field: {value, onChange} }) => (
                        <FormControl variant="outlined">
                            <ControlledSelect
                                value = {value}
                                valueList={ListDocsValueStored}
                                
                                size="small"
                                label={t('documentValueStored')}
                                sx={{
                                    width: 250
                                }}
                                {...register(`fields.${fieldIndex}.referenceConfiguration.firstParameterValue`,{
                                    required: {
                                    value: true,
                                    message: t('inputRequired').replace('%1', t('documentValueStored'))
                                }
                            })}
                            isTranslate={true}
                            helperText={errors.fields ? errors.fields[fieldIndex]?.referenceConfiguration.firstParameterValue.message : undefined}
                            error={errors.fields ? errors.fields[fieldIndex]?.referenceConfiguration.firstParameterValue !== undefined : undefined}
                            onChange={onChange}
                            />
                        </FormControl>
                        )}

                 
                        />
                    </Stack>
                    <LabelUserGroupContains schema={schemaName} field={fieldName} />  
                
        </Box>
    );
}

export default DocumentAttrs;