import { AxiosError } from "axios";
import useAlert from "../../../context/alertContext/useAlert";
import useLoader from "../../../context/loaderContext/useLoader";
import { useMutation } from "@tanstack/react-query";
import EntityService from "../../services/EntityInteractionService";
import { BaseEntityProps } from "../../../types/BaseEntityProps";

const useRemove = <T1, T2 extends BaseEntityProps>(
  entityName: string,
  onSuccess?: (result: any) => void,
  onError?: (error: any) => void,
) => {
  const { showSuccess, showError } = useAlert();
  const { setIsLoaderOpen } = useLoader();
  

  const { mutateAsync, isLoading } = useMutation(EntityService.remove<T1, T2>, {
    onMutate: () => setIsLoaderOpen(true),
    onSuccess: onSuccess 
      ? onSuccess
      : () => {
        showSuccess('Deleted successfully!');
      },
    onError: onError
      ? onError
      : (error) => {
          console.log(error);

          if (error instanceof AxiosError) {
            showError(error.message);
          }
        },
    onSettled: () => setIsLoaderOpen(false),
  });

  const remove = async (removeProps: T1) => {
    return mutateAsync({ entityName, removeProps });
  };

  return { remove, isCreating: isLoading };
};

export default useRemove;
