export const DefaultSchemasForDocument: string[] = [ 
    'audio',
    'blog',
    'blogpost',
    'collection',
    'collectionMember',
    'common',
    'contextualLink',
    'domain',
    'dublincore',
    'facetedTag',
    'file',
    'files',
    'image_metadata',
    'iptc',
    'mail',
    'note',
    'picture',
    'picturebook',
    'post',
    'protocol',
    'publishing',
    'task',
    'thread',
    'uid',
    'video',
    'webcontainer',
    'webpage'
]