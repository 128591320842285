import { WorkflowProps } from "../../../../types/content/workflows/WorkflowProps";
import { WORKFLOWS_ENDPOINT } from "../../../services/endpointsConstants";
import useGetAll from "../../entity/useGetAll";

const useWorkflows = (
  company: string,
  document: string,
  featureId?: string
) => {
  let entityName = "";

  if (featureId) {
    entityName = `workflows?company=${company}&document=${document}&featureId=${featureId}`;
  } else {
    entityName = `workflows?company=${company}&document=${document}`;
  }

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<WorkflowProps>(entityName);

  return {
    status,
    workflows: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useWorkflows;
