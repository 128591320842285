import { PropsWithChildren, useState } from "react";

import { Stack, Button, Box, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SimpleModal } from "../../../../../../../../common/modal/SimpleModal";

type Props = {
  isOpen: boolean;
  title: string;
  label: string;
  handleClose: () => void;
};

export function NoEntityWarningDialog({
  isOpen,
  title,
  label,
  handleClose,
}: Props) {
  const { t } = useTranslation();

  return (
    <SimpleModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>{t("warning").toLocaleUpperCase()}</Typography>
        </Box>
        <Divider sx={{ margin: "8px 0" }} />
        <Box>
          <Typography>{label}</Typography>
        </Box>
        <Stack
          direction="row"
          spacing={3}
          alignItems="right"
          justifyContent="end"
          sx={{ pt: 3 }}
        >
          <Button variant="outlined" onClick={handleClose} sx={{ width: 150 }}>
            OK
          </Button>
        </Stack>
      </Box>
    </SimpleModal>
  );
}
