import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../types/packages/PackageProps';
import useAutomationChains from '../../../../serverInteraction/hooks/automation/automationChains/useAutomationChains';
import { AutomationChainsProps } from '../../../../types/automation/automationChains/AutomationChainsProps';
import { AutomationChainsDeleteDialog } from './delete/AutomationChainsDeleteDialog';
import { AutomationChainsRenameDialog } from './rename/AutomationChainsRenameDialog';
import AutomationChainsCopyDialog from './copy/AutomationChainsCopyDialog';

export function AutomationsChainsListPage() {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, automationChains, isLoading, isFetching, error, refetch } = useAutomationChains(
    userPackage.company,
    userPackage.document,
  );

  if (isLoading || isFetching || automationChains === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {JSON.parse(`${automationChains}`).map((autoScript: AutomationChainsProps) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={autoScript.featureId}
          >
            <Link underline="hover" component={RouterLink} to={`${autoScript.featureId}`}>
              {autoScript.featureId}
            </Link>
            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <Box sx={{ display: 'flex' }}>
                <AutomationChainsRenameDialog
                  featureId={autoScript.featureId}
                  refetch={() => refetch()}
                />

                <AutomationChainsCopyDialog
                  featureId={autoScript.featureId}
                  existingIds={JSON.parse(`${automationChains}`).map((elem: any) =>
                    elem.featureId.toLowerCase(),
                  )}
                  refetch={() => refetch()}
                />
                <AutomationChainsDeleteDialog autoScript={autoScript} onDelete={() => refetch()} />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
