import { useSelector } from 'react-redux';
import { STRUCTURE_TEMPLATES_ENDPOINT } from '../../../services/endpointsConstants';
import useGet from '../../entity/useGet';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { GetStructureTemplateProps } from '../../../../types/content/structureTemplates/GetStructureTemplateProps';

const useStructureTemplate = (id: string) => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const params = `company=${userPackage.company}&document=${userPackage.document}&id=${id}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<GetStructureTemplateProps>(
    STRUCTURE_TEMPLATES_ENDPOINT,
    params,
  );
  
  return {
    status,
    structureTemplate: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useStructureTemplate;
