import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import {
  AUTOMATION_CHAINS,
  FACETS_ENDPOINT,
} from '../../../../../serverInteraction/services/endpointsConstants';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { Loader } from '../../../../common/loader/Loader';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { CopyContentDialog } from '../../../../common/dialog/CopyContentDialog';

import { FacetProps, FacetWithJsonProps } from '../../../../../types/content/facets/FacetProps';
import { CreateUpdateFacetsProps } from '../../../../../types/content/facets/CreateUpdateFacetsProps';
import {
  AutomationChainsProps,
  UpdateAutomationChainsProps,
} from '../../../../../types/automation/automationChains/AutomationChainsProps';

type Props = {
  featureId: string;
  existingIds: string[];
  refetch: () => void;
};

function FacetsCopyAutomationChainsCopyDialogDialog({ featureId, existingIds, refetch }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);

  const { create, isCreating } = useCreate<UpdateAutomationChainsProps, AutomationChainsProps>(
    AUTOMATION_CHAINS,
  );

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<AutomationChainsProps>();

  const onSubmit = async (data: UpdateAutomationChainsProps) => {
    isCreating && <Loader />;
    try {
      const updateData: UpdateAutomationChainsProps = {
        featureId: data.featureId,
        company: userPackage.company,
        document: userPackage.document,
        operations: data.operations,
        parameters: data.parameters,
      };
      const res: AutomationChainsProps = JSON.parse(`${await create(updateData)}`);
      reset();
    } catch (error: any) {
      console.log(error);
    }
  };

  const isUnique = (value: string) => existingIds.indexOf(value.toLowerCase()) === -1;

  const validate = async () => {
    return await trigger();
  };

  // RENDER
  const copyItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              required: {
                value: true,
                message: 'Feature ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              maxLength: {
                value: 40,
                message: 'Must be at more 40 symbols',
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
              validate: (value) =>
                isUnique(value) ||
                t('uniqueValidation').replace('%1', 'Facet').replace('%2', value),
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];

  return (
    <CopyContentDialog
      title={`${t('copy')} ${featureId}`}
      copyContent={handleSubmit(onSubmit)}
      validate={validate}
      isCopyContent={refetch}
    >
      <Box sx={{ height: 'auto', marginTop: 1 }}>
        <GridOneToThree items={copyItems} />
      </Box>
    </CopyContentDialog>
  );
}

export default FacetsCopyAutomationChainsCopyDialogDialog;
