import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, BoxProps, Box, Typography, Divider, List, ListItemButton, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ListDefaultFeatureForDirectory } from '../../../../../../../staticValues/Constants';
import { SimpleModal } from '../../../../../../common/modal/SimpleModal';


interface IProps {
    buttonTitle: string,
    onConfirm: (value: string) => void,
    initialValue: string
  /*title: string;
  update: () => Promise<void>;
  validate?: () => Promise<boolean>;
  isUpdate?: () => void;*/
} ;

function DirectoryNameDialog({ buttonTitle, onConfirm, initialValue }: IProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [directoryName, setDirectoryName] = useState(initialValue || '');

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    elem: string,
  ) => {
    setDirectoryName(elem);
  };
  
  const handleDialogButton = () => {
    setIsOpen(true);
    
  }

  const handleClose = () => {
    setIsOpen(false);
  }
  
  const handleConfirm = () => {
    onConfirm(directoryName);
    setIsOpen(false);
  }

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{marginTop: '2px'}}
        onClick={handleDialogButton}
      >
        {buttonTitle}
      </Button>
      <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
        <DialogTitle>
          {t('selectFeature')}
        </DialogTitle>
        <DialogContent>
          <List style={{width: '400px', maxHeight: '300px', overflow: 'auto', margin: '0 auto'}}>
            {ListDefaultFeatureForDirectory.map((elem: string) => (
            <ListItemButton
                key={elem}
                selected={directoryName === elem}
                onClick={(event) => handleListItemClick(event, elem)}
                style={{borderLeft: directoryName === elem ? '2px solid blue': 'none'}}
            >
                <ListItemText primary={elem} key={elem} />
            </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained" 
            onClick={handleConfirm}
          >
            {buttonTitle}
          </Button>
          <Button 
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>  
    </div>
  );
}

export default DirectoryNameDialog;
