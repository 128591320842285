import { List, ListItem, Typography } from "@mui/material";
import { ValidationErrorProps } from "../../../../types/validation-errors/ValidationErrorProps";
import { uniq } from "lodash";
import ValidationErrorsAccordionItem from "./ValidationErrorsAccordionItem";

interface IProps {
    variant: 'OBJECT' | 'ENTITY',
    errors: ValidationErrorProps[]
}

const ValidationErrorsAccordionDetails = ({ variant, errors}: IProps) => {
    
    if ( variant === 'OBJECT'){
      const featureIds = uniq(errors.map(elem => elem.featureId));
      return (
        <List sx = {{padding: 0}}>
          {featureIds.map (entity => (
            <ListItem sx = {{display: 'flex', flexDirection: 'column', alignItems: 'start', padding: 0}}>
              <Typography sx = {{ fontSize: '13px', fontWeight: 700}}>
                {entity}
              </Typography>
              <List sx = {{padding: 0}}>
                {errors.filter((error:any) => error.featureId === entity).map(error => (
                  <ValidationErrorsAccordionItem 
                    featureId={error.featureId}
                    label = {error.errorId}
                  />
                ))}
              </List>
            </ListItem>))}
        </List>
      )
    }

    // variant === ENTITY
    
    return (
      <List sx = {{padding: 0}}>
        {errors.map(error => (
          <ValidationErrorsAccordionItem 
            featureId={error.featureId}
            label = {error.errorId}
          />
        ))}
      </List>
    )
}

export default ValidationErrorsAccordionDetails;
