import { Paper, Stack } from "@mui/material";
import { Page } from "../../../common/page/Page";
import { PageContent } from "../../../common/page/PageContent";
import { PageHeader } from "../../../common/page/PageHeader";

import { useTranslation } from "react-i18next";
import { StructureTemplatesList } from "./StructureTemplatesList";
import { StructureTemplatesCreateDialog } from "./create/StructureTemplatesCreateDialog";

export const StructureTemplatesPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader title={t("structureTemplate")} />
      <PageContent subtitle={t("structureTemplatesSubtitle")}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <StructureTemplatesCreateDialog />
            <StructureTemplatesList />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
};
