import { Trans } from "react-i18next";
import { SidebarItemProps } from "./SidebarItem";

const configurationList: SidebarItemProps[] = [
    { label: 'Packages', link: 'packages' },
    { label: 'Resources', link: 'resources' },
    { label: 'Branding', link: 'branding' },
    {
      label: 'Content Model',
      link: 'content',
      children: [
        {
          label: <Trans i18nKey={'schemas'} />,
          link: 'schemas',
        },
        { label: 'Document Types', link: 'document-types' },
        { label: 'Facets', link: 'facets' },
        { label: 'Life Cycles', link: 'life-cycles' },
        { label: 'Structure Templates', link: 'structure-templates' },
      ],
    },
    {
      label: 'Workflow',
      link: 'workflow',
      children: [
        {
          label: 'Process Definitions',
          link: 'process-definitions',
        },
      ],
    },
    {
      label: 'Search',
      link: 'search',
      children: [
        {
          label: 'Page Providers',
          link: 'page-providers',
        },
        {
          label: 'Elasticsearch Mapping',
          link: 'elasticsearch-mapping',
        },
      ],
    },
    {
      label: 'Automation',
      link: 'automation',
      children: [
        {
          label: 'Automation Chains',
          link: 'automation-chains',
        },
        {
          label: 'Automation Scripting',
          link: 'automation-scripting',
        },
        {
          label: 'Event Handlers',
          link: 'event-handlers',
        },
      ],
    },
    {
      label: 'Templates',
      link: 'templates',
      children: [
        {
          label: 'Document Templates',
          link: 'document-templates',
        },
        {
          label: 'Mail Templates',
          link: 'mail-templates',
        },
      ],
    },
    {
      label: 'Roles & Permissions',
      link: 'roles-and-permissions',
      children: [
        {
          label: <Trans i18nKey={'perms_title'} />,
          link: 'permissions',
        },
        {
          label: 'Users & Groups',
          link: 'users-and-groups',
        },
      ],
    },
    { label: 'Vocabulaties', link: 'vocabulaties' },
    {
      label: 'Advanced Settings',
      link: 'advanced-settings',
      children: [
        {
          label: 'Deployment Extensions',
          link: 'deployment-extensions',
        },
        {
          label: 'Web Services Filtering',
          link: 'web-services-filtering',
        },
        {
          label: 'XML Extensions',
          link: 'xml  -extensions',
        },
      ],
    },
    { label: 'External Templates', link: 'external-templates' },
    { label: 'Migration Services', link: 'migration-services' },
  ];
  
const sourceControlList: SidebarItemProps[] = [
    { label: 'Branch Management', link: 'branch-management' },
    { label: 'Releases & Tags', link: 'releases-and-tags' },
    { label: 'Commit Preferences', link: 'commit-preferences' },
  ];
  
const settingsList: SidebarItemProps[] = [
    { label: 'Application Information', link: 'application-information' },
    { label: 'Application Definition', link: 'application-definition' },
    {
      label: 'Registries',
      link: 'registries',
      children: [
        {
          label: 'Automation Operations',
          link: 'automation-operations',
        },
        {
          label: 'Document Types',
          link: 'document-types',
        },
        {
          label: 'Document Schemas',
          link: 'document-schemas',
        },
        {
          label: 'Document Facets',
          link: 'document-facets',
        },
        {
          label: 'Core Events',
          link: 'core-events',
        },
        {
          label: 'Permissions',
          link: 'permissions',
        },
        {
          label: 'Life Cycles',
          link: 'life-cycles',
        },
      ],
    },
    {
      label: 'Automatic Registries',
      link: 'automatic-registries',
      children: [
        {
          label: 'Automation Operations',
          link: 'automation-operations',
        },
        {
          label: 'Document Types',
          link: 'document-types',
        },
        {
          label: 'Document Schemas',
          link: 'document-schemas',
        },
        {
          label: 'Document Facets',
          link: 'document-facets',
        },
        {
          label: 'Core Events',
          link: 'core-events',
        },
        {
          label: 'Permissions',
          link: 'permissions',
        },
        {
          label: 'Life Cycles',
          link: 'life-cycles',
        },
      ],
    },
  ];
  
export const lists = [
    { id: 'configurationList', label: 'Configuration', items: configurationList },
    { id: 'sourceControlList', label: 'Source Control', items: sourceControlList },
    { id: 'settingsList', label: 'Settings', items: settingsList },
  ];
  
export const drawerWidth = 300;
  