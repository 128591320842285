import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import IconMoreInfo from "../../../../common/button/iconButton/MoreInfo";

export const Subtitle = () => {
  const { t } = useTranslation();

  //RENDER
  const getInfoTitle = () => {
    return (
      <>
        <Typography color="inherit">{t("info_title1")}</Typography>
        {t("structureTemplatesSubtitle")}
        <Typography color="inherit" marginTop={1}>
          {t("info_title2")}
        </Typography>
        {t("info_structureTemplateHowTo")}
        <Typography color="inherit" marginTop={1}>
          {t("warning")}
        </Typography>
        {t("info_structureTemplateWarning")}
      </>
    );
  };

  return (
    <Typography>
      {t("structureTemplateSubtitle")}
      <IconMoreInfo title={getInfoTitle()} />
    </Typography>
  );
};
