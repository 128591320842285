import { Box, FormControl, MenuItem, NativeSelect, Select, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AddButton } from '../../../../common/button/AddButton';
import { DeleteIconButton } from '../../../../common/button/iconButton/DeleteIconButton';

export const Parameters = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [parameters, setParameters] = useState(getValues('parameters') || []);

  const staticTypesParameters = [
    'Blob',
    'Bloblist',
    'Boolean',
    'Date',
    'Document',
    'DocumentList',
    'Floating point',
    'Integer',
    'Properties',
    'Resource',
    'Script',
    'String',
    'StringList',
  ];

  const addParam = () => {
    const newParam = { name: 'new name', type: 'String' };
    const updatedParameters = [...parameters, newParam];
    setParameters(updatedParameters); // Обновляем локальное состояние
    setValue('parameters', updatedParameters, { shouldDirty: true }); // Обновляем состояние формы
  };

  const deleteParam = (index: number) => {
    const updatedParameters = parameters.filter((_: any, i: any) => i !== index);
    setParameters(updatedParameters); // Обновляем локальное состояние
    setValue('parameters', updatedParameters, { shouldDirty: true }); // Обновляем состояние формы
  };

  useEffect(() => {
    setParameters(getValues('parameters') || []);
  }, [getValues('parameters')]);

  const formatString = (str: string) => {
    if (typeof str !== 'string') {
      throw new Error('Input must be a string');
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <Box>
      <Box>
        <AddButton onClick={addParam} variant="contained">
          Add parameter
        </AddButton>
      </Box>
      {parameters.map((par: any, i: any) => (
        <Box key={i}>
          <Box display="flex" gap="10px" alignItems="center">
            <TextField
              label="Name"
              defaultValue={par.name}
              {...register(`parameters.${i}.name`, {
                required: 'Это поле обязательно для заполнения',
                validate: {
                  noSpaces: (value) => !/\s/.test(value) || 'Имя не должно содержать пробелы',
                },
              })}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <FormControl
              sx={{
                width: '200px',
                border: '1px solid #f4f4f4',
                padding: '11px',
                borderRadius: '4px',
              }}
              variant="standard"
            >
              <Select
                id={`input_type_${i}`}
                defaultValue={formatString(par.type)}
                {...register(`parameters.${i}.type`)}
              >
                {staticTypesParameters.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DeleteIconButton onClick={() => deleteParam(i)} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
