

import { useTranslation } from 'react-i18next';
import { INavigationConfirm } from '../../../context/navigationConfirmContext/types';
import { SimpleModal } from '../modal/SimpleModal';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface IProps extends INavigationConfirm { open: boolean };

const ConfirmNavigationDialog = ({open, onCancel, onConfirm, onSave}: IProps) => {
  const { t } = useTranslation();
  
  if(open) {
    return (
      <SimpleModal title={t("confirmDialog")} isOpen={true} handleClose={() => onCancel}>
        <Box>
          <Box 
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {t("warning").toLocaleUpperCase()}
            </Typography>
            <ReportProblemIcon fontSize='large' sx={{ color: 'DarkOrange' }} />
          </Box>
          <Divider sx={{margin: '8px 0'}} />
          <Box>
            <Typography>
                {t("discardText")}
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="outlined" onClick={onSave } sx={{ width: 200 }}>
              {t('save')}
            </Button>
            <Button variant="outlined" onClick={onConfirm } sx={{ width: 200 }}>
              {t('button_discard')}
            </Button>
            <Button variant="outlined" onClick={onCancel} sx={{ width: 150 }}>
              {t('cancel')}
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    )
  } else {
    return null;
  }
}

export default ConfirmNavigationDialog;
