import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarItemTitle from './SidebarItemTitle';

import { useSelector } from 'react-redux';
import { ValidationErrorProps } from '../../types/validation-errors/ValidationErrorProps';
import usePackageEntities from '../../serverInteraction/hooks/packages/usePackageEntities';
import { Loader } from '../common/loader/Loader';

export type SidebarItemProps = {
  label: any;
  link: string;
  children?: SidebarItemProps[];
  
};

export function SidebarItem({ label, link, children }: SidebarItemProps) {
  const validationErrors: ValidationErrorProps[] = useSelector((state: any) => state.validationErrors.errors);
  const [open, setOpen] = useState(false);
  const level = link.split('/').length - 1;

  return (
    <>
      {children ? (
        <>
          <ListItemButton
            onClick={() => children !== undefined && setOpen(!open)}
            sx={{ pl: level * 2, py: 0.3 }}
            disableGutters
          >
            <Box sx={{ width: 30, textAlign: 'center' }}>
              {children !== undefined &&
                (open ? (
                  <ExpandLess sx={{ width: 18, height: 18, paddingBottom: '2px' }} />
                ) : (
                  <ExpandMore sx={{ width: 18, height: 18, paddingBottom: '2px' }} />
                ))}
            </Box>

            <ListItemText primary={label} />
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => (
                <SidebarItem
                  key={item.link}
                  label={
                    <SidebarItemTitle 
                    label={item.label} 
                    countErrors={validationErrors?.filter(elem => elem.object === item.link).length || 0} 
                  />}
                  link={`${link}/${item.link}`}
                  children={item.children}
                />
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <NavLink to={link}>
          {({ isActive }) => (
            <ListItemButton selected={isActive} sx={{ pl: level * 2, py: 0.3 }}>
              <Box sx={{ width: 30 }}></Box>
              <ListItemText primary={label} />
            </ListItemButton>
          )}
        </NavLink>
      )}
    </>
  );
}
