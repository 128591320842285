// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarPanel_sidebar__Zj6Zm {
    width: 250px;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    padding: 10px;
    height: 100vh;
}

.SidebarPanel_sidebar__Zj6Zm > div {
    margin-bottom: 20px;
}

.SidebarPanel_sidebar__Zj6Zm > h3 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
    text-transform: uppercase;
}

.SidebarPanel_item__FG2En {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all color 0.2s;
  
    &:hover {
      color: #0aa230;
    }
}

.SidebarPanel_icon_wrapper__2P59Z {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/content/processDefinition/tabs/Graph/SidebarPanel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,0BAA0B;;IAE1B;MACE,cAAc;IAChB;AACJ;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".sidebar {\n    width: 250px;\n    background-color: #f4f4f4;\n    border-right: 1px solid #ddd;\n    padding: 10px;\n    height: 100vh;\n}\n\n.sidebar > div {\n    margin-bottom: 20px;\n}\n\n.sidebar > h3 {\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #555;\n    text-transform: uppercase;\n}\n\n.item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n    cursor: pointer;\n    transition: all color 0.2s;\n  \n    &:hover {\n      color: #0aa230;\n    }\n}\n\n.icon_wrapper {\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `SidebarPanel_sidebar__Zj6Zm`,
	"item": `SidebarPanel_item__FG2En`,
	"icon_wrapper": `SidebarPanel_icon_wrapper__2P59Z`
};
export default ___CSS_LOADER_EXPORT___;
