import { Box, Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";
import { LabelTextLength, TextLengthCheckbox } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { constraintsParamNames } from "../../FieldAdvancedSettings/validationViews/constants";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    subfieldIndex: number,
    control: any,
    register: any,
    errors: any,
    getValues: any,
    setValue: any
}


export default function TextLengthView({schemaName, fieldName, fieldNamePrefix, fieldIndex, subfieldIndex, control, register, errors, getValues, setValue}: IProps){
    const { t } = useTranslation();
   
    const paramName = "textLengthConstraint";
    const index = constraintsParamNames.findIndex((element: string) => element === paramName); 
    const textLengthConstraint: Array<any | number> = getValues([`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`, `${fieldNamePrefix}.constraints.${index}.${paramName}.maximum`]);
    const [isWatchTextLen, setWatchTextLen] =  useState(textLengthConstraint[0] || textLengthConstraint[1]);
    
    
    return(
        <Fragment>
          <Stack direction="row" alignItems="center" sx={{marginTop: "1em", marginLeft: "1em"}}>
                <Checkbox onChange={() => setWatchTextLen(!isWatchTextLen)} checked={isWatchTextLen} />
                <FormControl sx={{cursor: "default", width: "100%"}}>
                    <TextLengthCheckbox />  
                </FormControl>
            </Stack>
            {isWatchTextLen &&
            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <Box style={{
                    marginLeft: "3em",
                    marginTop: "1em"
                }}>
                    <Controller
                    name={`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`}
                    control={control}
                    render={({ field }) => (<TextField {...field}
                            variant="outlined" 
                            size="small"
                            type="number"
                            label={t("validMinimum")}
                            sx={{
                                marginRight: "1em"
                            }}
                            {...register(`${fieldNamePrefix}.constraints.${index}.${paramName}.minimum`, {
                                min: {
                                    value: 0,
                                    message: t("minValueValidation").replace("%1", "0")
                                },
                                max: {
                                    value: 999999999,
                                    message: t("maxValueValidation").replace("%1", "999999999")
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t("onlyIntegerNumber")
                                }
                            })}
                            helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.textLengthConstraint?.minimum?.message : undefined}
                            error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.textLengthConstraint?.minimum !== undefined : undefined}
                            />
                        )}
                    />
                    <Controller
                        name={`fields.${fieldIndex}.constraints.${index}.${paramName}.maximum`}
                        control={control}
                        render={({ field }) => (<TextField {...field}
                            {...register(`fields.${fieldIndex}.constraints.${index}.${paramName}.maximum`, {
                                min: {
                                    value: 0,
                                    message: t("minValidation").replace("%1", "0")
                                },
                                max: {
                                    value: 999999999,
                                    message: t("maxValidation").replace("%1", "999999999")
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t("onlyIntegerNumber")
                                }
                            })}
                            variant="outlined" 
                            size="small"
                            type="number"
                            label={<Trans i18nKey={"validMaximum"} />}
                            helperText={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.constraints?.[index]?.textLengthConstraint?.maximum?.message : undefined}
                            error={errors.fields ? errors.fields[fieldIndex]?.subFields[subfieldIndex]?.textLengthConstraint?.maximum !== undefined : undefined}
                            />
                        )}
                    />
                </Box>
                <LabelTextLength schema={schemaName} field={fieldName} />
            </div>
            }
        </Fragment>
    )
};
