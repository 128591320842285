import React from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Node,
  Edge,
  MiniMap,
  Controls,
  Background,
  Connection,
  NodeChange,
  EdgeChange,
} from 'react-flow-renderer';
import UniversalNode from './UniversalNode';

const nodeTypes = {
  customNode: UniversalNode,
};

type FlowChartProps = {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onConnect: (params: Connection) => void;
  onNodeDoubleClick: (node: Node) => void;
  onEdgeDoubleClick: (edge: Edge) => void;
};

const FlowChart: React.FC<FlowChartProps> = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onNodeDoubleClick,
  onEdgeDoubleClick,
}) => {
  // Функция для получения лейбла на основе id узлов и данных transitions
  const getEdgeLabel = (sourceId: string, targetId: string): string => {
    const modtarget = targetId.split('-');
    const sourceNode = nodes.find((node) => node.id === sourceId);
    if (sourceNode?.data?.transitions) {
      const transition = sourceNode.data.transitions.map(
        (t: any, index: string) => {
          if (String(index) === modtarget[1]) return t.name;
        }, // Можно адаптировать имя связи
      );
      return transition?.name || 'Unnamed Edge';
    }
    return 'Unnamed Edge';
  };

  return (
    <div style={{ flex: 1, height: '100%' }}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges.map((edge) => ({
            ...edge,
            label: getEdgeLabel(edge.source, `${edge.sourceHandle}`), // Установка лейбла из данных
            type: 'step',
            labelStyle: { fill: '#555', fontWeight: 700 },
            labelBgStyle: { fill: 'rgba(255, 255, 255, 0.8)', padding: 5 },
          }))}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes} // Передача кастомного типа узлов
          fitView
          onNodeDoubleClick={(_, node) => onNodeDoubleClick(node)} // Обработка двойного клика по узлу
          onEdgeDoubleClick={(_, edge) => onEdgeDoubleClick(edge)} // Обработка двойного клика по ребру
          style={{ width: '100%', height: '100%' }}
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default FlowChart;
