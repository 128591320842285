import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useState } from 'react';

interface SubpropertySelectorProps {
  subproperties: { name: string; parent?: boolean; subproperties?: any[] }[];
  onSubpropertyChange: (newValue: string) => void;
}

export const SubpropertySelector: FC<SubpropertySelectorProps> = ({
  subproperties,
  onSubpropertyChange,
}) => {
  const [selectedSubproperty, setSelectedSubproperty] = useState<string>('');

  const handleChange = (value: string) => {
    setSelectedSubproperty(value);
    onSubpropertyChange(value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormControl fullWidth>
        <InputLabel>Select Subproperty</InputLabel>
        <Select
          value={selectedSubproperty}
          onChange={(e) => handleChange(e.target.value)}
          label="Select Subproperty"
        >
          {subproperties.map((property) => (
            <MenuItem key={property.name} value={property.name}>
              {property.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedSubproperty &&
        subproperties.find((prop) => prop.name === selectedSubproperty)?.subproperties && (
          <SubpropertySelector
            subproperties={
              subproperties.find((prop) => prop.name === selectedSubproperty)?.subproperties || []
            }
            onSubpropertyChange={onSubpropertyChange}
          />
        )}
    </Box>
  );
};
