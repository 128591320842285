import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import React from "react";
import { Profile } from "../common/authorization/Profile";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PackageProps } from "../../types/packages/PackageProps";
import { BUILD_PACKAGE_ENDPOINT } from "../../serverInteraction/services/endpointsConstants";
import axios from "axios";
import { BuildPackageProps } from "../../types/packages/BuildPackageProps";

export function Header() {
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );

  const { i18n } = useTranslation();
  const changeLanguage = (language: string | undefined) => {
    i18n.changeLanguage(language);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const buildPackageAndGetBlob = async (
    data: BuildPackageProps
  ): Promise<Blob | null> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${BUILD_PACKAGE_ENDPOINT}`,
        data,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Ошибка: статус ответа ${response.status}`);
        return null;
      }
    } catch (error) {
      console.error("Произошла ошибка при отправке запроса:", error);
      return null;
    }
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePackage = async () => {
    const packageData: BuildPackageProps = {
      project: `${userPackage.company}-${userPackage.document}`,
      userEmail: "exampleEmail@example.com",
    };

    const blob = await buildPackageAndGetBlob(packageData);

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${userPackage.document}-${packageData.userEmail}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      console.error("Не удалось получить файл");
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box sx={{ px: 3 }}>
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            STUDIO 2.0
          </Typography>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "white",
              color: "black",
              marginRight: 10,
            }}
            onClick={() => changeLanguage("ru")}
          >
            RUS
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={() => changeLanguage("en")}
          >
            ENG
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "white",
              color: "black",
              marginLeft: 10,
            }}
            onClick={handlePackage}
          >
            package
          </Button>
          <Typography
            variant="h6"
            noWrap
            sx={{
              marginLeft: 2,
            }}
          >
            {userPackage?.project || "-"}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            STUDIO 2.0
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Profile />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
