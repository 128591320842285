import React, {
  useState,
  useEffect,
  SyntheticEvent,
  DetailedHTMLProps,
  HTMLAttributes,
  useRef,
} from 'react';
import { Tabs, Tab, Box, Button, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Loader } from '../../../common/loader/Loader';
import { AUTOMATION_SCRIPTING } from '../../../../serverInteraction/services/endpointsConstants';
import useUpdate from '../../../../serverInteraction/hooks/entity/useUpdate';
import useAutomationScripting from '../../../../serverInteraction/hooks/automation/automationScripting/useAutomationScripting';
import CodeEditor from './tabs/CodeEditor';
import GeneralDescAndTypes from './tabs/GeneralDescAndTypes';
import { Parameters } from './tabs/Parameters';
import { useTranslation } from 'react-i18next';

interface TabPanelProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IProps {
  script: string;
  description: string;
  parameters: [];
  inputType: string;
  outputType: string;
  featureId: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...props }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...props}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const AutomationScriptingView: React.FC = () => {
  const { id } = useParams();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, isLoading, isFetching, error, automationScripting } = useAutomationScripting(
    userPackage.company,
    userPackage.document,
    id,
  );

  const { t } = useTranslation();

  const { update } = useUpdate(AUTOMATION_SCRIPTING);
  const methods = useForm<IProps>();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const codeEditorRef = useRef<any>(null);

  useEffect(() => {
    if (automationScripting) {
      const initialData = JSON.parse(`${automationScripting}`);
      const { script, description, parameters, inputType, outputType, featureId } = initialData;
      methods.reset({
        script,
        description,
        parameters,
        inputType,
        outputType,
        featureId,
      });
    }
  }, [automationScripting, methods]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const onSubmit = async (data: any) => {
    const editorData = codeEditorRef.current?.getData() || {};

    const allData = {
      ...data,
      ...editorData,
      company: userPackage.company,
      document: userPackage.document,
    };

    console.log('Собранные данные из обоих табов:', allData);

    await update(`${id}`, allData);
  };

  if (isLoading || isFetching || !automationScripting) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Ошибка: {error.message}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3, mt: '50px' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="process tabs">
            <Tab label={`${t('scriptingEditor')}`} />
            <Tab label={`${t('parameters')}`} />
          </Tabs>

          <GeneralDescAndTypes />

          <TabPanel value={selectedTab} index={0}>
            <CodeEditor ref={codeEditorRef} />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <Parameters />
          </TabPanel>

          <Button type="submit" variant="contained" color="primary">
            Submit All Data
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AutomationScriptingView;
