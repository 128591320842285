import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { FACETS_ENDPOINT } from "../../../../../serverInteraction/services/endpointsConstants";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../../common/grid/GridOneToThree";
import { ControlledInput } from "../../../../common/input/ControlledInput";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import usePatch from "../../../../../serverInteraction/hooks/entity/usePatch";
import { BaseEntityProps } from "../../../../../types/BaseEntityProps";
import { Loader } from "../../../../common/loader/Loader";
import RenameDialog from "../../../../common/dialog/RenameDialog";
import { EntityRenameProps } from "../../../../../types/common/entity/EntityRenameProps";
import { FacetProps } from "../../../../../types/content/facets/FacetProps";

type Props = {
  featureId: string;
  existingIds: string;
  refetch: () => void;
};

function FacetsRenameDialog({ refetch, featureId, existingIds }: Props) {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { patch, isUpdating } = usePatch<
    EntityRenameProps,
    EntityRenameProps & BaseEntityProps
  >(FACETS_ENDPOINT);

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<EntityRenameProps>({
    defaultValues: {
      newFeatureId: featureId,
      oldFeatureId: featureId,
      company: userPackage.company,
      document: userPackage.document,
    },
  });

  const isUnique = (value: string) =>
    existingIds.indexOf(value.toLowerCase()) === -1;

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("newFeatureId", {
              required: {
                value: true,
                message: "Feature ID is required",
              },
              minLength: {
                value: 3,
                message: "Must be at least 3 symbols",
              },
              maxLength: {
                value: 40,
                message: "Must be at more 40 symbols",
              },
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
              validate: (value) =>
                isUnique(value) ||
                t("uniqueValidation")
                  .replace("%1", "Facet")
                  .replace("%2", value),
            }),
          }}
          helperText={errors.newFeatureId?.message}
          error={errors.newFeatureId !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
  ];

  const onSubmit = async (data: EntityRenameProps) => {
    isUpdating && <Loader />;
    try {
      const res: FacetProps = JSON.parse(`${await patch(data)}`);
      reset();
    } catch (error: any) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <RenameDialog
      title={`${t("rename")} ${featureId}`}
      update={handleSubmit(onSubmit)}
      validate={validate}
      isUpdate={refetch}
    >
      <hr />
      <Box sx={{ height: "auto", marginTop: 1 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </RenameDialog>
  );
}

export default FacetsRenameDialog;
