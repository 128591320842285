import { useSelector } from "react-redux";
import { LifeCycleProps } from "../../../../types/content/life-cycles/LifeCycleProps";
import useGetAll from "../../entity/useGetAll";
import { PackageProps } from "../../../../types/packages/PackageProps";

const useLifeCycles = (
  company: string,
  document: string,
  featureId?: string
) => {
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  let entityName = "";

  if (featureId) {
    entityName = `lifecycles/complete?company=${userPackage.company}&document=${userPackage.document}&featureId=${featureId}`;
  } else {
    entityName = `lifecycles?company=${userPackage.company}&document=${userPackage.document}`;
  }

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<LifeCycleProps>(entityName);

  return {
    status,
    lifeCycles: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useLifeCycles;
