import { Checkbox, FormControl, Stack, TextField } from "@mui/material"
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";
import { LabelTextFormat, TextFormatCheckbox } from "../../FieldAdvancedSettings/validationViews/ValidationLabels";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";
import { PatternLists } from "../../../../../../../staticValues/Constants";
import { constraintsParamNames } from "../../FieldAdvancedSettings/validationViews/constants";

interface IProps {
    schemaName: string,
    fieldName: string,
    fieldNamePrefix: string,
    fieldIndex: number,
    control: any,
    getValues: any,
    setValue: any
}

export default function TextFormatView({schemaName, fieldName, fieldNamePrefix, fieldIndex, control, getValues, setValue}: IProps){
    const { t } = useTranslation();
    const paramName = 'textFormatConstraint';
    const index = constraintsParamNames.findIndex((element: string) => element === paramName); 
    const pattern: string = getValues(`${fieldNamePrefix}.constraints.${index}.${paramName}.pattern`);
    const [isShowPattern, setShowPattern] = useState(pattern ? true : false);
    
    return (
        <Fragment>
            <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                <Checkbox onChange={() => setShowPattern(!isShowPattern)} checked={isShowPattern} />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <TextFormatCheckbox />  
                </FormControl>
            </Stack>
            {isShowPattern &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '3em'
                }}>
                    <div style={{
                        marginTop: '1em',
                        marginBottom: '0.5em',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Controller
                        name={`${fieldNamePrefix}.constraints.${index}.${paramName}.pattern`}
                        control={control}
                        render={({ field: { onChange, value } }) => (<TextField
                                variant="outlined" 
                                size='small'
                                label={t('validPattern')}
                                value={value === null ? '' : value}
                                onChange={onChange}
                                sx={{
                                    width: '30%'
                                }}
                                />
                            )}
                        />
                        <ControlledSelect valueList={PatternLists} selectedValue={pattern === null ? 'unknown' : pattern} size='small' label={t('validPattern')} sx={{
                        width: '200px',
                        marginLeft: '1em'
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(`${fieldNamePrefix}.constraints.${index}.${paramName}.pattern`, e.target.value === 'unknown' ? null : e.target.value)}
                    />
                    </div>
                    <LabelTextFormat schema={schemaName} field={fieldName} />
                </div>  
            }
        </Fragment>
    )
}