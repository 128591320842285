import { Box } from "@mui/material";
import useRemove from "../../../../../serverInteraction/hooks/entity/useRemove";
import { DeleteDialog } from "../../../../common/dialog/DeleteDialog";
import useAlert from "../../../../../context/alertContext/useAlert";
import { WorkflowProps } from "../../../../../types/content/workflows/WorkflowProps";
import { WORKFLOWS_ENDPOINT } from "../../../../../serverInteraction/services/endpointsConstants";
import { PackageProps } from "../../../../../types/packages/PackageProps";
import { useSelector } from "react-redux";

type Props = {
  workflow: WorkflowProps;
  onDelete?: () => void;
};

export function WorkflowsDeleteDialog({ workflow, onDelete }: Props) {
  const userPackage: PackageProps = useSelector(
    (state: any) => state.packages.userPackage
  );
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess("Deleted successfully!");
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove(WORKFLOWS_ENDPOINT, onSuccess);
  const handleDelete = async () => {
    const removeData = {
      featureId: workflow.featureId,
      company: userPackage.company,
      document: userPackage.document,
    };

    await remove(removeData);
  };

  return (
    <DeleteDialog
      title={`Delete Workflow ${workflow.featureId}`}
      remove={handleDelete}
    >
      <Box>{`Are you sure to delete the feature ${workflow.featureId}?`}</Box>
    </DeleteDialog>
  );
}
