import { Box, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SchemaFieldsTable from "./SchemaFieldsTable";

interface IProps {
    control: any,
    register: any,
    errors: any,
    getValues: any,
    setValue: any,
    watch: any,
   
}

const SchemaForm = ({control, register, errors, getValues, setValue, watch}:IProps) => {
    const { t } = useTranslation();
    
    return (
        <Stack spacing={3}>
            <Box display="flex" marginBottom="10px">
                <Box display="flex" alignItems="center" sx={{ paddingRight: '1em' }}>
                    <Controller
                        name='featureId'
                        control={control}
                        render={({field})=>(
                            <TextField
                                {...field}
                                disabled
                                variant="outlined"
                                size="small"
                                label={t('schemaName')}
                            />
                        )}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Controller
                        name='prefix'
                        control={control}
                        render={({field})=>(
                            <TextField
                                {...field}
                                variant="outlined"
                                size="small"
                                label={t('schemaPrefix')}
                                {...register('prefix',{
                                required: {
                                    value: true,
                                        message: t('inputRequired').replace('%1', t('schemaPrefix')),
                                    },
                                    minLength: {
                                        value: 2,
                                        message: t('minValidation').replace('%1', '2'),
                                    },
                                    maxLength: {
                                        value: 256,
                                        message: t('maxValidation').replace('%1', '256'),
                                    },
                                    pattern: {
                                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                        message: t('patternValidation'),
                                    }
                                })}
                                helperText={errors.prefix?.message}
                                error={errors.prefix !== undefined}
                            />
                        )}
                    />
                </Box>
                {getValues('isImported') && 
                    <Box 
                        display="flex" 
                        alignItems="center" 
                        sx={{ paddingLeft: '1em' }}
                    >
                        <Typography textAlign="center">
                            {t('importedFromXsdFile')}
                        </Typography>
                    </Box>
                }
            </Box>
            <SchemaFieldsTable 
                
                control={control} 
                register={register} 
                errors={errors} 
                getValues={getValues} 
                setValue={setValue}
                watch={watch}
            />
        </Stack>
    )
}

export default SchemaForm;