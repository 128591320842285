import { Box, Button, Checkbox, FormControl, FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { ControlledSelect } from "../../../../../common/select/ControlledSelect";
import { FieldsSelectValue } from "../../../../../../staticValues/Constants";
import RequiredView from "./validationViews/RequiredView";
import ListRequiredView from "./validationViews/ListRequiredView";
import TextFormatView from "./validationViews/TextFormatView";
import TextLengthView from "./validationViews/TextLengthView";
import { SimpleAccordion } from "../../../../../common/accordion/SimpleAccordion";
import { useNavigate, useSearchParams } from "react-router-dom";
import NumericRangeView from "./validationViews/NumericRangeView";
import DateRangeView from "./validationViews/DateRangeView";
import DirectoryAttrs from "./directoryAttrs/DirectoryAttrs";
import UserGroupAttrs from "./user_groupAttrs/UserGroupAttrs";
import DocumentAttrs from "./documentAttrs/DocumentAttrs"
import FieldTypeSelect from "./FieldTypeSelect/FieldTypeSelect";
import SchemaFieldsTable from "../SchemaFieldsTable";
import NavigationSection from "./NavigationSection/NavigationSection";
import DefaultValueInput from "../FieldAdvancedSettings/DefaultValueInput/DefaultValueInput";
import FieldComment from "../FieldComment/FieldComment";

interface IProps {
    index: number,
    subindex: number,
    control: any,
    register: any,
    errors: any,
    setValue: any,
    getValues: any,
    watch: any
    
}

const SubFieldAdvancedSettingsV1 = ({ index, subindex, control, register, errors, setValue, getValues, watch }:IProps) => {
    const { t } = useTranslation();
    const fieldNamePrefix = `fields.${index}.subFields.${subindex}`;
   
    const fieldsWatches = useWatch({
        control,
        name: [`${fieldNamePrefix}.fieldType`]
    });
    
    return (
        <>
            <NavigationSection 
                schemaName={getValues("featureId")} 
                fieldName={getValues(`fields.${index}.name`)}
                subfieldName={getValues(`${fieldNamePrefix}.name`)}
                fieldIndex={index}
            />
            
            <Box>
                <Controller
                    name={`${fieldNamePrefix}.name`}
                    control={control}
                    render={({ field: {value, onChange} }) => {
                        const errorsObj = subindex===null ? errors.fields?.[index] : (errors.fields ? errors.fields[index]?.subFields[subindex] : undefined);
                        return (
                        <TextField
                            value = {value}
                            label={t("fieldName")}
                            variant="outlined"
                            size="small"
                            sx={{
                                paddingRight: "1em",
                              }}
                              {...register(`${fieldNamePrefix}.name`, {
                                required: {
                                  value: true,
                                  message: t("inputRequired").replace("%1", t("fieldName")),
                                },
                                minLength: {
                                  value: 2,
                                  message: t("minValidation").replace("%1", "2"),
                                },
                                maxLength: {
                                  value: 256,
                                  message: t("maxValidation").replace("%1", "256"),
                                },
                                pattern: {
                                  value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                  message: t("patternValidation"),
                                },
                              })}
                            onChange = {onChange}
                            helperText={errorsObj?.name?.message}
                            error={errorsObj?.name !== undefined}
                        />
                    )}}
                />
                <FieldTypeSelect  
                    variant={subindex === null ? "FIELD" : "SUBFIELD"}
                    fieldNamePrefix = {fieldNamePrefix}
                    name={`${fieldNamePrefix}.fieldType`}
                    isImported={getValues("isImported")}
                    control={control}
                    register={register}
                    setValue={setValue} 
                    helperText={errors.fields && errors.fields[index]?.subFields ? errors.fields[index]?.subFields[subindex]?.fieldType?.message : undefined}
                    error={errors.fields && errors.fields[index]?.subFields ? errors.fields[index]?.subFields[subindex]?.fieldType !== undefined : undefined}
                    defaultInputName={`${fieldNamePrefix}.defaultValue`}
                    referenceConfigurationInputName={`${fieldNamePrefix}.referenceConfiguration`}
                />
                
                <Controller
                    name={`${fieldNamePrefix}.isMultiValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={<Checkbox onChange={onChange} checked={value} />}
                            label={t("fieldMulti")}
                            sx={{ paddingLeft: "1em" }}
                        />
                    )}
                />
                <DefaultValueInput 
                    name={`${fieldNamePrefix}.defaultValue`} 
                    control={control} 
                    register={register} 
                    getValues={getValues} 
                    fieldTypeInputName={`${fieldNamePrefix}.fieldType`}                
                />
                <FieldComment field={`${fieldNamePrefix}`} control={control} />
            </Box>
           
            {/*Набор установок зависит от типа поля*/}
            {/*//Здесь COMPLEX невозможен, но оставим для единообразия с field*/}
            {fieldsWatches[0] === "COMPLEX" &&
            <SimpleAccordion title={t("subfields")} elevation={2} sx={{marginTop: "1em"}}>
                <SchemaFieldsTable control = {control} register = {register} errors ={errors} getValues = {getValues} setValue = {setValue} watch = {watch} />
            </SimpleAccordion>
            }
            {fieldsWatches[0] === "DIRECTORY" &&
                <SimpleAccordion title={t("directoryFieldType")} elevation={2} sx={{marginTop: "1em"}}>
                    <DirectoryAttrs
                        schemaName = {getValues("featureId")}
                        fieldName = {getValues(`${fieldNamePrefix}.name`)}
                        fieldNamePrefix = {fieldNamePrefix}
                        fieldIndex = {index}
                        subfieldIndex = {subindex}
                        register = {register}
                        control = {control}
                        setValue = {setValue}
                        getValues={getValues}
                        errors = {errors}
                    />
                </SimpleAccordion>
            }
            {fieldsWatches[0] === "USER_GROUP" &&
            <SimpleAccordion title={t("userGroupFieldType")} elevation={2} sx={{marginTop: "1em"}}>
                <UserGroupAttrs
                    schemaName = {getValues("featureId")}
                    fieldName = {getValues(`${fieldNamePrefix}.name`)}
                    fieldNamePrefix = {fieldNamePrefix}
                    fieldIndex = {index}
                    subfieldIndex = {subindex}
                    register = {register}
                    control = {control}
                    setValue = {setValue}
                    errors = {errors}
                />
            </SimpleAccordion>
            }
            {fieldsWatches[0] === "DOCUMENT" &&
            <SimpleAccordion title={t("documentFieldType")} elevation={2} sx={{marginTop: "1em"}}>
                <DocumentAttrs
                    schemaName = {getValues("featureId")}
                    fieldName = {getValues(`${fieldNamePrefix}.name`)}
                    fieldNamePrefix = {fieldNamePrefix}
                    fieldIndex = {index}
                    subfieldIndex = {subindex}
                    register = {register}
                    control = {control}
                    setValue = {setValue}
                    errors = {errors}
                />
            </SimpleAccordion>
            }
            <SimpleAccordion title={t("validation")} elevation={2} sx={{marginTop: "1em"}}>
                <Box >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <RequiredView 
                            schemaName = {getValues("featureId")}
                            fieldName = {getValues(`${fieldNamePrefix}.name`)}
                            fieldNamePrefix = {fieldNamePrefix}
                            fieldIndex = {index}
                            control = {control}
                        />
                        <ListRequiredView 
                            schemaName = {getValues("featureId")}
                            fieldName = {getValues(`${fieldNamePrefix}.name`)}
                            fieldNamePrefix = {fieldNamePrefix}
                            fieldIndex = {index}
                            control = {control}
                        />
                        {["STRING", "INTEGER", "FLOATING_POINT", "DIRECTORY", "USER_GROUP", "DOCUMENT"].includes(fieldsWatches[0])  && 
                            <TextFormatView 
                                schemaName = {getValues("featureId")}
                                fieldName = {getValues(`${fieldNamePrefix}.name`)}
                                fieldNamePrefix = {fieldNamePrefix}
                                fieldIndex = {index}
                                control = {control}
                                getValues = {getValues}
                                setValue = {setValue}
                            />
                        }
                        {["STRING", "DIRECTORY", "USER_GROUP", "DOCUMENT"].includes(fieldsWatches[0])  && 
                            <TextLengthView 
                                schemaName = {getValues("featureId")}
                                fieldName = {getValues(`${fieldNamePrefix}.name`)}
                                fieldNamePrefix = {fieldNamePrefix}
                                fieldIndex = {index}
                                subfieldIndex = {subindex}
                                control = {control}
                                register = {register}
                                errors = {errors}
                                getValues = {getValues}
                                setValue = {setValue}
                            />
                        }
                        {["INTEGER", "FLOATING_POINT"].includes(fieldsWatches[0])  && 
                            <NumericRangeView 
                                schemaName = {getValues("featureId")}
                                fieldName = {getValues(`${fieldNamePrefix}.name`)}
                                fieldNamePrefix = {fieldNamePrefix}
                                fieldIndex = {index}
                                subfieldIndex = {subindex}
                                control = {control}
                                register = {register}
                                errors = {errors}
                                getValues = {getValues}
                                setValue = {setValue}
                            />
                        }
                         {["DATE"].includes(fieldsWatches[0])  && 
                            <DateRangeView 
                                schemaName = {getValues("featureId")}
                                fieldName = {getValues(`${fieldNamePrefix}.name`)}
                                fieldNamePrefix = {fieldNamePrefix}
                                fieldIndex = {index}
                                subfieldIndex = {subindex}
                                control = {control}
                                register = {register}
                                errors = {errors}
                                getValues = {getValues}
                                setValue = {setValue}
                            />
                        }
                    </Box>
                </Box>
                </SimpleAccordion>
        </>
    )
}

export default SubFieldAdvancedSettingsV1;

