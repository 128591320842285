import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../types/packages/PackageProps';
import useAutomationScripting from '../../../../serverInteraction/hooks/automation/automationScripting/useAutomationScripting';
import { AutomationScriptingProps } from '../../../../types/automation/automationScripting/AutomationScriptingProps';
import { AutomationScriptingDeleteDialog } from './delete/AutomationScriptingDeleteDialog';
import { AutomationScriptsRenameDialog } from './rename/AutomationScriptsRenameDialog';
import AutomationScriptingCopyDialog from './copy/AutomationScriptingCopyDialog';

export function AutomationsScriptingListPage() {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, automationScripting, isLoading, isFetching, error, refetch } =
    useAutomationScripting(userPackage.company, userPackage.document);

  if (isLoading || isFetching || automationScripting === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {JSON.parse(`${automationScripting}`).map((autoScript: AutomationScriptingProps) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={autoScript.featureId}
          >
            <Link underline="hover" component={RouterLink} to={`${autoScript.featureId}`}>
              {autoScript.featureId}
            </Link>
            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <Box sx={{ display: 'flex' }}>
                {/* <AutomationScriptingCopyDialog autoScript={autoScript} refetch={() => refetch()} /> */}
                <AutomationScriptsRenameDialog
                  featureId={autoScript.featureId}
                  refetch={() => refetch()}
                />
                <AutomationScriptingDeleteDialog
                  autoScript={autoScript}
                  onDelete={() => refetch()}
                />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
